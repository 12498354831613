import { ref } from 'vue';

import type { OrdersVps } from '@/entities/Orders';

import type { CreateMeasureInput, GroupedMeasureItems } from '../interfaces';

export const useGroupedMeasureList = (orderId: string) => {
  const measureValues = ref({} as Record<string, Record<string, CreateMeasureInput>>);

  const getGroupedMeasureListFromVps = (vps: OrdersVps[], VORNR: string): GroupedMeasureItems[] => {
    const filteredVps = [...vps].sort((a, b) =>
      new Date(`${b.IDATE}T${b.ITIME}`) < new Date(`${a.IDATE}T${a.ITIME}`) ? 1 : -1,
    );
    const groupedObj = filteredVps?.reduce(
      (acc, vps) => {
        if (!acc[+vps.POINT]) {
          acc[+vps.POINT] = {
            DECIM: vps.DECIM,
            measureItems: [],
            recentMeasure: vps,
          };
          measureValues.value[VORNR] = {
            [vps.POINT]: {
              POINT: vps.POINT,
              CNTRC: '',
              UNITR: vps.MSEHL,
              itemId: orderId as string,
            },
          };
        }
        acc[+vps.POINT].measureItems.push(vps);
        acc[+vps.POINT].recentMeasure = { ...vps, itemId: orderId }; //Последний записанный элемент будет самым актуальным по дате
        return acc;
      },
      {} as Record<number, GroupedMeasureItems>,
    );
    return Object.values(groupedObj);
  };

  return { getGroupedMeasureListFromVps, measureValues };
};
