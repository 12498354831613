import { format } from 'date-fns';
import { storeToRefs } from 'pinia';

import { useDefectsListStore } from '@/entities/Defects';
import { useSymptomsStore } from '@/entities/Defects/model/useSymptomsStore';
import { useEquipmentStore } from '@/entities/Equipments/model/useEquipmentStore';
import { useFuncLocStore } from '@/entities/Equipments/model/useFuncLocStore';
import { useMaterialStore } from '@/entities/Materials';
import {
  type OrdersHeaders,
  useCurrentOrdersStore,
  useOrderConfirmationsStore,
  useOrdersOperPlanStore,
  useOrdersStore,
} from '@/entities/Orders';
import { getOrdersFromOperPlan } from '@/entities/Orders/hooks/useGetOrders';
import { checkOrderIsInspection, checkOrderMaintenanceOrRepair } from '@/entities/Orders/lib';
import { useOrdersHistoryStore } from '@/entities/Orders/model/useOrdersHistoryStore';
import { usePriorityListStore } from '@/entities/Priorities/model/usePriorityListStore';
import { useTechnologistStore, useUsersListStore } from '@/entities/Users';
import { useTeamsStore } from '@/entities/Users/model/useTeamsStore';
import { useAuthModel } from '@/features/Auth';
import { useRejectReasonsStore } from '@/features/RejectionReasons/model/useRejectReasonsStore';
import { useStorage } from '@/shared/hooks';
import { STORAGE_DATA_SYNC, STORAGE_ORDER_SUPERVISOR, STORAGE_ORDER_USERS } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useFileStore } from '@/shared/model/useFiles';
import { useSchemaStore } from '@/shared/model/useSchemaStore';
import { useSectorsStore } from '@/shared/model/useSectorsStore';

export const requestBackgroundSync = async () => {
  if ('serviceWorker' in navigator && 'SyncManager' in window) {
    try {
      const registration: ServiceWorkerRegistration = await navigator.serviceWorker.ready;
      await registration.sync.register('workbox-background-sync:write-queue');
    } catch (error: unknown) {}
  } else {
    console.log('Background sync not supported');
  }
};

export const useSync = () => {
  const { setItem } = useStorage();
  const { setAlert } = useAlertStore();
  const userTokenModel = useAuthModel();
  const { fmpToken, keycloakToken } = storeToRefs(useAuthModel());
  const userProfileStore = useUserProfileStore();
  const {
    profile,
    userIsTechnologist,
    userIsRepairer,
    userIsSupervisor,
    userIsRepairSupervisor,
    userIsTechnologSupervisor,
    userIsEmployee,
  } = storeToRefs(userProfileStore);

  const rejectReasonsStore = useRejectReasonsStore();
  const funcLocStore = useFuncLocStore();
  const equipmentStore = useEquipmentStore();
  const priorityListStore = usePriorityListStore();
  const symptomsStore = useSymptomsStore();
  const sectorsStore = useSectorsStore();
  const materialsStore = useMaterialStore();

  const ordersOperPlanStore = useOrdersOperPlanStore();
  const { ordersOperPlan } = storeToRefs(ordersOperPlanStore);
  const teamsStore = useTeamsStore();
  const { teamsOrders, teams } = storeToRefs(teamsStore);
  const technologistStore = useTechnologistStore();
  const { technologistsOrdersIs, technologist } = storeToRefs(technologistStore);
  const ordersStore = useOrdersStore();

  const orderConfirmationsStore = useOrderConfirmationsStore();
  const { orderConfirmations } = storeToRefs(orderConfirmationsStore);
  const ordersHistoryStore = useOrdersHistoryStore();
  const defectsListStore = useDefectsListStore();
  const { ordersDefectsList } = storeToRefs(defectsListStore);
  const fileStore = useFileStore();

  const usersStore = useUsersListStore();
  const { usersList } = storeToRefs(usersStore);
  const currentOrdersStore = useCurrentOrdersStore();

  const updateLastSyncDate = async () => {
    const lastSyncDate = new Date().getTime().toString();
    await setItem(STORAGE_DATA_SYNC, lastSyncDate);
    userProfileStore.updateLastSyncDate(lastSyncDate);
  };

  const sync = async (initial = false) => {
    await Promise.all([
      userTokenModel.fetchFmpTokenAsync(initial),
      userTokenModel.authPythonBackendAsync(initial),
    ]);

    if (fmpToken.value) await useSchemaStore().fetchSchemaAsync(initial); // Получение ФМП схемы
    if (keycloakToken.value)
      await useUserProfileStore().fetchUserProfileAsync(keycloakToken.value, initial); // Получение информации о пользователе

    if (!fmpToken.value || !keycloakToken.value)
      return setAlert({ message: 'Ошибка авторизации', type: 'error' });

    requestBackgroundSync();

    if (userProfileStore.profile.area_id)
      await usersStore.fetchUsersListAsync(userProfileStore.profile.area_id.toString(), true);

    //Для сотрудника актива не нужно запрашивать заказы
    if (profile.value.user_id && !userIsEmployee.value) {
      let fmpOrdersInput = [];

      // Для  всех кроме супервайзера заказы на смену получаем в  fetchOrdersOperPlanAsync
      if (userIsRepairSupervisor.value) {
        // Для супервайзера ремонтников заказы получаем из fetchTeamsAsync
        await teamsStore.fetchTeamsAsync({
          supervisorId: profile.value.user_id,
          startDate: format(new Date(), 'yyyy-MM-dd'),
          endDate: format(new Date(), 'yyyy-MM-dd'),
        });
        fmpOrdersInput = teamsOrders.value;
      } else if (userIsTechnologSupervisor.value) {
        // Для супервайзера технологов заказы получаем из fetchTechnologistAsync
        await technologistStore.fetchTechnologistAsync({
          supervisorTechnologistId: profile.value.user_id,
          startDate: format(new Date(), 'yyyy-MM-dd'),
          endDate: format(new Date(), 'yyyy-MM-dd'),
        });
        fmpOrdersInput = technologistsOrdersIs.value;
      } else {
        await ordersOperPlanStore.fetchOrdersOperPlanAsync({
          userId: profile.value.user_id,
          startDate: format(new Date(), 'yyyy-MM-dd'),
          endDate: format(new Date(), 'yyyy-MM-dd'),
        });
        fmpOrdersInput = getOrdersFromOperPlan(ordersOperPlanStore.ordersOperPlan);
      }
      await ordersStore.fetchOrdersAsync(fmpOrdersInput, initial);
    }

    if ((userIsSupervisor.value || userIsRepairer.value) && ordersStore.orders) {
      // Для супервайзера и ремонтника нужно получить данные о подтвержденных заказов с бэкенда python
      const confirmRequest = ordersStore.orders.EX_HEADERS.map((order) => {
        const orderOperations = ordersStore.orders?.EX_OPER_LIST?.filter(
          (operation) => operation.AUFNR === order.AUFNR,
        );
        return {
          OrderNumber: order.AUFNR,
          Operations: orderOperations?.map((operation) => operation.VORNR) || [],
        };
      });
      await orderConfirmationsStore.fetchOrderConfirmationsAsync(confirmRequest);
      if (orderConfirmations.value) {
        ordersStore.mapConfirmationDataWithOrderFmData(orderConfirmations.value);
      }
    }

    const setTeamExecutedOrder = async (order: OrdersHeaders) => {
      // команда выполнившая заказ
      const teamExecutedOrder = teams.value?.data.find((team) => {
        return team.work_orders.some((workOrder) => workOrder.order_id === order.AUFNR);
      });
      await setItem(
        `${STORAGE_ORDER_USERS}_${order.AUFNR}`,
        JSON.stringify(
          teamExecutedOrder?.workers_data.map((worker) => {
            return { ...worker, working: true, phone_number: [worker.phone_number] };
          }),
        ),
      );
    };

    ordersStore.orders?.EX_HEADERS?.forEach(async (order) => {
      if (checkOrderIsInspection(order)) {
        if (userIsTechnologist.value || userIsRepairer.value) {
          // Технолог и ремонтник всегда будут исполнителем для Осмотра
          const currentUser = usersList.value?.find(
            (user) => user.personnel_number === profile.value.personnel_number,
          );
          await setItem(`${STORAGE_ORDER_USERS}_${order.AUFNR}`, JSON.stringify([currentUser]));
        } else if (userIsRepairSupervisor.value) {
          setTeamExecutedOrder(order);
        } else if (userIsTechnologSupervisor.value) {
          const technologistExecutedOrder = technologist.value?.find((technologist) => {
            return technologist.work_orders.some((workOrder) => workOrder.order_id === order.AUFNR);
          });
          await setItem(
            `${STORAGE_ORDER_USERS}_${order.AUFNR}`,
            JSON.stringify([
              {
                ...technologistExecutedOrder,
                working: true,
                phone_number: [technologistExecutedOrder?.mobile],
              },
            ]),
          );
        }
      } else if (checkOrderMaintenanceOrRepair(order)) {
        if (userIsRepairer.value) {
          const supervisor = {
            ...ordersOperPlan.value?.workgroup.supervisor,
            working: true,
            phone_number: [ordersOperPlan.value?.workgroup.supervisor?.phone_number],
          };
          await setItem(`${STORAGE_ORDER_SUPERVISOR}_${order.AUFNR}`, JSON.stringify([supervisor]));
          const workers = ordersOperPlan.value?.workgroup.workers_data.map((worker) => {
            return { ...worker, working: true, phone_number: [worker.phone_number] };
          });
          //Первым в списке должен идти текущий пользователь
          const sortedUsers = workers?.sort((a, b) => {
            if (a.user_id === profile.value.user_id) return -1;
            if (b.user_id === profile.value.user_id) return 1;
            return 0;
          });
          await setItem(`${STORAGE_ORDER_USERS}_${order.AUFNR}`, JSON.stringify(sortedUsers));
        }
        if (userIsRepairSupervisor.value) {
          //в ответе teams у каждой бригады будет одинаковый мастер поэтому берем из первого массива
          const supervisor = {
            ...teams.value?.data[0].supervisor,
            working: true,
            phone_number: [teams.value?.data[0].supervisor?.phone_number],
          };
          await setItem(`${STORAGE_ORDER_SUPERVISOR}_${order.AUFNR}`, JSON.stringify([supervisor]));
          setTeamExecutedOrder(order);
        }
      }
    });

    const uniqueEqunrs = Array.from(
      new Set(ordersStore.orders?.EX_HEADERS?.map((order) => order.EQUNR)),
    );
    await defectsListStore.fetchOrdersDefectsListAsync({
      IM_WERKS: '',
      IM_EQUNR: uniqueEqunrs.map((item) => [item]) || [],
      IM_TPLNR: '',
      IM_EQUNR_HISTORY: [],
      IM_QMNUM: '',
    });

    const fileIds = [
      ...(ordersStore.orders?.EX_FILES_LIST?.map((file) => file.FILE_ID) || []),
      ...(ordersDefectsList.value?.EX_FILES_LIST?.map((file) => file.FILE_ID) || []),
    ];
    fileStore.fetchFileAsync(fileIds);
    const ordersEquipmentIds = ordersStore.orders?.EX_HEADERS?.map((order) => order.EQUNR) || [];
    if (!userIsRepairSupervisor.value) {
      ordersHistoryStore.fetchOrdersHistoryByEquipmentIdsAsync(ordersEquipmentIds);
    }
    currentOrdersStore.fetchCurrentRepairsAsync();
    rejectReasonsStore.fetchRejectReasonsAsync();
    funcLocStore.fetchFuncLockListAsync();
    equipmentStore.fetchEquipmentListAsync();
    priorityListStore.fetchPriorityListAsync();
    sectorsStore.fetchSectorsAsync();
    materialsStore.fetchMaterialListAsync();
    symptomsStore.fetchSymptomsAsync();

    updateLastSyncDate();
  };

  return { sync, updateLastSyncDate };
};
