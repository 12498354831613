<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { useGetSectors } from '@/entities/Sectors/hooks/useGetSectors';
import { PageHeading, PageTabs } from '@/shared/ui';

import { tabs } from '../lib/tabs';
import styles from './Defects.module.scss';

const { sectorId } = useRoute().params;

const { sectors } = useGetSectors();

const sectorName = computed(() => {
  const sector = sectors.value?.EX_TECHPLACE.find((item) => item.TPLNR === sectorId);
  return sector ? sector.PLTXT : '';
});
</script>

<template>
  <div :class="styles.area">
    <PageHeading :text="sectorName" :go-back="true" />
    <PageTabs :tabs="tabs" save-tabs :additional-hash="sectorId as string" />
  </div>
</template>
