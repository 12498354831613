import type { AxiosResponse } from 'axios';
import { addMilliseconds, differenceInMilliseconds, format, subMilliseconds } from 'date-fns';
import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';

import type { FormStateMaterial } from '@/entities/Materials';
import { OrderStatus, useOrderConfirmationsStore, useOrdersOperPlanStore } from '@/entities/Orders';
import type { OrdersOperList } from '@/entities/Orders/interfaces';
import { useTeamsStore } from '@/entities/Users';
import type { UsersList } from '@/entities/Users/interfaces';
import { useStorage } from '@/shared/hooks';
import type { ErrorResponse, MaterialsInput, OrderInput } from '@/shared/interfaces';
import { STORAGE_MATERIALS, STORAGE_ORDER_USERS } from '@/shared/lib';
import { formatDateToUTC } from '@/shared/lib/dateUtils';
import { useUserProfileStore } from '@/shared/model';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';

import { fetchOrderInput } from '../api/fetchOrderInput';

export const useOrderInputStore = defineStore('orderInput', () => {
  const confirm = ref<ErrorResponse | undefined>(undefined);
  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();
  const ordersStore = useOrdersOperPlanStore();
  const userProfileStore = useUserProfileStore();
  const { ordersOperPlan } = storeToRefs(ordersStore);
  const { profile, userIsSupervisor } = storeToRefs(userProfileStore);

  const teamsStore = useTeamsStore();
  const { teams } = storeToRefs(teamsStore);

  const orderConfirmationsStore = useOrderConfirmationsStore();
  const { orderConfirmations } = storeToRefs(orderConfirmationsStore);

  const { getItem, setItem } = useStorage();

  const fetchOrderInputAsync = async (
    orderId: string,
    currentOperations: OrdersOperList[],
    reason?: number | null,
    comment?: string,
    timeStartInspection?: number,
    finish?: boolean,
    timeDuration?: number,
    AFRUD?: string,
  ) => {
    const currentOrderConfirmation = orderConfirmations.value?.find(
      (orderConfirmation) => orderConfirmation.OrderNumber === orderId,
    );
    const currentDateString = new Date().toString();
    const beginDateString = timeStartInspection
      ? new Date(timeStartInspection).toString()
      : currentDateString;

    const storageMaterialsKey = `${STORAGE_MATERIALS}_${orderId}`;

    const selectedMaterials = await getItem(storageMaterialsKey);
    const userShortName = [profile.value.last_name];

    if (profile.value.first_name) {
      userShortName.push(`${profile.value.first_name.charAt(0)}.`);
    }

    if (profile.value.middle_name) {
      userShortName.push(`${profile.value.middle_name.charAt(0)}.`);
    }

    const teamOrder = teams.value?.data?.find((team) => {
      return team.work_orders.some((workOrder) => workOrder.order_id === orderId);
    });

    const orderUsersString = await getItem(`${STORAGE_ORDER_USERS}_${orderId}`);
    const orderUsers: UsersList[] = orderUsersString ? JSON.parse(orderUsersString) : [];

    if (currentOrderConfirmation && timeDuration) {
      const start = currentOrderConfirmation.StartDateWork;
      const finish = currentOrderConfirmation.FinishDateWork;
      const confirmationDifference = differenceInMilliseconds(finish, start);
      const difference = confirmationDifference - timeDuration;
      if (userIsSupervisor.value) {
        const dateAsString = (input: string | Date) => format(input, 'yyyy-MM-dd HH:mm:ss');
        if (difference > 0) {
          currentOrderConfirmation.StartDateWork = dateAsString(subMilliseconds(start, difference));
        } else if (difference < 0) {
          currentOrderConfirmation.StartDateWork = dateAsString(addMilliseconds(start, difference));
        }
      }
    }

    // Данные для мастера
    const inputDataSupervisor = {
      ...(currentOrderConfirmation && {
        StartTimeWork: format(
          currentOrderConfirmation.StartDateWork.toString(),
          'yyyy-MM-dd HH:mm:ss',
        ),
        FinishTimeWork: timeDuration
          ? format(
              new Date(
                new Date(currentOrderConfirmation.StartDateWork).getTime() + timeDuration,
              ).toString(),
              'yyyy-MM-dd HH:mm:ss',
            )
          : format(currentOrderConfirmation.FinishDateWork.toString(), 'yyyy-MM-dd HH:mm:ss'),
      }),
      FlFinalConfClient: true,
      TabNumber: profile.value.personnel_number,
      FiRejectionOrder: !!reason,
    };

    const inputData = {
      OrderNumber: orderId, //Номер заказа
      People: orderUsers.length,
      StartTimeWork: formatDateToUTC(beginDateString, 'yyyy-MM-dd HH:mm:ss'), //Подтвержденное дата и время для момента времени "начало выполнения"
      FinishTimeWork: formatDateToUTC(currentDateString, 'yyyy-MM-dd HH:mm:ss'), //Подтвержденное время для момента времени "конца выполнения"
      DateTimeClient: format(currentDateString, 'yyyy-MM-dd HH:mm:ss'), //Момент вызова метода с устройства
      FlFinalConfClient: false, //"Флаг подтверждения мастером" (для ремонтника false)
      TabNumber: null, //Табельный номер мастера (для ремонтника null)
      FiRejectionOrder: false, //Флаг отмены заказа мастером (для ремонтника false)
      Operations: [],
      UserUpdate: +profile.value.personnel_number,
      ...(userIsSupervisor.value ? inputDataSupervisor : {}),
    } as OrderInput;

    let alertMessage = 'Заказ завершён';

    currentOperations.forEach((operation) => {
      const materialsArray = [] as MaterialsInput[];
      (selectedMaterials ? (JSON.parse(selectedMaterials) as FormStateMaterial[]) : [])?.forEach(
        (material) => {
          if (material.isUsing && material.VORNR === operation.VORNR) {
            materialsArray.push({
              OperationNumber: operation.VORNR, //Номер операции
              MaterialPosnr: +material.POSNR, //Позиция номера сбыта
              MaterialNumber: material.MATNR, //Код материала
              MaterialQuantity: material.MENGE, //Количество материала
              MaterialUnit: material.MSEHT,
            });
          }
        },
      );

      const isOperationCompleted = operation.STATUS === OrderStatus.COMPLETED;

      let flFinalConfValue: boolean = isOperationCompleted;
      let commentIntermediate = comment;
      let isChangeShift: boolean = false;

      if (!reason && commentIntermediate) {
        alertMessage = 'Заказ передан по смене';
        commentIntermediate = `${userShortName.join(' ')} /n ${commentIntermediate}`;
        isChangeShift = true;
      } else if (reason) {
        alertMessage = 'Заказ отменен';
        flFinalConfValue = true;
      } else if (finish && !isOperationCompleted) {
        flFinalConfValue = true;
      }

      const getReasonValue = () => {
        if (isChangeShift && !isOperationCompleted) {
          return 2;
        } else if (operation?.localRejection) {
          return operation?.localRejection.ReasonId;
        } else if (reason) {
          return reason;
        } else if (finish && !isOperationCompleted) {
          return 10;
        }

        return null;
      };
      const getCommentValue = () => {
        if (!currentOrderConfirmation && AFRUD && !operation?.localRejection) {
          return AFRUD;
        } else if (isChangeShift && isOperationCompleted) {
          return '';
        } else if (operation?.localRejection) {
          return operation?.localRejection.Comment;
        } else if (commentIntermediate) {
          return commentIntermediate;
        }

        return '';
      };

      const currentOperationConfirmation = currentOrderConfirmation?.Operations?.find(
        (oper) => oper.OperationNumber === operation.VORNR,
      );

      const getInfoOrderConfirmationChangeShift = () => {
        if (currentOperationConfirmation) {
          const { FlFinalConf, ReasonId } = currentOperationConfirmation;

          if (!FlFinalConf && ReasonId === 2) {
            return {
              FlFinalConf,
              ReasonId,
            };
          }
        }

        return {};
      };

      inputData.Operations.push({
        OperationNumber: operation.VORNR, //Номер операции
        ReasonId: getReasonValue(), //Причина отклонения
        Comment: getCommentValue(), //Комментарий отклонения
        FlFinalConf: flFinalConfValue, //Флаг окончательное подтверждение, false для передать по смене
        OperationArbpl: operation?.ARBPL ? +operation.ARBPL : 0, //Рабочее место
        TeamNumber: ordersOperPlan.value
          ? ordersOperPlan.value?.workgroup?.number
          : teamOrder?.number ?? '', //Код бригады
        Materials: materialsArray,
        ...(currentOperationConfirmation?.TeamNumber && {
          TeamNumber: currentOperationConfirmation?.TeamNumber, //Код бригады
        }),
        ...getInfoOrderConfirmationChangeShift(),
      });
    });

    const tryBlock = async () => {
      await setItem(storageMaterialsKey, JSON.stringify([]));
      const createConfirmResponse = await fetchOrderInput(inputData).catch(() => ({
        //если приложение не подключено к интернету то показываем уведомление об успешной отправке
        status: window.navigator.onLine ? 0 : 200,
      }));
      handleResponse(createConfirmResponse as AxiosResponse, alertMessage);
    };
    await handleLoading({ tryBlock, funcName: 'fetchOrderInputAsync' });
  };

  return {
    confirm,
    fetchOrderInputAsync,
  };
});
