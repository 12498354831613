import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';

import { useIdlesListStore } from '../model/useIdlesListStore';

export const useGetIdlesList = (IM_WERKS: string) => {
  const idlesListStore = useIdlesListStore();

  const { idlesList, idlesFetchError } = storeToRefs(idlesListStore);

  const reloadFetchRequest = () => {
    idlesListStore.fetchIdlesListAsync({ IM_WERKS });
  };

  onMounted(() => {
    reloadFetchRequest();
  });
  return { idlesList, idlesFetchError, reloadFetchRequest };
};
