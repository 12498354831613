import { storeToRefs } from 'pinia';
import { type Ref, watch } from 'vue';

import { useUserProfileStore } from '../model/useUserProfileStore';

export const useGetUserProfile = (keycloakToken?: Ref<string>) => {
  const userProfileStore = useUserProfileStore();
  const { profile } = storeToRefs(userProfileStore);

  watch(
    () => keycloakToken?.value,
    (newValue) => {
      if (newValue) userProfileStore.fetchUserProfileAsync(newValue);
    },
  );

  return {
    profile,
  };
};
