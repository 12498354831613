import { ref, watchEffect } from 'vue';

import type { OrdersHistory } from '../interfaces/Orders';
import { useGetOrders } from './useGetOrders';

export const useGetOrderHistory = (orderId: string) => {
  const { orders } = useGetOrders();
  const history = ref<OrdersHistory[]>([]);

  watchEffect(() => {
    const orderEquipmentNumber = orders.value?.EX_HEADERS?.find(
      (item) => item.AUFNR === orderId,
    )?.EQUNR;
    orders.value?.EX_HISTORY_ORDER.forEach((historyItem) => {
      if (historyItem.EQUNR === orderEquipmentNumber) {
        history.value?.push(historyItem);
      }
    });
  });

  return { history };
};
