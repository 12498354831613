<script setup lang="ts">
import { format } from 'date-fns';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

import { SelectedIcon } from '@/shared/assets/svg';
import { useUserProfileStore } from '@/shared/model';
import { DrawerCustomize } from '@/shared/ui';

import type { AbsentReason } from '../../interfaces';
import type { UserUpdateInput } from '../../interfaces/UserUpdateInput';
import { absentReasonsList } from '../../lib';
import { useUserUpdateStore } from '../../model/useUserUpdateStore';

interface Props {
  userId?: string;
  teamId?: string;
  open: boolean;
  onClose: () => void;
}

const { userId, teamId, open, onClose } = defineProps<Props>();
const { updateUserAsync } = useUserUpdateStore();
const { profile } = storeToRefs(useUserProfileStore());
const reason = ref<AbsentReason>();

const handleReason = (item: AbsentReason) => {
  reason.value = item;
};

const handleClose = () => {
  reason.value = undefined;
  onClose();
};

const onSubmit = async () => {
  if (reason.value && userId) {
    const input: UserUpdateInput = {
      pernr: userId,
      presence: false,
      deviation_code: reason.value.deviation_code,
      deviation_name: reason.value.deviation_name,
      date: format(new Date(), 'yyyy-MM-dd'),
      time_begin: '00:00:00',
      time_end: '23:59:59',
      source: 'МТОРО',
      edit_date: format(new Date(), 'yyyy-MM-dd'),
      edit_user: profile.value.user_id,
    };
    updateUserAsync({ input, userId, teamId });
  }

  handleClose();
};
</script>

<template>
  <DrawerCustomize :title="'Выберите причину'" :open="open" @close="() => handleClose()">
    <div :class="$style.list">
      <div
        v-for="item in absentReasonsList"
        :key="item.deviation_code"
        :class="$style.item"
        @click="handleReason(item)"
      >
        {{ item.deviation_name }}
        <SelectedIcon v-if="item.deviation_code === reason?.deviation_code" />
      </div>
    </div>

    <div :class="$style.buttons">
      <a-button
        type="primary"
        size="large"
        :class="$style.button"
        @click="onSubmit"
        :disabled="!reason"
        >Отправить</a-button
      >
    </div>
  </DrawerCustomize>
</template>

<style module lang="scss">
.buttons {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: center;
  background: var(--color-white, #fff);
  border-top: 1px solid #eeeeee;
}

.button {
  width: 100%;
}

.list {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.item {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-text, #333);
  border-top: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;

  &:first-child {
    border-top: none;
  }

  svg {
    fill: var(--color-primary, #ef7f1a);
  }
}
</style>
