<script setup lang="ts">
import { computed, ref } from 'vue';

import { SearchIcon } from '@/shared/assets/svg';

import { useGetUsersList, UserRow } from '../..';
import styles from './SelectUsers.module.scss';

interface Props {
  onSelected: (id: string[]) => void;
  selected: string[];
  onShowModal: (show: boolean) => void;
}

const { onSelected, selected = [], onShowModal } = defineProps<Props>();

const { usersList } = useGetUsersList();

const search = ref<string>('');
const tabNumbers = ref<string[]>(selected);

const filteredUsers = computed(() => {
  return usersList.value?.filter(
    (user) =>
      !selected.includes(user.personnel_number) &&
      user.working &&
      (search.value ? user.last_name?.toLowerCase().includes(search.value?.toLowerCase()) : user),
  );
});

const hideModalActions = () => {
  onShowModal(false);
  document.documentElement.style.overflow = '';
};

const addUsers = () => {
  onSelected(Array.from(tabNumbers.value));
  hideModalActions();
};

const handleSelected = (tabNumber: string) => {
  tabNumbers.value = tabNumbers.value.includes(tabNumber)
    ? tabNumbers.value.filter((userId) => userId !== tabNumber)
    : [...tabNumbers.value, tabNumber];
};
</script>

<template>
  <div :class="styles.search">
    <a-input v-model:value="search" placeholder="Поиск">
      <template #addonBefore><SearchIcon /></template>
    </a-input>
  </div>
  <div :class="styles.list">
    <UserRow
      v-for="user in filteredUsers"
      :key="user.personnel_number"
      :user="user"
      :selected-list="tabNumbers"
      :on-selected="handleSelected"
    />
  </div>
  <div :class="styles.buttons">
    <a-button
      :disabled="!tabNumbers.length"
      type="primary"
      size="large"
      :class="styles.button"
      @click="addUsers"
      >Добавить</a-button
    >
  </div>
</template>
