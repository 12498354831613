import { ref, watchEffect } from 'vue';

import type { FileItem } from '@/shared/interfaces';

import { useGetOrders } from './useGetOrders';

export const useGetOrderFiles = (orderId: string) => {
  const { orders } = useGetOrders();
  const files = ref<FileItem[]>([]);

  watchEffect(() => {
    orders.value?.EX_FILES_LIST.forEach((file) => {
      if (file.AUFNR === orderId) {
        files.value?.push(file);
      }
    });
  });

  return { files };
};
