<script setup lang="ts">
import { useRoute } from 'vue-router';

import { OperationsList, useGetOrderInfo } from '@/entities/Orders';
import { HandleRequest } from '@/shared/ui';

const route = useRoute();
const { orderId } = route.params;
const { order, ordersFetchError, reloadOrders } = useGetOrderInfo(orderId as string);
</script>

<template>
  <div :class="$style.widget">
    <HandleRequest
      :no-data="!order?.EX_OPER_LIST?.length"
      :error-request="ordersFetchError"
      :on-repeat="reloadOrders"
    />
    <OperationsList
      :with-title="false"
      v-if="order?.EX_OPER_LIST"
      :operations="order.EX_OPER_LIST"
      show-status
      show-number
    />
  </div>
</template>

<style lang="scss" module>
.widget {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 12px;
  padding-bottom: 76px;
}
</style>
