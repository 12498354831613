import type { Symptom } from '../interfaces';
import { SymptomCodeGropes } from '../interfaces/Symptoms';

export const getSymptomsByCatalogCode = (catalogCode: string, symptoms: Symptom[]) => {
  const filtered = symptoms.filter(
    (symptom) => symptom.RBNR === catalogCode && symptom.CODEGRUPPE !== SymptomCodeGropes.TORSO,
  );
  filtered.sort((a, b) => {
    //первыми должны  идти те что заканчиваются на 3
    const lastCodeSymbol = '3';
    // по последнему символу
    const codeA = a.CODEGRUPPE.charAt(a.CODEGRUPPE.length - 1);
    const codeB = b.CODEGRUPPE.charAt(a.CODEGRUPPE.length - 1);

    if (codeA === lastCodeSymbol && codeB !== lastCodeSymbol) {
      return -1;
    } else if (codeA !== lastCodeSymbol && codeB === lastCodeSymbol) {
      return 1;
    } else {
      return 0;
    }
  });
  return filtered;
};
