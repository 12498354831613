import type { PageTab } from '@/shared/interfaces';
import { OrderCommentsWidget } from '@/widgets/OrderCommentsWidget';
import { OrderDefectsWidget } from '@/widgets/OrderDefectsWidget';
import { OrdersHistoryWidget } from '@/widgets/OrdersHistoryWidget';
import { WorksDetailsWidget } from '@/widgets/WorksDetailsWidget';
import { WorksWidget } from '@/widgets/WorksWidget';

export const tabs: PageTab[] = [
  {
    name: 'Работы',
    component: WorksWidget,
    id: 1,
  },
  {
    name: 'Детали',
    component: WorksDetailsWidget,
    id: 2,
  },
  {
    name: 'Дефекты',
    component: OrderDefectsWidget,
    id: 3,
  },
  {
    name: 'История',
    component: OrdersHistoryWidget,
    id: 4,
  },
  {
    name: 'Комментарий',
    component: OrderCommentsWidget,
    id: 5,
  },
];
