<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { useAlertStore } from '@/shared/model/useAlertStore';

import styles from './AlertInfo.module.scss';

const alertStore = useAlertStore();
const { alert } = storeToRefs(alertStore);
</script>

<template>
  <div :class="styles.alert" v-if="alert">
    <a-alert
      :message="alert.message"
      :type="alert.type"
      closable
      show-icon
      :after-close="alertStore.clearAlert"
    >
    </a-alert>
  </div>
</template>
