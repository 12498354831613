import { storeToRefs } from 'pinia';
import { ref, watchEffect } from 'vue';

import { EquipmentStatuses } from '@/shared/interfaces';

import { type Equipment, type EquipmentNodesByParent } from '../interfaces/Equipment';
import { useEquipmentStore } from '../model/useEquipmentStore';

export const useGetEquipmentByParentNodes = (currentNodeEQUNR?: string) => {
  //Получение единиц оборудования в разбивке по родительским нодам. Ноды определяем по HEQUI
  const equipmentStore = useEquipmentStore();
  const { equipmentList } = storeToRefs(equipmentStore);
  const equipmentsNodesByParentNodes = ref<EquipmentNodesByParent | undefined>(undefined);
  const currentNode = ref<Equipment | undefined>(undefined);
  watchEffect(() => {
    if (equipmentList.value) {
      equipmentList.value?.EX_EQUIP_LIST?.forEach((item) => {
        const statusArray = item.STTXU?.split(' ') || [];
        if (item.EQUNR === currentNodeEQUNR) {
          currentNode.value = item;
        }
        if (!equipmentsNodesByParentNodes.value) {
          equipmentsNodesByParentNodes.value = {};
        }
        if (!equipmentsNodesByParentNodes.value[item.HEQUI]) {
          equipmentsNodesByParentNodes.value[item.HEQUI] = [];
        }
        if (
          statusArray.includes(EquipmentStatuses.DEFECTS_LEVEL) ||
          statusArray.includes(EquipmentStatuses.MTORO_LEVEL)
        ) {
          //В узлы попадают только те единицы на которые можно сделать дефект(УДЕФ) и статус СТМТ(статус моб торо для отображения группы ЕО со статусами УДЕФ)
          equipmentsNodesByParentNodes.value[item.HEQUI].push(item);
        }
      });
    }
  });
  return {
    equipmentsNodesByParentNodes,
    currentNode,
  };
};
