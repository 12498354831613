import { format } from 'date-fns';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';

import { useUserProfileStore } from '@/shared/model';

import { useOrdersOperPlanStore } from '../model/useOrdersOperPlanStore';

export const useGetOrdersOperPlan = () => {
  const ordersStore = useOrdersOperPlanStore();
  const { ordersOperPlan, ordersOperPlanFetchError } = storeToRefs(ordersStore);
  const { profile } = storeToRefs(useUserProfileStore());

  const reloadFetchRequest = () => {
    return ordersStore.fetchOrdersOperPlanAsync({
      userId: profile.value.user_id,
      startDate: format(new Date(), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
    });
  };

  watch(
    () => profile.value.personnel_number,
    () => {
      if (!ordersOperPlan.value && profile.value.personnel_number) reloadFetchRequest();
    },
  );

  return { ordersOperPlan, ordersOperPlanFetchError, reloadFetchRequest };
};
