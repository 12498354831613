<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { onUnmounted } from 'vue';

import type { PageTab } from '@/shared/interfaces';
import { useTabsStore } from '@/shared/model';

import styles from './PageTabs.module.scss';

interface Props {
  tabs: PageTab[];
  saveTabs?: boolean;
  additionalHash?: string;
  noWrap?: number[]; //пустой массив если не нужно оборачивать все табы
}
const { tabs, saveTabs, noWrap, additionalHash } = defineProps<Props>();
const tabsStore = useTabsStore();

const namesHash = `${additionalHash} ${tabs.map((tab) => tab.name).join('')}`;
const { activeTabsByNamesHash } = storeToRefs(tabsStore);

const noWrapAllPages = noWrap && noWrap?.length === 0;

const onTabChange = (key: string) => {
  tabsStore.setActiveTabsByNamesHash(namesHash, +key);
};

onUnmounted(() => {
  !saveTabs && tabsStore.setActiveTabsByNamesHash(namesHash, 1);
});
</script>

<template>
  <a-tabs
    :class="styles.tabs"
    v-model:activeKey="activeTabsByNamesHash[namesHash]"
    size="medium"
    :tab-bar-gutter="0"
    :animated="false"
    @change="onTabChange"
  >
    <a-tab-pane v-for="(item, index) in tabs" :key="item.id" :tab="item.name">
      <div
        :class="{
          [styles.pageContent]: !(noWrapAllPages || noWrap?.includes(index)),
        }"
      >
        <component :is="item.component" v-bind="item.props"></component>
      </div>
    </a-tab-pane>
  </a-tabs>
</template>
