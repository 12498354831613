import type { FileItem, Material, StatusLabels, UserInformation } from '@/shared/interfaces';

import type { OrderConfirmationStatuses } from './OrderConfirmations';

export enum OrderTypesName {
  MAINTENANCE = 'MAINTENANCE',
  INSPECTION = 'INSPECTION',
  CURRENT_REPAIR = 'CURRENT_REPAIR',
  ALL = 'ALL',
}

export enum OrderTypes {
  EMERGENCY = 'YM01', //Заказ на аварийный ТОиР
  CORRECTION = 'YM02', //Заказ на корректирующий ТОиР
  PLAN = 'YM03', //Заказ на плановый ТОиР
}

export enum OrderSubTypes {
  TECHNICAL_MAINTENANCE = '101', // Техническое обслуживание (ТО)
  TECHNICAL_MAINTENANCE_1 = '102',
  TECHNICAL_MAINTENANCE_2 = '103',
  TECHNICAL_MAINTENANCE_3 = '104',
  TECHNICAL_MAINTENANCE_4 = '105',
  TECHNICAL_MAINTENANCE_5 = '106',
  TECHNICAL_MAINTENANCE_6 = '107',
  TECHNICAL_MAINTENANCE_7 = '108',
  TECHNICAL_MAINTENANCE_8 = '109',
  TECHNICAL_MAINTENANCE_9 = '110',
  TECHNICAL_MAINTENANCE_10 = '111',
  CURRENT_REPAIR = '118', // Текущий ремонт (ТР)
  CURRENT_REPAIR_1 = '119', // ТР первого объема (ТР-1)
  CURRENT_REPAIR_2 = '120', // ТР первого объема (ТР-2)
  CURRENT_REPAIR_3 = '121', // ТР третьего объема (ТР-3)
  AVERAGE_REPAIR = '122', // Средний ремонт (СР)
  EMERGENCY_REPAIR = '125', // Аварийно-восстан ремонт (АВР)
  ACCORDING_CONDITION_REPAIR = '126', // Ремонт по тех. состоянию (РТС)
  EXPERT_TECHNICAL_DIAGNOSTIC = '128', // Эксперт. техн. диагностирование
  PERIODICAL_REPAIR = '130', // Периодический осмотр
  TECHNICAL_MAINTENANCE_11 = '133',
}

export interface Order {
  //todo убрать когда заменим все моки
  id: number;
  name: string;
  subtitle: string;
  time?: string;
  startTime?: string;
  endTime?: string;
  timeSpent?: string;
  currentTime?: string;
  status: StatusLabels;
  type: OrderTypesName;
  operatingTime?: string;
}

export interface OrdersHistory {
  EQUNR: string; // Номер оборудования
  AUFNR: string; //Номер заказа
  STTXT: OrderStatus; //статус
  IEDD: string; //Дата
  ARBPL: string;
  KTEXT: string; //Название заказа
  IEDZ: string; //Время
  TABN: string; //Табельный номер
  userInfo?: UserInformation; //Данные о пользователе после мэпа по табельному номеру
}

export enum OrderStatus {
  NOT_STARTED = 'ДЕБЛ', // НЕ НАЧАТ
  IN_PROGRESS = 'ЧПДТ', // НАЧАТ
  COMPLETED = 'ПДТВ', // ВЫПОЛНЕН
  CLOSED = 'ЗАКР', // ВЫПОЛНЕН
  CLOSED_WITH_ERRORS = 'ТЗКР', // ВЫПОЛНЕН
  CANCELED = '4000', // ОТКЛОНЕН
  PENDING = '1', // НА ПОДТВЕРЖДЕНИИ из OrderConfirmations
}

export interface OrderComment {
  AUFNR: string; //Номер заказа
  VORNR: string; //Номер операции
  LTXA1: string; //Текст комментария
  IEDD: string; //Дата
  IEDZ: string; //Время
}

export interface OrdersHeaders {
  AUFNR: string; //Номер заказа
  AUART: string; //Тип заказа
  ILART: string; // Подтип заказа
  STTXT: OrderStatus; //статус
  KTEXT: string; //Название заказа
  TPLNR: string; //Техническое место
  PLTXT: string; //Название технического места
  EQUNR: string; //Номер оборудования
  EQKTX: string; //Название оборудования
  GSTRP: string; //Базисный срок начала дата
  GSUZP: string; //Базисный срок начала время
  GLTRP: string; //Базисный срок конца дата
  GLUZP: string; //Базисный срок конца время
  TOTALTIME: number; //  Фактическое время заказа
  timeBegin?: number; //Время начала заказа
  timeDuration?: number; //Время продолжительности (только для мастера)
  StatusId?: OrderConfirmationStatuses; //Статус подтверждения заказа ремонтником (добавляем при мэпе данных сам и заказов из фм)
}

export interface OrdersOperList {
  AUFNR: string; // Номер заказа
  EQUNR: string; // Номер оборудования
  EQKTX: string; // Название оборудования
  TIDNR: string;
  VORNR: string; //Номер операции в заказе
  LTXA1: string; //Название операции
  DESCR: string; //Описание операции (шаги)
  STATUS: OrderStatus; //Статус
  ARBPL?: string;
  TPLNR: string; //Техническое место
  STTXU: string; //статус оборудования
  RBNR: string; //Каталог кодов ТОРО
  localDefects?: string[]; // Локально зафиксированные дефекты у операции
  localRejection?: LocalRejection; // Локально отменена операция
  localChecked?: boolean; // Локальное подтверждение операции супервайзером
  CANCEL?: string;
  ReasonId?: number; //причина отклонения заказа ремонтником  (добавляем при мэпе данных сам и заказов из фм)
  FlFinalConf: boolean; // (добавляем при мэпе данных сам и заказов из фм)
  EX_VPS?: OrdersVps[];
}

export interface OrdersVps {
  AUFNR: string; // Номер заказа
  FHMAR: string;
  VORNR: string;
  POINT: string;
  PTTXT: string;
  MDOCM: string;
  IDATE: string;
  ITIME: string;
  CNTRC: string;
  READC: string;
  UNITC: string;
  MSEHL: string;
  DECIM: number;
}

export interface OrdersOperListWithVps extends OrdersOperList {
  EX_VPS: OrdersVps[];
}

export interface OrdersHeadersWithOperList extends OrdersHeaders {
  EX_OPER_LIST: OrdersOperList[];
}

export interface OrdersHeadersWithOperListAndComponent extends OrdersHeaders {
  EX_OPER_LIST: OrdersOperListWithVps[];
  EX_COMPONENT?: Material[];
  EX_COMMENT?: OrderComment[];
  operationsHaveVps: boolean;
  countTags: number; //Подсчёт меток у заказа
  timeBegin?: number; //Время начала осмотра
  EX_HISTORY_ORDER?: OrdersHistory;
}

export interface OrdersHeadersWithOperListAndComponentHistoryAndComponent extends OrdersHeaders {
  EX_HISTORY_ORDER?: OrdersHistory;
  EX_OPER_LIST?: OrdersOperList[];
  EX_COMPONENT?: Material[];
}

export interface OrdersResponse {
  EX_COMMENT: OrderComment[];
  EX_COMPONENT: Material[];
  EX_FILES_LIST: FileItem[];
  EX_HEADERS: OrdersHeaders[];
  EX_HISTORY_ORDER: OrdersHistory[];
  EX_OPER_LIST: OrdersOperList[];
  EX_VPS: OrdersVps[];
}

export type OrdersHistoryResponse = Omit<OrdersResponse, 'EX_FILES_LIST' | 'EX_VPS'>;

export interface WorkerData {
  user_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  fullname: string;
  schedule: number;
  position: string;
  personnel_number: string;
  work_duration: number;
  phone_number: string;
  photo_url: string;
}

export interface OrdersOperPlan {
  orders: {
    order_id: string;
    operations: string;
  }[];
  workgroup: {
    id: string;
    number: string;
    workers_count: number;
    workgroup_capacity: number;
    orgunit: {
      name: string;
      short_name: string;
      org_management_id: string;
      sap_code: string;
      id: string;
      updated_at: string;
      type: string;
      kind: string;
      created_at: string;
      parent_id: string;
    };
    specialization: string;
    supervisor_fullname: string;
    supervisor: {
      user_id: string;
      first_name: string;
      middle_name: string;
      last_name: string;
      work_duration: number;
      position: string;
      personnel_number: string;
      phone_number: string;
      photo_url: string;
    };
    workers_data: {
      user_id: string;
      first_name: string;
      middle_name: string;
      last_name: string;
      work_duration: number;
      position: string;
      personnel_number: string;
      fullname: string;
      phone_number: string;
      photo_url: string;
    }[];
  };
}

export interface OrdersOperPlanRequest {
  userId: string;
  startDate: string;
  endDate: string;
}

export interface LocalRejection {
  ReasonId: number | null;
  Comment: string;
}
