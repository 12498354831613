<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { DefectsList, useGetOrdersDefectsList } from '@/entities/Defects';
import { HandleRequest } from '@/shared/ui';

import { useGetDefectsInspection } from '../lib/hooks/useGetDefectsInspection';

const route = useRoute();
const { orderId } = route.params;

const { equipmentIds } = useGetDefectsInspection(orderId as string);
const { ordersDefectsList, filesList, ordersDefectsFetchError, reloadFetchRequest } =
  useGetOrdersDefectsList({
    IM_WERKS: '',
    IM_TPLNR: '',
    IM_EQUNR: equipmentIds.value,
    IM_EQUNR_HISTORY: [],
    IM_QMNUM: '',
  });

const defectsOfCurrentOrders = computed(() => {
  return ordersDefectsList?.value?.EX_DEFECT_LIST.filter((defect) =>
    equipmentIds.value.flat().includes(defect.EQUNR),
  );
});
</script>

<template>
  <HandleRequest
    :no-data="!defectsOfCurrentOrders?.length"
    :error-request="ordersDefectsFetchError"
    :on-repeat="reloadFetchRequest"
  />
  <DefectsList
    v-if="defectsOfCurrentOrders"
    :items="defectsOfCurrentOrders"
    :files-list="filesList"
    :auto-load-files="true"
    :is-link="true"
    :order-defect="true"
  />
</template>
