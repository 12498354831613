<script setup lang="ts">
import { onMounted, ref } from 'vue';

import styles from './DefaultSlider.module.scss';

interface Props {
  onAfterChange?: (current: number) => void;
}

const { onAfterChange } = defineProps<Props>();

const mountRef = ref(false);
onMounted(() => {
  mountRef.value = true; //без этого будет ошибка ResizeObserver
});
</script>

<template>
  <div :class="styles.wrap">
    <a-carousel v-if="mountRef" :after-change="onAfterChange">
      <slot> </slot>
    </a-carousel>
  </div>
</template>
