import { storeToRefs } from 'pinia';
import { ref, watchEffect } from 'vue';

import { useGetUserProfile } from '@/shared/hooks';
import { DEFAULT_IM_WERKS, enterprises } from '@/shared/lib';

import { useEquipmentStore } from '../model/useEquipmentStore';

export const useGetEquipList = () => {
  const equipmentStore = useEquipmentStore();
  const { profile } = useGetUserProfile();
  const enterpriseSapCode = profile.value?.enterprise_sap_code ?? DEFAULT_IM_WERKS;

  const { equipmentList, equipmentListFetchError, equipmentSearchValue, lastSearchRoute } =
    storeToRefs(equipmentStore);
  const clearedEquipmentList = ref(equipmentList.value);

  watchEffect(() => {
    if (equipmentList.value) {
      clearedEquipmentList.value = {
        // поле NOACTIVE со значением X оставили для ЕЭК и ФООР, ранее для других заводов оно не бралось в счет
        EX_EQUIP_LIST:
          enterpriseSapCode === enterprises.EEK || enterpriseSapCode === enterprises.ODPP
            ? equipmentList.value.EX_EQUIP_LIST
            : equipmentList.value.EX_EQUIP_LIST?.filter((item) => item.NOACTIVE !== 'X') || [],
        EX_FILES_LIST: equipmentList.value.EX_FILES_LIST,
        EX_MEASURE: equipmentList.value.EX_MEASURE,
      };
    }
  });

  const reloadFetchRequest = () => {
    equipmentStore.fetchEquipmentListAsync();
  };

  return {
    equipmentList: clearedEquipmentList,
    equipmentListFetchError,
    equipmentSearchValue,
    lastSearchRoute,
    reloadFetchRequest,
  };
};
