<script setup lang="ts">
import { DismissCircleIcon } from '@/shared/assets/svg';
import { StatusLabels } from '@/shared/interfaces';
import { HorizontalSwipeAble, StatusIcon } from '@/shared/ui';

import { type OrdersOperList, OrderStatus } from '../../interfaces/Orders';

interface Props {
  swipeAble?: boolean;
  onReject?: (id: number) => void;
  url?: string;
  urlTag?: boolean;
  showStatus?: boolean;
  showNumber?: boolean;
  isLink?: boolean;
  operation: OrdersOperList;
  handleStatusIcon?: (e: Event, operationId: string) => void;
  isSupervisor?: boolean;
  isInspectionOrder?: boolean;
}

const {
  swipeAble,
  onReject,
  url,
  urlTag,
  showStatus,
  showNumber,
  isLink,
  operation,
  handleStatusIcon,
  isSupervisor,
  isInspectionOrder,
} = defineProps<Props>();

const component = swipeAble ? HorizontalSwipeAble : 'div';

const getOperationStatus = () => {
  if (operation.FlFinalConf !== undefined) {
    if (operation.STATUS === OrderStatus.COMPLETED && !operation.ReasonId) {
      return StatusLabels.COMPLETED;
    } else if (
      (operation.STATUS === OrderStatus.COMPLETED && operation.ReasonId) ||
      (operation.STATUS === OrderStatus.IN_PROGRESS && operation.ReasonId === 2)
    ) {
      return StatusLabels.CANCELED;
    }

    return StatusLabels.IN_PROGRESS;
  } else {
    if (
      operation.STATUS === OrderStatus.IN_PROGRESS ||
      operation.STATUS === OrderStatus.NOT_STARTED ||
      !operation.STATUS
    ) {
      return StatusLabels.IN_PROGRESS;
    }
    if (operation.STATUS === OrderStatus.COMPLETED) {
      if (operation.CANCEL === 'X' && !isInspectionOrder) {
        return StatusLabels.CANCELED;
      }
    }
    return StatusLabels.COMPLETED;
  }
};
</script>

<template>
  <component :on-right-slot-click="onReject" :is="component" :item-id="operation.VORNR">
    <component
      :is="isLink ? 'router-link' : 'div'"
      :to="
        isLink
          ? `${url}/${urlTag ? operation.TIDNR : operation.AUFNR}/${operation.VORNR}`
          : undefined
      "
      :class="{ [$style.link]: true, [$style.finish]: isSupervisor && operation.localChecked }"
    >
      <StatusIcon
        v-if="
          (showStatus && !isInspectionOrder) ||
          (isInspectionOrder && operation.STATUS === OrderStatus.COMPLETED)
        "
        :status="getOperationStatus()"
        @click="(e) => handleStatusIcon?.(e, operation.VORNR)"
      />
      <a-typography-text :class="$style.text">
        {{ showNumber ? `${parseInt(operation.VORNR, 10)}. ` : '' }}
        {{ operation.LTXA1 }}</a-typography-text
      >
      <a-typography-text v-if="operation.localDefects && showStatus" :class="$style.count">{{
        operation.localDefects.length
      }}</a-typography-text>
      <ChevronRightFilledIcon v-if="isLink" :class="$style.arrow" />
    </component>
    <template #rightButtonSlot> <DismissCircleIcon />Отклонить</template>
  </component>
</template>

<style lang="scss" module>
.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 12px 12px 12px 16px;
  background-color: var(--color-white, #fff);
  position: relative;
  text-decoration: none;

  &:after {
    content: '';
    position: absolute;
    width: calc(100% - 16px);
    height: 1px;
    right: 0;
    top: 0;
    background-color: var(--colorBorder, rgba(0, 0, 0, 0.15));
  }
}

.finish {
  background-color: var(--colorErrorOutline, #fff1f0);
}

.text {
  font-size: 17px;
  line-height: 140%;
  margin-right: auto;
}

.count {
  background-color: #f7f8fa;
  color: var(--color-text, #333333);
  border-radius: 6px;
  font-size: 20px;
  font-weight: 600;
  padding: 0 4px;
  height: 36px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.arrow {
  display: flex;
  width: 20px;
  min-width: 20px;
  height: 20px;
  fill: rgba(0, 0, 0, 0.45);
}
</style>
