<script setup lang="ts">
import { format } from 'date-fns';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

import { useTechnologistStore } from '@/entities/Users';
import type { UsersList } from '@/entities/Users/interfaces';
import { useTeamsStore } from '@/entities/Users/model/useTeamsStore';
import { UserItem } from '@/entities/Users/ui/UserItem';
import AbsentModal from '@/features/UsersManagement/ui/AbsentModal/AbsentModal.vue';
import { UsersIcon } from '@/shared/assets/svg';
import { useUserProfileStore } from '@/shared/model';
import { HandleRequest } from '@/shared/ui';

import styles from './TeamsWidget.module.scss';

const teamsStore = useTeamsStore();
const { teams, teamsFetchError } = storeToRefs(teamsStore);
const technologistStore = useTechnologistStore();
const { technologist, technologistFetchError } = storeToRefs(technologistStore);
const { profile } = storeToRefs(useUserProfileStore());

const handleReloadTeams = () => {
  teamsStore.fetchTeamsAsync({
    supervisorId: profile.value.user_id,
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  });
};

const handleReloadTechnologist = () => {
  technologistStore.fetchTechnologistAsync({
    supervisorTechnologistId: profile.value.user_id,
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  });
};
const handleReload = () => {
  if (!teamsFetchError.value) {
    handleReloadTeams();
  }
  if (!technologistFetchError.value) {
    handleReloadTechnologist();
  }
};

const absentUserId = ref<string | undefined>(undefined);
const absentUserTeamId = ref<string | undefined>(undefined);
const handleDismiss = (userId: string, teamId?: string) => {
  absentUserId.value = userId;
  if (teamId) absentUserTeamId.value = teamId;
};

const activeKey = ref(['0']);
</script>

<template>
  <HandleRequest
    :no-data="!teams?.data.length && !technologist?.length"
    :error-request="teamsFetchError || technologistFetchError"
    :on-repeat="handleReload"
  />
  <div :class="styles.list" v-if="teams?.data.length">
    <div :class="styles.group">
      <a-typography-text :class="styles.title">Бригады</a-typography-text>

      <a-collapse v-model:activeKey="activeKey" v-for="team in teams?.data">
        <div>
          <div :class="styles.headerName">{{ team.name }}</div>
          <UserItem
            :key="team.supervisor.personnel_number"
            :user="
              { ...team.supervisor, phone_number: [team.supervisor.phone_number] } as UsersList
            "
            :swipe-able="true"
            :show-user-position="true"
            :on-dismiss="(id) => handleDismiss(id, team.id)"
            :avatar-size="40"
            text-dismiss="Отсутствует"
            is-supervisor
            :hide-phone="profile.personnel_number === team.supervisor.personnel_number"
          />
          <div :class="styles.info">
            <a-typography-text :class="styles.users"
              ><UsersIcon />{{ team.workers_count }}/<a-typography-text>{{
                team.workers_count_max
              }}</a-typography-text></a-typography-text
            >
            <a-typography-text :class="styles.hours">
              <span :class="team.workgroup_capacity_max > team.workgroup_capacity && styles.exceed">
                {{ team.workgroup_capacity_max }}</span
              >
              /
              {{ team.workgroup_capacity }} ч
            </a-typography-text>
          </div>
        </div>
        <a-collapse-panel :key="team.id">
          <UserItem
            v-for="(user, index) in team.workers_data"
            :key="user.personnel_number"
            :user="{ ...user, phone_number: [user.phone_number] } as UsersList"
            :index="index"
            :swipe-able="true"
            :show-user-position="true"
            :on-dismiss="(id) => handleDismiss(id, team.id)"
            :avatar-size="40"
            text-dismiss="Отсутствует"
            hide-phone
          />
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
  <div :class="styles.list" v-if="technologist?.length">
    <div :class="styles.group">
      <a-typography-text :class="styles.title">Исполнители</a-typography-text>
      <div v-for="technologistItem in technologist" :key="technologistItem.user_id">
        <UserItem
          :key="technologistItem.user_id"
          :user="
            {
              ...technologistItem,
              personnel_number: technologistItem.sap_client_id,
              phone_number: [technologistItem.mobile],
            } as UsersList
          "
          :swipe-able="true"
          :show-user-position="true"
          :on-dismiss="(id) => handleDismiss(id)"
          :avatar-size="40"
          text-dismiss="Отсутствует"
        />
        <div :class="styles.info">
          <a-typography-text :class="styles.users"
            ><UsersIcon />{{ technologistItem.working ? 1 : 0 }}/<a-typography-text>{{
              Boolean(technologistItem.work_duration) ? 1 : 0
            }}</a-typography-text></a-typography-text
          >
          <a-typography-text :class="styles.hours">
            <span> {{ technologistItem.working ? technologistItem.work_duration : 0 }}</span>
            /
            {{ technologistItem.work_duration || 0 }} ч
          </a-typography-text>
        </div>
      </div>
    </div>
  </div>
  <AbsentModal
    v-if="Boolean(absentUserId)"
    :open="Boolean(absentUserId)"
    :on-close="() => (absentUserId = undefined)"
    :user-id="absentUserId"
    :team-id="absentUserTeamId"
  />
</template>
