<script setup lang="ts">
import { format } from 'date-fns';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { NoAvatar } from '@/shared/assets/svg';
import { APP_URL } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import QueueCounter from '@/shared/ui/QueueCounter.vue';

const userProfileStore = useUserProfileStore();
const { profile } = storeToRefs(userProfileStore);
const lastSyncDate = computed(() =>
  profile.value?.last_sync_date
    ? format(parseInt(profile.value?.last_sync_date), 'dd.MM.yyyy HH:mm')
    : '-',
);
const fullName = computed(
  () => `${profile?.value.last_name ?? ''} ${profile?.value.first_name ?? ''}`,
);
const avatarImg = computed(() =>
  profile.value.photo_url ? `${APP_URL}${profile.value.photo_url}` : undefined,
);
</script>

<template>
  <div :class="$style.container">
    <div :class="$style.profile">
      <div :class="$style.wrap">
        <a-typography-title :level="2" :class="$style.name">
          {{ fullName }}
        </a-typography-title>
      </div>
      <a-avatar
        v-if="avatarImg"
        :size="52"
        shape="square"
        :src="avatarImg"
        :class="$style.avatar"
      />
      <NoAvatar v-else :class="$style.avatar" />
    </div>
    <a-typography-text :class="$style.sync">
      Посл. синхронизация <span>{{ lastSyncDate }}</span
      ><QueueCounter with-prefix-comma />
    </a-typography-text>
  </div>
</template>

<style lang="scss" module>
.container {
  background: var(--color-primary, #ef7f1a);
  padding: 12px 16px 20px;
  color: var(--color-white, #fff);
}

.profile {
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  color: var(--color-white, #fff);
}

.name,
.sync {
  margin-bottom: 0 !important;
  color: var(--color-white, #fff) !important;
}

.sync {
  & > span:last-child {
    opacity: 0.5;
  }
}

.avatar {
  border-radius: 4px;
  min-width: 44px;
}
</style>
