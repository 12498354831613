import type { OrdersResponse } from '@/entities/Orders';
import type { WorkgroupData } from '@/entities/Users';

import type { WorkgroupDataWithFmpOrders } from '../interfaces';

export const getTeamsWithFmpOrders = (
  orders: OrdersResponse,
  teams: WorkgroupData[],
): WorkgroupDataWithFmpOrders[] => {
  return teams.map((team) => {
    const teamUniqueOrderIds = new Set(team.work_orders.map((order) => order.order_id));
    const fmpOrders = orders.EX_HEADERS.filter((order) => teamUniqueOrderIds.has(order.AUFNR));
    const fmpOrdersWithOperations = fmpOrders.map((order) => {
      const operationsOfOrder = orders.EX_OPER_LIST.filter(
        (operation) => operation.AUFNR === order.AUFNR,
      );
      return { ...order, EX_OPER_LIST: operationsOfOrder };
    });
    return {
      ...team,
      fmpOrders: fmpOrdersWithOperations,
    };
  });
};
