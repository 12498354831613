import { httpBackendClient } from '@/shared/api';
import { API_USER_INFORMATION } from '@/shared/lib';

export const getUserInformation = async (request: string[]) => {
  const uniqueNumbers = [...new Set(request)];
  const queryString = uniqueNumbers
    .filter((num) => num)
    .map((num) => `TabNumbers=${num}`)
    .join('&');
  return await httpBackendClient.get(`${API_USER_INFORMATION}?${queryString}`);
};
