import {
  type OrdersHeaders,
  type OrdersHeadersWithOperListAndComponent,
  type OrdersOperList,
  OrderStatus,
} from '../interfaces';

export const completedStatuses = [
  OrderStatus.COMPLETED,
  OrderStatus.CLOSED,
  OrderStatus.CLOSED_WITH_ERRORS,
];

export const checkOrderHasCompletedOperations = (
  order: OrdersHeadersWithOperListAndComponent | undefined,
) => {
  if (!order) return false;
  return order.EX_OPER_LIST?.some((operation) =>
    completedStatuses.includes(operation.STATUS as OrderStatus),
  );
};

export const checkAllOperationsRejectedAfterConfirmSync = (
  order:
    | (OrdersHeaders & {
        EX_OPER_LIST: OrdersOperList[];
      })
    | undefined,
) => {
  if (!order || order.STTXT !== OrderStatus.PENDING) return false;
  return order.EX_OPER_LIST?.every((operation) => {
    if (Boolean(operation.ReasonId) && operation.FlFinalConf) {
      return true;
    }
  });
};
