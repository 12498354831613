import { defineStore } from 'pinia';
import { ref } from 'vue';

import type { Equipment } from '@/entities/Equipments/interfaces';
import type { FormStateMaterial } from '@/entities/Materials';
import type { ExErrorResponse } from '@/shared/interfaces';
import { EXTRA_DELIMITER } from '@/shared/lib';
import { formatDateToUTC } from '@/shared/lib/dateUtils';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';

import { createWorkOrder } from '../api/createWorkOrder';
import type { OrderCreateFormData } from '../interfaces';

export interface OrderCreateFormState {
  name: string;
  text: string;
  brigade?: string;
  materials: FormStateMaterial[];
  hours: number;
  minutes: number;
}

export const defaultOrderCreateData = {
  name: '',
  text: '',
  brigade: undefined,
  materials: [],
  hours: 0,
  minutes: 0,
};

export const useCreateOrderManagementStore = defineStore('createOrderManagement', () => {
  const { setAlert } = useAlertStore();
  const { handleLoading } = useLoadingStore();
  const createOrderValues = ref({ ...defaultOrderCreateData } as OrderCreateFormState);
  const workOrderId = ref<string | undefined>();

  const timeCounting = () => {
    return createOrderValues.value?.hours
      ? (createOrderValues.value?.hours + createOrderValues.value?.minutes / 60).toFixed(2)
      : createOrderValues.value?.minutes.toString();
  };

  const fetchCreateOrderAsync = async (
    currentEquipment: Equipment,
    currentMaterials: FormStateMaterial[],
    people: number,
    successTextResponse: string,
    QMNUM: string,
  ) => {
    const currentDateString = formatDateToUTC(new Date().toString(), 'yyyy-MM-dd');
    const inputData = {
      IT_HEADER: [],
      IT_OPERATION: [],
      IT_COMPONENTS: [],
    } as OrderCreateFormData;

    inputData.IT_HEADER.push([
      '',
      'YM01',
      QMNUM,
      currentEquipment.TPLNR,
      currentEquipment.EQUNR,
      createOrderValues.value.name,
      '',
      currentDateString,
      currentDateString,
      currentEquipment.WERKS,
      currentEquipment.INGRP,
      currentEquipment.ARBPL,
      currentEquipment.WERGW,
      '125',
      '0',
      '1',
    ]);

    inputData.IT_OPERATION.push([
      '',
      '0010',
      currentEquipment.ARBPL,
      currentEquipment.WERGW,
      'PM01',
      createOrderValues.value.name,
      createOrderValues.value.text,
      currentDateString, // ? УТОЧНИТЬ о UTC0
      currentDateString, // ? УТОЧНИТЬ о UTC0
      timeCounting(),
      Number(createOrderValues.value?.hours) ? 'STD' : 'MIN',
      people.toString(),
      currentEquipment.LSTAR,
    ]);

    currentMaterials.forEach((material) => {
      inputData.IT_COMPONENTS.push([
        '0010',
        material.MATNR,
        'L',
        material.MENGE.toString(),
        material.MEINS,
        material.WERKS,
        material.LGORT,
        '',
        '',
        '',
      ]);
    });

    const tryBlock = async () => {
      const createOrderResponse = await createWorkOrder(inputData).catch(() => ({
        //если приложение не подключено к интернету то показываем уведомление об успешной отправке
        status: window.navigator.onLine ? 0 : 200,
        data: {},
        config: {
          url: '',
        },
      }));

      if (createOrderResponse.status !== 200) {
        const errorMessage = [
          `Произошла ошибка в запросе ${createOrderResponse.config?.url ?? 'fetchCreateOrderAsync'}`,
        ];

        if (createOrderResponse.data?.description) {
          errorMessage.push(createOrderResponse.data?.description);
        }
        setAlert({
          type: 'error',
          message: errorMessage.join(EXTRA_DELIMITER),
        });
      } else if (createOrderResponse.data?.ET_ERROR?.length) {
        createOrderResponse.data.ET_ERROR.forEach((error: ExErrorResponse) => {
          setAlert({
            type: error.ERROR_TEXT === successTextResponse ? 'success' : 'error',
            message: error.ERROR_TEXT,
          });
        });
      } else {
        setAlert({ type: 'success', message: successTextResponse });
      }

      workOrderId.value = createOrderResponse.data?.ET_WORKORDER_LIST?.[0].AUFNR;
    };
    await handleLoading({ tryBlock, funcName: 'fetchCreateOrderAsync' });
  };

  const clearCreateOrderValues = () => {
    createOrderValues.value = { ...defaultOrderCreateData };
  };

  return {
    createOrderValues,
    workOrderId,
    clearCreateOrderValues,
    fetchCreateOrderAsync,
  };
});
