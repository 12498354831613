<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { useSync } from '@/app/sync';
import { ReconnectButton } from '@/features/Auth';
import { transparentHeaderPages } from '@/shared/lib';
import { NAV_ROOT } from '@/shared/lib/constants';

interface Props {
  className?: string;
}

const { className } = withDefaults(defineProps<Props>(), { className: '' });
const route = useRoute();
const { sync } = useSync();

const transparent = computed(() =>
  transparentHeaderPages.includes(route?.name?.toString() || route.path),
);
</script>

<template>
  <header
    :class="{ [$style.header]: true, [className]: className, [$style.transparent]: transparent }"
  >
    <div :class="$style.row">
      <ReconnectButton
        v-if="$route.path !== NAV_ROOT"
        :transparent="transparent"
        :on-sync-data="sync"
      />
      <div></div>
    </div>
  </header>
</template>

<style lang="scss" module>
.header {
  background-color: var(--color-primary, #ef7f1a);
  padding: 12px 16px 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: sticky;
  top: 0;
  z-index: 1;
}
.header.transparent {
  background-color: transparent;
  .title {
    color: var(--color-text, #333333);
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span.anticon {
    transform: scale(1.6) !important;
  }

  button {
    color: var(--color-white, #fff);
    font-weight: 600;
  }

  .title {
    color: var(--color-white, #fff);
    font-size: 16px;
    font-weight: 500;
    margin: 0 auto;
  }
}

.buttons {
  display: flex;
  align-items: center;
  gap: 12px;
}
</style>
