import { ref, watchEffect } from 'vue';

import type { OrdersOperList } from '../interfaces/Orders';
import { useGetOrders } from './useGetOrders';

export const useOperationsByTag = (tagId: string) => {
  const { orders } = useGetOrders();
  const operations = ref<OrdersOperList[]>([]);

  watchEffect(() => {
    orders.value?.EX_OPER_LIST.forEach((operation: OrdersOperList) => {
      if (operation.TIDNR === tagId) {
        operations.value.push(operation);
      }
    });
  });

  return { operations };
};
