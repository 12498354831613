import { storeToRefs } from 'pinia';
import { ref, watchEffect } from 'vue';

import { useOrdersHistoryStore } from '../model/useOrdersHistoryStore';
import { useOrdersStore } from '../model/useOrdersStore';

export const useGetOrdersHistory = (orderId: string) => {
  const ordersHistoryStore = useOrdersHistoryStore();
  const { ordersHistory, ordersHistoryFetchError } = storeToRefs(ordersHistoryStore);

  const ordersStore = useOrdersStore();
  const { orders } = storeToRefs(ordersStore);

  const equipmentId = ref('');

  const reloadFetchRequest = () => {
    if (!equipmentId.value) return;
    //история загружается по оборудованию переданного заказа
    const alreadyLoaded = ordersHistory.value?.EX_HEADERS.find(
      (item) => item.EQUNR === equipmentId.value,
    );
    if (alreadyLoaded) return;
    ordersHistoryStore.fetchOrdersHistoryByEquipmentIdsAsync([equipmentId.value]);
  };

  watchEffect(() => {
    if (orders.value?.EX_HEADERS.length) {
      equipmentId.value =
        orders.value?.EX_HEADERS?.find((item) => item.AUFNR === orderId)?.EQUNR || '';
      reloadFetchRequest();
    }
  });

  return {
    ordersHistory,
    equipmentId,
    ordersHistoryFetchError,
    reloadFetchRequest,
  };
};
