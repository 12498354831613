<script setup lang="ts">
import { getCurrentUTC0Date, getDateWithTimeInUserTimeZone } from '@/shared/lib/dateUtils';

import type { EditMeasureItem, GroupedMeasureItems } from '../../interfaces';
import MeasuringPointsHistory from '../MeasuringPointsHistory/MeasuringPointsHistory.vue';
import MeasuringPointsInstruction from '../MeasuringPointsInstruction/MeasuringPointsInstruction.vue';
import styles from './EditMeasureList.module.scss';

interface Props {
  items: GroupedMeasureItems[];
  onEditMeasure: (inputValue: string, POINT: string) => void;
}

const { items, onEditMeasure } = defineProps<Props>();

const getMeasureValue = (recentMeasure: EditMeasureItem) => {
  return {
    date:
      recentMeasure.IDATE && recentMeasure.ITIME
        ? getDateWithTimeInUserTimeZone(
            `${recentMeasure.IDATE} ${recentMeasure.ITIME}`,
            'DD.MM.YYYY',
          )
        : '—',
    value: recentMeasure.CNTRC || recentMeasure.READC || '—',
  };
};
</script>

<template>
  <ul>
    <li v-for="item in items" :key="item.recentMeasure.POINT">
      <div :class="styles.row">
        <div>
          <a-typography-text :class="styles.label">{{
            item.recentMeasure.PTTXT
          }}</a-typography-text>
          <a-typography-text :class="styles.text"
            >{{ getMeasureValue(item.recentMeasure).value }}
            {{ item.recentMeasure.MSEHL }}</a-typography-text
          >
        </div>
        <div :class="styles.date">
          <a-typography-text :class="styles.label">Дата</a-typography-text>
          <a-typography-text :class="styles.text">{{
            getMeasureValue(item.recentMeasure).date
          }}</a-typography-text>
        </div>
        <MeasuringPointsHistory
          :key="item?.measureItems.length"
          :class-name="styles.history"
          :history-list="item?.measureItems"
        />
      </div>
      <div :class="[styles.row, styles.bottom]">
        <div>
          <a-typography-text :class="styles.label"
            >Значение на {{ getCurrentUTC0Date('dd.MM.yyyy') }}</a-typography-text
          >
          <a-input-number
            :class="styles.input"
            @change="
              (value: string) =>
                onEditMeasure(
                  value
                    ? String(value)
                    : ''.trim().replace(new RegExp(`(\\.\\d{${item.DECIM || 0}})\\d+$`), '$1'),
                  item.recentMeasure.POINT.toString(),
                )
            "
            size="large"
            :maxlength="15"
            :min="0"
            :formatter="
              (value: string) =>
                value.trim().replace(new RegExp(`(\\.\\d{${item.DECIM || 0}})\\d+$`), '$1')
            "
          />
        </div>
        <MeasuringPointsInstruction :instruction="item.recentMeasure?.DESCR" />
      </div>
    </li>
  </ul>
</template>
