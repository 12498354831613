<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { onMounted, onUnmounted, ref } from 'vue';

import { ChevronRightFilledIcon, DismissCircleIcon } from '@/shared/assets/svg';
import { differenceInHoursAndMinutes, getTimerValue } from '@/shared/lib';
import { NAV_INSPECTION_DETAIL, NAV_MAINTENANCE_OR_REPAIR } from '@/shared/lib/constants';
import { useUserProfileStore } from '@/shared/model';
import { HorizontalSwipeAble } from '@/shared/ui';

import { type OrdersHeaders, OrderStatus } from '../../interfaces';
import { checkOrderMaintenanceOrRepair } from '../../lib';
import OrderStatusLine from '../OrderStatusLine/OrderStatusLine.vue';
import OrderTimeStatus from '../OrderTimeStatus/OrderTimeStatus.vue';
import styles from './OrderItem.module.scss';

interface Props {
  order: OrdersHeaders;
  isLink?: boolean;
  url?: string;
  minimalView?: boolean;
  size?: 'lg';
  history?: boolean;
  swipeAble?: boolean;
  onReject?: (id: number) => void;
  onReassign?: (id: number) => void;
}
const { order, isLink, url, minimalView, size, history, swipeAble, onReject, onReassign } =
  defineProps<Props>();
const { userIsRepairer } = storeToRefs(useUserProfileStore());

const notAllowedToSeeDetailStatuses = [
  OrderStatus.COMPLETED,
  OrderStatus.CLOSED,
  OrderStatus.CLOSED_WITH_ERRORS,
  OrderStatus.CANCELED,
  userIsRepairer.value ? OrderStatus.PENDING : undefined,
];

const getWrapComponent = (STTXT: OrderStatus) => {
  if (notAllowedToSeeDetailStatuses.includes(STTXT) || !swipeAble) {
    return 'div';
  }
  return HorizontalSwipeAble;
};

let intervalId = 0 as number;
const currentTime = ref('');

onMounted(() => {
  if (order.STTXT === OrderStatus.IN_PROGRESS && order?.timeBegin) {
    intervalId = window.setInterval(() => {
      currentTime.value = order.timeBegin ? getTimerValue(order.timeBegin) : '';
    }, 1000);
  } else {
    window.clearInterval(intervalId);
  }
});

const getOrderUrl = (order: OrdersHeaders) => {
  if (url) {
    return url;
  }
  if (checkOrderMaintenanceOrRepair(order)) {
    return NAV_MAINTENANCE_OR_REPAIR;
  }
  return NAV_INSPECTION_DETAIL;
};

onUnmounted(() => {
  window.clearInterval(intervalId);
});
</script>

<template>
  <li
    :class="{
      [styles.item]: true,
      [styles.canceled]: order.STTXT === OrderStatus.CANCELED,
      [styles[`item-${size}`]]: size,
    }"
  >
    <component
      :on-right-slot-click="onReject"
      :on-left-slot-click="onReassign"
      :is="getWrapComponent(order.STTXT)"
      :item-id="+order.AUFNR"
    >
      <component
        :is="isLink && !notAllowedToSeeDetailStatuses.includes(order.STTXT) ? 'router-link' : 'div'"
        :to="
          isLink && !notAllowedToSeeDetailStatuses.includes(order.STTXT)
            ? `${getOrderUrl(order)}/${order.AUFNR}`
            : undefined
        "
        :class="styles.wrap"
      >
        <div :class="styles.names">
          <a-typography-text :class="styles.subtitle">{{ order.EQKTX }}</a-typography-text>
          <a-typography-text :class="styles.name">{{ order.KTEXT }}</a-typography-text>
        </div>
        <div :class="styles.params">
          <template v-if="!history">
            <a-typography-text
              v-if="
                // order?.time
                true
              "
              :class="styles.time"
            >
              {{
                differenceInHoursAndMinutes(
                  new Date(`${order.GLTRP} ${order.GLUZP}`),
                  new Date(`${order.GSTRP} ${order.GSUZP}`),
                )
              }}
            </a-typography-text>
            <OrderTimeStatus
              v-if="!minimalView"
              :status="order.STTXT"
              :time-spent="
                differenceInHoursAndMinutes(new Date(order.TOTALTIME * 1000), new Date(0))
              "
              :current-time="currentTime"
              :key="currentTime"
            />
          </template>
          <!-- <template v-if="history && order.operatingTime">
            <div :class="styles.operatingTime">
              <a-typography-text :class="styles.subtitle">Наработка</a-typography-text>
              <a-typography-text :class="styles.name">{{ order.operatingTime }}</a-typography-text>
            </div>
          </template> -->
        </div>
        <ChevronRightFilledIcon
          v-if="isLink && !notAllowedToSeeDetailStatuses.includes(order.STTXT)"
          :class="styles.icon"
        />
        <OrderStatusLine :status="order.STTXT" :class="styles.status" />
      </component>
      <template #rightButtonSlot> <DismissCircleIcon />Отклонить</template>
    </component>
  </li>
</template>
