<script setup lang="ts">
import { OrderStatus } from '@/entities/Orders';
import type { OrdersOperList } from '@/entities/Orders/interfaces/Orders';
import { ChevronRightFilledIcon, NfcIcon } from '@/shared/assets/svg';
import { NAV_NFC_ORDER } from '@/shared/lib';
import { PageHeading } from '@/shared/ui';

import styles from './NfcTags.module.scss';

interface Props {
  tags: OrdersOperList[];
  orderId: string;
  tagOperations: OrdersOperList[];
}

const { tags, tagOperations, orderId } = defineProps<Props>();

const currentOperationsByTag = (tagId: string) =>
  tagOperations.filter((operation) => operation.TIDNR === tagId);

const isCompleted = (tagId: string) => {
  const operations = currentOperationsByTag(tagId);

  return operations.every((item) => {
    return item.STATUS.includes(OrderStatus.COMPLETED) || item.localDefects?.length;
  });
};
</script>

<template>
  <PageHeading text="Выбор метки" :go-back="true" />
  <div :class="styles.wrap">
    <ul :class="styles.list">
      <li
        v-for="item in tags"
        :key="item.EQUNR"
        :class="{ [styles.done]: isCompleted(item.TIDNR) }"
      >
        <router-link :class="styles.link" :to="`${NAV_NFC_ORDER}/${orderId}/${item.TIDNR}`">
          <NfcIcon :class="styles.icon" />
          <div :class="styles.body">
            <div :class="styles.row">
              <a-typography-text :class="styles.name">Описание</a-typography-text>
              <a-typography-text :class="styles.text">{{ item.EQKTX }}</a-typography-text>
            </div>
          </div>
          <div :class="styles.count">{{ currentOperationsByTag(item.TIDNR).length }}</div>
          <ChevronRightFilledIcon :class="styles.arrow" />
        </router-link>
      </li>
    </ul>
  </div>
</template>
