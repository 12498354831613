<script setup lang="ts">
import { debounce } from 'throttle-debounce';
import { computed, onMounted, ref, shallowRef, watch } from 'vue';

import { ChevronRightFilledIcon, SearchIcon } from '@/shared/assets/svg';

import { HandleRequest } from '../HandleRequest';
import styles from './Search.module.scss';

interface OptionsType {
  id: string;
  name: string;
}

interface Props {
  placeholder?: string;
  urlName: string;
  items: OptionsType[];
  paramName: string;
  showSearchResult?: boolean;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: (e: FocusEvent) => void;
  onEmptyInput?: () => void;
  debounceTime?: number;
  defaultValue?: string;
  minSearchLength?: number;
}

const props = defineProps<Props>();

const inputValue = ref(props.defaultValue || '');
const itemsToShow = shallowRef<OptionsType[]>([]);

const filteredSearchItems = computed(() => {
  if (!inputValue.value) return [];
  return props.items.filter(
    (item) =>
      item.name.toLowerCase().includes(inputValue.value.toLowerCase()) ||
      item.id.toLowerCase().includes(inputValue.value.toLowerCase()),
  );
});

watch(
  inputValue,
  debounce(props.debounceTime || 0, (newValue) => {
    if (!inputValue.value) {
      return props.onEmptyInput?.();
    }
    if (props.minSearchLength && inputValue.value.length < props.minSearchLength) return;
    if (inputValue.value !== newValue) {
      inputValue.value = newValue;
    }
    itemsToShow.value = filteredSearchItems.value;
    props.onFocus?.();
  }),
);

watch(inputValue, (newValue) => {
  const shouldClearItems = props.minSearchLength
    ? newValue.length < props.minSearchLength
    : !newValue.length;
  if (shouldClearItems) itemsToShow.value = [];
});

const resetForm = () => {
  inputValue.value = '';
  itemsToShow.value = [];
};

onMounted(() => {
  if (inputValue.value) {
    itemsToShow.value = filteredSearchItems.value;
  }
});

defineExpose({ resetForm, inputValue });
</script>

<template>
  <div :class="styles.wrap">
    <a-input
      v-model:value="inputValue"
      :placeholder="placeholder"
      :class="styles.search"
      :disabled="disabled"
      @focus="onFocus"
      @blur="onBlur"
    >
      <template #prefix><SearchIcon :class="styles.icon" /></template>
    </a-input>
  </div>
  <HandleRequest :no-data="!Boolean(itemsToShow.length) && !!showSearchResult && !!inputValue" />
  <ul :class="styles.list" v-if="Boolean(itemsToShow.length) && showSearchResult">
    <li v-for="item in itemsToShow" :key="item.id" :class="styles.item">
      <router-link :class="styles.link" :to="{ name: urlName, params: { [paramName]: item.id } }">
        <div>
          <a-typography-text :class="styles.name">{{ item.name }}</a-typography-text>
        </div>
        <ChevronRightFilledIcon :class="styles.arrow" />
      </router-link>
    </li>
  </ul>
</template>
