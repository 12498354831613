import { defineStore } from 'pinia';
import { ref } from 'vue';

import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';

import { getSymptoms } from '../api/getSymptoms';
import type { SymptomsResponse } from '../interfaces';

export const useSymptomsStore = defineStore('symptoms', () => {
  const symptoms = ref<SymptomsResponse | undefined>(undefined);
  const symptomsFetchError = ref(false);
  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();

  const fetchSymptomsAsync = async (silent = false) => {
    const tryBlock = async () => {
      symptomsFetchError.value = false;
      const response = await getSymptoms();
      if (handleResponse(response)) {
        symptoms.value = response.data as SymptomsResponse;
      } else {
        symptomsFetchError.value = true;
      }
    };
    await handleLoading({ tryBlock, funcName: 'fetchSymptomsAsync', noGlobalLoader: silent });
  };

  return {
    symptoms,
    symptomsFetchError,
    fetchSymptomsAsync,
  };
});
