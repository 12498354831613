import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';

import { useRejectReasonsStore } from '../model/useRejectReasonsStore';

export const useGetRejectReasons = () => {
  const rejectReasonsStore = useRejectReasonsStore();
  const { rejectReasons, rejectReasonsFetchError } = storeToRefs(rejectReasonsStore);

  const reloadFetchRequest = () => {
    rejectReasonsStore.fetchRejectReasonsAsync();
  };

  onMounted(() => {
    if (!rejectReasons.value) reloadFetchRequest();
  });
  return { rejectReasons, rejectReasonsFetchError, reloadFetchRequest };
};
