import type { PageTab } from '@/shared/interfaces';
import { InspectionHistoryWidget } from '@/widgets/InspectionHistoryWidget';
import { InspectionWidget } from '@/widgets/InspectionWidget';
import { OrderDefectsWidget } from '@/widgets/OrderDefectsWidget';

export const tabs: PageTab[] = [
  {
    name: 'Осмотр',
    component: InspectionWidget,
    id: 1,
  },
  {
    name: 'История',
    component: InspectionHistoryWidget,
    id: 2,
  },
  {
    name: 'Дефекты',
    component: OrderDefectsWidget,
    id: 3,
  },
];
