<script setup lang="ts">
import { storeToRefs } from 'pinia';
import type { UnwrapRef } from 'vue';
import { computed, reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import {
  DEFAULT_IM_WERKS,
  NAV_EQUIPMENT_LINES,
  NAV_EQUIPMENT_LINES_CREATE_DEFECT,
  NAV_EQUIPMENT_SEARCH_NO_NFC,
} from '@/shared/lib/constants';
import { useUserProfileStore } from '@/shared/model';
import { BottomButtons } from '@/shared/ui';

import type { FucLockByParentNode } from '../../interfaces';
import styles from './EquipmentSearchFilter.module.scss';

interface Props {
  funcLocByParentNode: FucLockByParentNode;
}

const { funcLocByParentNode } = defineProps<Props>();
const router = useRouter();
const route = useRoute();
const userProfileStore = useUserProfileStore();
const { profile } = storeToRefs(userProfileStore);

const userWerk = profile.value?.enterprise_sap_code ?? DEFAULT_IM_WERKS;
const workshops = funcLocByParentNode[userWerk] || []; // Цехом являются дочерние ноды для завода пользователя

interface FormState {
  workshop: number | undefined;
  area: number | undefined;
}

const formState: UnwrapRef<FormState> = reactive({
  workshop: undefined,
  area: undefined,
});

const submitDisabled = computed(
  () => formState.workshop === undefined || formState.area === undefined,
);

const onFinish = (values: FormState) => {
  router.push({
    name:
      route.path === NAV_EQUIPMENT_SEARCH_NO_NFC
        ? NAV_EQUIPMENT_LINES_CREATE_DEFECT
        : NAV_EQUIPMENT_LINES,
    params: { workshopId: values.workshop, areaId: values.area },
  });
};

watch(
  () => formState.workshop,
  () => {
    formState.area = undefined;
  },
);
</script>

<template>
  <a-form
    name="equipmentSearch"
    :model="formState"
    layout="vertical"
    @finish="onFinish"
    :required-mark="false"
    :class="styles.form"
  >
    <div :class="styles.wrap">
      <a-form-item :name="['workshop']" label="Цех" :class="styles.item">
        <a-radio-group
          v-model:value="formState.workshop"
          button-style="solid"
          :class="styles.radioGroup"
        >
          <a-radio-button
            v-for="workShop in workshops"
            :key="workShop.hhive_id"
            :value="workShop.TPLNR"
            >{{ workShop.PLTXT }}</a-radio-button
          >
        </a-radio-group>
      </a-form-item>
      <a-form-item v-if="formState.workshop" :name="['area']" label="Участок" :class="styles.item">
        <a-radio-group
          v-model:value="formState.area"
          button-style="solid"
          :class="styles.radioGroup"
        >
          <!-- Участками являются дочерние ноды для цеха выбранного пользователем -->
          <a-radio-button
            v-for="area in funcLocByParentNode[formState.workshop] || []"
            :key="area.hhive_id"
            :value="area.TPLNR"
            >{{ area.PLTXT }}</a-radio-button
          >
        </a-radio-group>
      </a-form-item>
    </div>
    <BottomButtons>
      <a-button type="primary" html-type="submit" :disabled="submitDisabled" size="large"
        >Далее</a-button
      ></BottomButtons
    >
  </a-form>
</template>
