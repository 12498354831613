<script setup lang="ts">
import { useRoute } from 'vue-router';

import { DefectsList, useGetDefectsListByStatus } from '@/entities/Defects';
import { HandleRequest } from '@/shared/ui';

const { sectorId } = useRoute().params;
const { completedDefects, filesList, defectsFetchError, reloadFetchRequest } =
  useGetDefectsListByStatus({
    IM_WERKS: '',
    IM_TPLNR: sectorId as string,
    IM_EQUNR: [],
    IM_EQUNR_HISTORY: [],
    IM_QMNUM: '',
  });
</script>

<template>
  <HandleRequest
    :no-data="!completedDefects.length"
    :error-request="defectsFetchError"
    :on-repeat="reloadFetchRequest"
  />
  <DefectsList
    v-if="completedDefects.length"
    :items="completedDefects"
    :files-list="filesList"
    :is-link="true"
  />
</template>
