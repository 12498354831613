import { EquipmentStatuses } from '@/shared/interfaces';

import { type OrdersOperList } from '../interfaces/Orders';

export const checkCanCreateDefectForOperation = (operation: OrdersOperList) => {
  return !(
    !operation.EQUNR ||
    !operation.TPLNR ||
    !operation.STTXU.includes(EquipmentStatuses.DEFECTS_LEVEL)
  );
};
