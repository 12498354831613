<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import {
  OperationDescription,
  type OrdersHeaders,
  OrderStatus,
  RejectReason,
  useGetOrders,
  useOrdersStore,
} from '@/entities/Orders';
import { useOperationInfo } from '@/entities/Orders';
import { checkOrderIsInspection, checkOrderMaintenanceOrRepair } from '@/entities/Orders/lib';
import { RejectionReasons } from '@/features/RejectionReasons';
import { useUserProfileStore } from '@/shared/model';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { BottomButtons, PageHeading } from '@/shared/ui';

import styles from './OperationDetail.module.scss';

const currentOrder = ref<OrdersHeaders | undefined>();
const openReasons = ref<boolean>(false);
const handleModalReasons = (value: boolean) => {
  openReasons.value = value;
};

const route = useRoute();
const router = useRouter();
const { orderId, operationId } = route.params;
const { operation } = useOperationInfo(orderId as string, operationId as string);
const { userIsRepairer, userIsSupervisor } = storeToRefs(useUserProfileStore());
const { orders } = useGetOrders();
const { setAlert } = useAlertStore();
const { updateLocalOperationStatusAndDefects } = useOrdersStore();

const handleAfterSubmit = () => {
  router.back();
  setAlert({ type: 'error', message: 'Операция отклонена' });
};

const handleOperationFinish = () => {
  updateLocalOperationStatusAndDefects({
    orderId: orderId as string,
    operationId: operationId as string,
    localStatus: OrderStatus.COMPLETED,
    localChecked: true,
  });

  router.back();
  setAlert({ type: 'success', message: 'Операция завершена' });
};

watchEffect(() => {
  currentOrder.value = orders.value?.EX_HEADERS?.find((item) => item.AUFNR === orderId);
});

const isStatusInProgress = currentOrder.value?.STTXT === OrderStatus.IN_PROGRESS;
</script>

<template>
  <div :class="styles.page">
    <PageHeading v-if="operation" :text="operation?.LTXA1" :go-back="true" />
    <!-- Todo когда доработают бек -->
    <!-- <SliderMedia
      v-if="operation?.images"
      :className="styles.images"
      :images="operation?.images"
      :miniSlider="true"
    /> -->
    <OperationDescription v-if="operation" :description="operation['DESCR']" />
    <RejectReason v-if="userIsSupervisor && operation?.ReasonId" :reason-id="operation?.ReasonId" />
    <!-- Todo когда доработают бек -->
    <!-- <div :class="styles.wrap">
      <DocumentsList :showTitle="false" />
    </div> -->
  </div>
  <BottomButtons
    v-if="
      userIsRepairer &&
      operation?.STATUS !== OrderStatus.COMPLETED &&
      isStatusInProgress &&
      currentOrder &&
      !checkOrderIsInspection(currentOrder) &&
      !!currentOrder.STTXT
    "
  >
    <a-button size="large" :class="styles.button" @click="handleModalReasons(true)"
      >Отклонить опер.</a-button
    >
    <a-button type="primary" size="large" :class="styles.button" @click="handleOperationFinish"
      >Завершить</a-button
    >
  </BottomButtons>
  <BottomButtons
    v-if="
      userIsSupervisor &&
      currentOrder &&
      checkOrderMaintenanceOrRepair(currentOrder) &&
      !operation?.localChecked &&
      !!currentOrder.STTXT
    "
  >
    <a-button size="large" :class="styles.button" @click="handleModalReasons(true)"
      >Отклонить опер.</a-button
    >
    <a-button type="primary" size="large" :class="styles.button" @click="handleOperationFinish"
      >Завершить</a-button
    >
  </BottomButtons>
  <RejectionReasons
    v-if="openReasons"
    :on-close="() => handleModalReasons(false)"
    :open="openReasons"
    :on-after-submit="handleAfterSubmit"
    :operation-id="operationId as string"
    :order-id="orderId as string"
  />
</template>
