import { storeToRefs } from 'pinia';
import { ref, watchEffect } from 'vue';

import { EquipmentStatuses } from '@/shared/interfaces';
import { DEFAULT_IM_WERKS, enterprises } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { useSectorsStore } from '@/shared/model/useSectorsStore';

import type { FucLockByParentNode } from '../interfaces';
import type { TechPlaceItem } from '../interfaces/TechPlaceItem';
import { useGetEquipList } from './useGetEquipList';
import { useGetFuncLocList } from './useGetFuncLocList';

export const useGetFuncLocksWerksAndAreas = () => {
  const { funcLockList, funcLockFetchError, reloadFetchRequest } = useGetFuncLocList();
  const { profile } = storeToRefs(useUserProfileStore());
  const { sectors } = storeToRefs(useSectorsStore());
  const { equipmentList } = useGetEquipList();

  const funcLocByParentNode = ref<FucLockByParentNode | undefined>(undefined);

  watchEffect(() => {
    const enterpriseSapCode = profile.value?.enterprise_sap_code ?? DEFAULT_IM_WERKS;
    if (funcLockList.value) {
      if (enterpriseSapCode === enterprises.EEK || enterpriseSapCode === enterprises.ODPP) {
        function initializeFuncLocByParentNode() {
          if (!funcLocByParentNode.value) {
            funcLocByParentNode.value = {};
          }
        }

        function ensureArrayExists(key: string) {
          if (!funcLocByParentNode.value![key]) {
            funcLocByParentNode.value![key] = [];
          }
        }

        function addItemToFuncLoc(werksKey: string, item: TechPlaceItem, key: number) {
          if (!funcLocByParentNode.value![werksKey].some((el) => el.TPLNR === item.WORK_CODE)) {
            funcLocByParentNode.value![werksKey].push({
              TPLNR: item.WORK_CODE!,
              WERKS: item.WERKS!,
              PLTXT: item.WORK_NAME!,
              TPLMA: item.WERKS!,
              hhive_id: key + Math.random(),
            });
          }
        }

        function addWorkshopItem(workCodeKey: string, item: TechPlaceItem, key: number) {
          if (!funcLocByParentNode.value![workCodeKey].some((el) => el.TPLNR === item.TPLNR)) {
            funcLocByParentNode.value![workCodeKey].push({
              TPLNR: item.TPLNR!,
              WERKS: item.WERKS!,
              PLTXT: item.PLTXT!,
              TPLMA: item.WORK_CODE!,
              hhive_id: key + 10,
            });
          }
        }

        sectors.value?.EX_TECHPLACE.forEach((item, key) => {
          initializeFuncLocByParentNode();

          if (!item.WERKS || !item.WORK_CODE) return;

          // Убедиться, что массивы существуют
          ensureArrayExists(item.WERKS);
          ensureArrayExists(item.WORK_CODE);

          // Добавление объектов
          addItemToFuncLoc(item.WERKS, item, key);
          addWorkshopItem(item.WORK_CODE, item, key);
        });

        function isValidFormat(value: string): boolean {
          const pattern = /^\d{4}-\d{3}-\d{3}$/;
          return pattern.test(value);
        }

        funcLockList.value?.EX_FUNC_LOC.forEach((technicalPlaces) => {
          initializeFuncLocByParentNode();

          Object.keys(funcLocByParentNode.value!).forEach((key) => {
            funcLocByParentNode.value![key].forEach((value) => {
              const isMatch =
                value.TPLNR === technicalPlaces.TPLNR ||
                value.TPLMA === technicalPlaces.TPLNR ||
                value.WERKS === technicalPlaces.TPLNR;

              if (!isMatch && value.TPLNR === technicalPlaces.TPLNR.slice(0, 12)) {
                ensureArrayExists(technicalPlaces.TPLMA);
                funcLocByParentNode.value![technicalPlaces.TPLMA].push(technicalPlaces);
              } else if (
                value.TPLNR === technicalPlaces.TPLNR &&
                isValidFormat(value.TPLNR) &&
                !funcLockList.value?.EX_FUNC_LOC.some((tm) => tm.TPLMA === value.TPLNR)
              ) {
                ensureArrayExists(technicalPlaces.TPLNR);
                funcLocByParentNode.value![value.TPLNR].push({
                  TPLNR: value.TPLNR,
                  WERKS: value.WERKS,
                  PLTXT: value.PLTXT,
                  TPLMA: value.TPLNR,
                  hhive_id: value.hhive_id,
                });
              }
            });
          });
        });

        if (equipmentList.value && enterpriseSapCode === enterprises.ODPP) {
          equipmentList.value.EX_EQUIP_LIST.forEach((equipment) => {
            Object.keys(funcLocByParentNode.value!).forEach((key) => {
              // в заводе ФООР 1016, есть такое понятие как группирующая ЕО которую использовали как ТМ для объединения ЕО со статусами УПЛН. У этой ЕО статус УПРС. В данной обработке мы эти ЕО ставим рядом с ТМ в нашем объекте funcLocByParentNode

              if (
                key === equipment.TPLNR &&
                !equipment.HEQUI &&
                equipment.STTXU === EquipmentStatuses.IDLE_LEVEL
              ) {
                funcLocByParentNode.value![key].push({
                  TPLNR: equipment.EQUNR,
                  WERKS: equipment.WERKS,
                  PLTXT: equipment.EQKTX,
                  TPLMA: equipment.TPLNR,
                  hhive_id: equipment.hhive_id,
                  ifGroupingEquipment: equipment,
                });
              }
            });
          });
        }
      } else {
        funcLockList.value.EX_FUNC_LOC?.forEach((item) => {
          if (!funcLocByParentNode.value) {
            funcLocByParentNode.value = {};
          }
          if (!funcLocByParentNode.value[item.TPLMA]) {
            funcLocByParentNode.value[item.TPLMA] = [];
          }
          funcLocByParentNode.value[item.TPLMA].push(item);
        });
      }
    }
  });
  return {
    funcLocByParentNode,
    funcLockFetchError,
    reloadFetchRequest,
  };
};
