<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import {
  EquipmentLinesList,
  useGetEquipmentNodesByParentLines,
  useGetFuncLocksWerksAndAreas,
} from '@/entities/Equipments';
import {
  NAV_DEFECT_CREATE_FROM_EQUIPMENT_TO_DETAIL,
  NAV_EQUIPMENT_DETAIL,
  NAV_EQUIPMENT_LINES_CREATE_DEFECT,
} from '@/shared/lib';
import { HandleRequest, PageHeading, Search } from '@/shared/ui';

import styles from './EquipmentLines.module.scss';

const router = useRouter();
const { workshopId, areaId } = useRoute().params;

const {
  funcLocByParentNode,
  funcLockFetchError,
  reloadFetchRequest: reloadFuncLocks,
} = useGetFuncLocksWerksAndAreas();

const {
  equipmentList,
  equipmentSearchValue,
  equipmentNodesByParentLines,
  equipmentListFetchError,
  reloadFetchRequest: reloadEquipmentList,
} = useGetEquipmentNodesByParentLines();

const areaName = computed(
  () =>
    funcLocByParentNode?.value?.[workshopId as string]?.find((area) => area.TPLNR === areaId)
      ?.PLTXT,
);

const showEquipmentList = ref<boolean>(false);
const showSearchResult = ref<boolean>(true);
const clearInputValue = ref();

const onFocus = () => {
  showEquipmentList.value = true;
  showSearchResult.value = true;
};

const onBlur = () => {
  equipmentSearchValue.value = clearInputValue.value?.inputValue ?? '';
};

const onReload = () => {
  if (funcLockFetchError) {
    reloadFuncLocks();
  }

  if (equipmentListFetchError) {
    reloadEquipmentList();
  }
};

const equipmentOptions = computed(() =>
  equipmentList.value?.EX_EQUIP_LIST?.map((equipment) => ({
    id: equipment.EQUNR,
    name: equipment.EQKTX,
  })),
);

const handleBack = () => {
  if (showEquipmentList.value) {
    showEquipmentList.value = false;
    showSearchResult.value = false;
    clearInputValue.value.resetForm();
  } else {
    router.back();
  }
  equipmentSearchValue.value = '';
};

const onEmptyInput = () => handleBack();
</script>

<template>
  <div :class="styles.defect">
    <PageHeading :text="areaName || ''" :go-back="true" :on-handle-back="handleBack" />
    <HandleRequest
      :no-data="!funcLocByParentNode || !equipmentNodesByParentLines"
      :error-request="funcLockFetchError || equipmentListFetchError"
      :on-repeat="onReload"
    />
    <template v-if="funcLocByParentNode && equipmentNodesByParentLines && !funcLockFetchError">
      <Search
        v-if="equipmentOptions"
        ref="clearInputValue"
        placeholder="Поиск"
        :url-name="
          $route.name === NAV_EQUIPMENT_LINES_CREATE_DEFECT
            ? NAV_DEFECT_CREATE_FROM_EQUIPMENT_TO_DETAIL
            : NAV_EQUIPMENT_DETAIL
        "
        :items="equipmentOptions"
        param-name="equipmentId"
        :on-focus="onFocus"
        :on-blur="onBlur"
        :on-empty-input="onEmptyInput"
        :default-value="equipmentSearchValue"
        :debounce-time="500"
        :min-search-length="3"
        :show-search-result="showSearchResult"
      />
      <div v-show="!showEquipmentList" :class="styles.body">
        <EquipmentLinesList
          v-if="funcLocByParentNode && equipmentNodesByParentLines"
          :area-id="areaId as string"
          :func-loc-by-parent-node="funcLocByParentNode"
          :equipment-nodes-by-parent-lines="equipmentNodesByParentLines"
        />
      </div>
    </template>
  </div>
</template>
