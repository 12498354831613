<script setup lang="ts">
import { computed, ref, useSlots } from 'vue';

import { ArrowBackIcon, People } from '@/shared/assets/svg';

import styles from './UsersGroup.module.scss';

interface UsersGroupProps {
  title?: string;
  usersCount?: { current?: number; total?: number };
  hoursCount?: { current?: number; total?: number };
}

const { title, usersCount, hoursCount } = defineProps<UsersGroupProps>();

const activeKey = ref<string[]>([]);
const slots = useSlots();

const hoursExceeded = computed(
  () => !!hoursCount?.total && !!hoursCount?.current && hoursCount.total > hoursCount.current,
);
</script>

<template>
  <a-collapse v-model:activeKey="activeKey" :bordered="false" :class="styles.collapse">
    <div :class="styles.title">{{ title }}</div>
    <slot />
    <div :class="{ [styles.footer]: true, [styles.footerHidden]: activeKey.length !== 0 }">
      <div>
        <People />
        <span :class="styles.weak">{{ usersCount?.current }}/</span>{{ usersCount?.total }}
      </div>
      <div>
        <span :class="styles.weak">{{ hoursCount?.current }}/</span>
        <span :class="{ [styles.warn]: hoursExceeded }">{{ hoursCount?.total }} ч</span>
      </div>
    </div>
    <a-collapse-panel v-if="slots.more" key="1">
      <slot name="more" />
    </a-collapse-panel>
    <template #expandIcon="{ isActive }">
      <ArrowBackIcon :class="{ [styles.icon]: true, [styles.rotate]: isActive }" />
    </template>
  </a-collapse>
</template>
