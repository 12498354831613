<script setup lang="ts">
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { NfcScan } from '@/entities/Nfc';

const route = useRoute();
const routeName = ref(route.name as string);

watch(
  () => route.name,
  (current) => {
    routeName.value = current as string;
  },
);
</script>

<template>
  <NfcScan :key="routeName" />
</template>
