<script setup lang="ts">
import { onClickOutside, useSwipe } from '@vueuse/core';
import { computed, onMounted, ref, useSlots } from 'vue';

import { DismissCircleIcon, PeopleSwapRegularIcon } from '@/shared/assets/svg';

import styles from './HorizontalSwipeAble.module.scss';

interface Props {
  itemId: number | string;
  onRightSlotClick?: (id: number | string) => void;
  onLeftSlotClick?: (id: number | string) => void;
  textDismiss?: string;
}

const { itemId, onRightSlotClick, onLeftSlotClick } = defineProps<Props>();

const target = ref<HTMLElement | null>(null);
const container = ref<HTMLElement | null>(null);
const right = ref('0');
const pointerEvents = ref<'auto' | 'none'>('auto');
const containerWidth = computed(() => container.value?.offsetWidth);
const containerBg = ref('transparent');

const restore = () => {
  right.value = '0';
  containerBg.value = 'transparent';
  pointerEvents.value = 'auto';
};

onMounted(() => {
  window.addEventListener('scroll', restore);
  return () => {
    window.removeEventListener('scroll', restore);
  };
});

onClickOutside(target, () => {
  restore();
});

const hasActionOnTheRight = !!onRightSlotClick;
const hasActionOnTheLeft = !!onLeftSlotClick;

const { isSwiping, lengthX } = useSwipe(target, {
  passive: true,
  threshold: 0,
  onSwipe() {
    if (containerWidth.value) {
      if (lengthX.value > 0) {
        // Свайп в левую сторону
        if (!hasActionOnTheRight) return;
        const length = Math.abs(lengthX.value);
        right.value = `${length}px`;
        containerBg.value = '#ff3141';
      } else if (lengthX.value < 0) {
        // Свайп в правую сторону
        if (!hasActionOnTheLeft) return;
        const length = Math.abs(lengthX.value);
        right.value = `-${length}px`;
        containerBg.value = '#08979C';
      } else {
        restore();
      }
    }
  },
  onSwipeEnd() {
    if (
      lengthX.value > 0 &&
      containerWidth.value &&
      Math.abs(lengthX.value) / containerWidth.value >= 0.2
    ) {
      // Свайп в левую сторону
      if (!hasActionOnTheRight) return;
      right.value = '20%';
      containerBg.value = '#ff3141';
      pointerEvents.value = 'none';
    } else if (
      lengthX.value < 0 &&
      containerWidth.value &&
      Math.abs(lengthX.value) / containerWidth.value >= 0.2
    ) {
      // Свайп в правую сторону
      if (!hasActionOnTheLeft) return;
      right.value = '-28%';
      containerBg.value = '#08979C';
      pointerEvents.value = 'none';
    } else {
      restore();
    }
  },
});

const slots = useSlots();
</script>

<template>
  <div :class="styles.item" ref="container" :style="{ background: containerBg }">
    <button
      v-if="onLeftSlotClick"
      :class="styles.leftSlot"
      @click="() => onLeftSlotClick?.(itemId)"
    >
      <slot name="leftButtonSlot" v-if="slots.leftButtonSlot"></slot>
      <template v-else>
        <PeopleSwapRegularIcon />
        Переназначить
      </template>
    </button>
    <button
      v-if="onRightSlotClick"
      @click="() => onRightSlotClick?.(itemId)"
      :class="styles.rightSlot"
    >
      <slot name="rightButtonSlot" v-if="slots.rightButtonSlot"></slot>
      <template v-else>
        <DismissCircleIcon />
        <a-typography-text :class="styles.textDismiss">{{
          textDismiss ?? 'Удалить'
        }}</a-typography-text>
      </template>
    </button>
    <div
      :class="{ [styles.overlay]: true, [styles.animated]: !isSwiping }"
      :style="{ right }"
      ref="target"
    >
      <div :style="{ pointerEvents }">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
