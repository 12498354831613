<script setup lang="ts">
import { defectPriorities } from '../../lib';
import styles from './PriorityIndicator.module.scss';

interface Props {
  priorityId: string;
  className?: string;
}

const { priorityId, className } = defineProps<Props>();
const bgcColor = defectPriorities.find((p) => p.id === priorityId)?.color;
</script>

<template>
  <div :class="`${styles.priority} ${className}`" :style="{ backgroundColor: bgcColor }">
    {{ priorityId }}
  </div>
</template>
