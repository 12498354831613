import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';

import { useStorage } from '@/shared/hooks';
import { useUserProfileStore } from '@/shared/model';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';

import { getOrders } from '../api/getOrders';
import {
  type OrderConfirmationsResponse,
  OrderConfirmationStatuses,
  type OrdersResponse,
} from '../interfaces';
import { type LocalRejection, OrderStatus, type OrdersVps } from '../interfaces/Orders';
import { clearOrdersWithIncorrectType } from '../lib';
import { confirmationStatusesMap } from '../lib/ordersMapUtils';
import { getLocalOperations, getLocalOrders } from '../lib/ordersStorage';

export const useOrdersStore = defineStore('orders', () => {
  const orders = ref<OrdersResponse | undefined>(undefined);
  const ordersFetchError = ref(false);
  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();
  const { setItem } = useStorage();
  const { userIsRepairer, userIsSupervisor, userIsRepairSupervisor } =
    storeToRefs(useUserProfileStore());

  const fetchOrdersAsync = async (data: string[][], initial = false) => {
    const tryBlock = async () => {
      ordersFetchError.value = false;
      const sendHistory = !userIsRepairer.value && !userIsRepairSupervisor.value;
      const response = await getOrders(data, sendHistory); // Для ремонтника и супервайзера передаем пустой флаг а для технолога и сотрудника актива "X"
      if (handleResponse(response)) {
        const clearedOrders = clearOrdersWithIncorrectType(response.data as OrdersResponse);
        orders.value = clearedOrders;

        // Если имеются несохраненные заказы в хранилище, то подставляем их данные вместо пришедших с сервера
        const localOrders = getLocalOrders();
        for (const localOrder of localOrders) {
          const index = orders.value.EX_HEADERS.findIndex(
            (order) => order.AUFNR === localOrder.AUFNR,
          );
          if (index !== -1) orders.value.EX_HEADERS[index] = localOrder;
        }

        // Если имеются несохраненные операции заказов в хранилище, то подставляем их данные вместо пришедших с сервера
        const localOperations = getLocalOperations();
        for (const localOperation of localOperations) {
          const index = orders.value.EX_OPER_LIST.findIndex(
            (operation) => operation.AUFNR === localOperation.AUFNR,
          );
          if (index !== -1) orders.value.EX_OPER_LIST[index] = localOperation;
        }
      } else {
        ordersFetchError.value = true;
      }
    };
    await handleLoading({ tryBlock, funcName: 'fetchOrdersAsync', initialData: initial });
  };

  const updateLocalOrderStatus = (orderId: string, status: OrderStatus, timeDuration?: number) => {
    if (!orders.value) return;
    const index = orders.value.EX_HEADERS.findIndex((order) => order.AUFNR === orderId);
    if (index !== -1) {
      const order = orders.value.EX_HEADERS[index];
      const currentTime = new Date().getTime();
      const orderStatusesForStatusId = [OrderStatus.COMPLETED, OrderStatus.CANCELED];
      const getOrderStatusId = () => {
        //обновление StatusId из OrderConfirmationsResponse для супервайзера
        if (status === OrderStatus.COMPLETED) {
          return OrderConfirmationStatuses.CONFIRMED;
        } else if (status === OrderStatus.CANCELED) {
          return OrderConfirmationStatuses.CANCELED;
        }
      };

      const updatedOrder = {
        ...order,
        STTXT: status,
        ...(orderStatusesForStatusId.includes(status) ? { StatusId: getOrderStatusId() } : {}),
        ...(status === OrderStatus.IN_PROGRESS ? { timeBegin: currentTime } : {}),
        ...(status === OrderStatus.COMPLETED && order?.timeBegin
          ? { TOTALTIME: Math.trunc((currentTime - order.timeBegin) / 1000) }
          : {}),
        ...(timeDuration !== undefined && userIsSupervisor.value ? { timeDuration } : {}),
      };
      orders.value.EX_HEADERS[index] = updatedOrder;

      // Запоминаем заказ на случай частичного изменения заказа без отправки на сервер
      setItem(`order_${orderId}`, JSON.stringify(updatedOrder));
    }
  };

  const updateLocalOperationStatusAndDefects = ({
    orderId,
    operationId,
    localStatus,
    localDefects,
    localCancel,
    localRejection,
    localChecked,
  }: {
    orderId: string;
    operationId: string;
    localStatus?: OrderStatus;
    localDefects?: string[];
    localCancel?: boolean;
    localRejection?: LocalRejection;
    localChecked?: boolean;
  }) => {
    if (!orders.value) return;
    const index = orders.value.EX_OPER_LIST.findIndex(
      (operation) => operation.AUFNR === orderId && operation.VORNR === operationId,
    );
    if (index !== -1) {
      const operation = orders.value.EX_OPER_LIST[index];
      const defects = operation.localDefects || [];

      const updatedOperation = {
        ...operation,
        ...(localStatus ? { STATUS: localStatus } : {}),
        ...(localDefects
          ? {
              localDefects: [...defects, ...localDefects],
            }
          : {}),
        ...(localCancel ? { CANCEL: 'X' } : {}),
        ...(localRejection ? { localRejection } : {}),
        ...(localChecked ? { localChecked } : {}),
      };
      orders.value.EX_OPER_LIST[index] = updatedOperation;

      // Запоминаем операцию на случай частичного изменения пользователем без отправки на сервер
      setItem(`operation_${orderId}_${operationId}`, JSON.stringify(updatedOperation));
    }
  };

  const mapConfirmationDataWithOrderFmData = (confirmationData: OrderConfirmationsResponse[]) => {
    if (!orders.value) return;
    orders.value.EX_HEADERS.forEach((fmOrder) => {
      const confirmationOrder = confirmationData?.find(
        (order) => order.OrderNumber === fmOrder.AUFNR,
      );
      if (!confirmationOrder) return;
      // при мапе данных затираем статусы пришедшего из фм заказа теми статусами что пришли из бэкенда python
      fmOrder.STTXT =
        confirmationStatusesMap[confirmationOrder.StatusId as keyof typeof confirmationStatusesMap];
      // fmOrder.StatusId = confirmationOrder?.StatusId;
      if (orders?.value?.EX_OPER_LIST) {
        orders.value.EX_OPER_LIST.map((operation) => {
          const operationFromConfirmationData = confirmationOrder?.Operations?.find(
            (confirmationOperation) => confirmationOperation.OperationNumber === operation.VORNR,
          );
          if (operationFromConfirmationData && operation.AUFNR === fmOrder.AUFNR) {
            operation.STATUS = operationFromConfirmationData.StatusTextOperation;
            operation.FlFinalConf = operationFromConfirmationData.FlFinalConf;
            if (operationFromConfirmationData.ReasonId) {
              operation.ReasonId = operationFromConfirmationData.ReasonId;
            }
            orders.value?.EX_COMPONENT?.map((component) => {
              if (component.VORNR === operationFromConfirmationData.OperationNumber) {
                const componentFromConfirmationData = operationFromConfirmationData.Materials?.find(
                  (confirmationMaterial) =>
                    +confirmationMaterial.MaterialNumber === parseInt(component.MATNR) &&
                    confirmationMaterial.MaterialPosnr === parseInt(component.POSNR),
                );
                if (componentFromConfirmationData) {
                  component.MAX = component.MENGE;
                  component.MENGE = componentFromConfirmationData.MaterialQuantity;
                }
              }
            });
          }
        });
      }
    });
  };

  const addLocalVps = (newVps: OrdersVps[]) => {
    if (orders.value?.EX_VPS) {
      orders.value.EX_VPS = [...orders.value.EX_VPS, ...newVps];
    }
  };

  return {
    orders,
    ordersFetchError,
    fetchOrdersAsync,
    updateLocalOrderStatus,
    updateLocalOperationStatusAndDefects,
    mapConfirmationDataWithOrderFmData,
    addLocalVps,
  };
});
