<script setup lang="ts">
import { computed } from 'vue';

import { declOfNum } from '@/shared/lib';

import { useGetInfoWidgetOrderData } from '../../hooks/useGetInfoWidgetOrderData';
import styles from './InspectionsProgressPanel.module.scss';

const { inspectionsProgressInfo } = useGetInfoWidgetOrderData();
const percentProgress = computed(() => {
  return inspectionsProgressInfo.value.totalOperations
    ? Math.round(
        (inspectionsProgressInfo.value.completedOperations /
          inspectionsProgressInfo.value.totalOperations) *
          100,
      )
    : 0;
});
</script>

<template>
  <div :class="styles.panel">
    <a-typography-text :class="styles.text">Осмотры</a-typography-text>
    <div :class="styles.wrap">
      <a-typography-title :level="3" style="margin: 0" :class="styles.title">{{
        inspectionsProgressInfo.total
      }}</a-typography-title>
      <a-typography-text :class="styles.progress_text"
        >Выполнено на {{ percentProgress }}%</a-typography-text
      >
    </div>
    <a-progress
      :show-info="false"
      :stroke-linecap="'square'"
      :stroke-color="'#FF7A45'"
      :trail-color="'#E6E6E6'"
      :percent="percentProgress"
      :class="styles.progress"
    />
    <a-typography-text :class="styles.count"
      >{{ inspectionsProgressInfo.totalOperations }}
      {{ declOfNum(inspectionsProgressInfo.totalOperations, ['операция', 'операции', 'операции']) }}
      осмотра</a-typography-text
    >
  </div>
</template>
