import type { EquipmentMeasure } from '../interfaces/Equipment';

export const getMeasurePointsByEQUNRs = (
  measureList: EquipmentMeasure[] | undefined,
  EQUNRs: string[],
): EquipmentMeasure[] => {
  if (!measureList) {
    return [];
  }
  return measureList.filter((item) => EQUNRs.includes(item.EQUNR));
};
