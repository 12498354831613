<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { DefectsList, useGetDefectsList } from '@/entities/Defects';
import { useGetEquipmentInfo } from '@/entities/Equipments';
import { useGetEquipmentByParentNodes } from '@/entities/Equipments/hooks/useGetEquipmentByParentNodes';
import { getEquipmentChildAndChildrenChildEQUNRs } from '@/entities/Equipments/lib';
import { HandleRequest } from '@/shared/ui';

const { equipmentId } = useRoute().params;
const { equipmentInfo } = useGetEquipmentInfo(equipmentId as string);
const { equipmentsNodesByParentNodes } = useGetEquipmentByParentNodes();

const idsToSend = computed(() => {
  if (equipmentInfo.value?.isNode) {
    // Если оборудование является узлом то отправляем только его EQUNR
    return [[equipmentId as string]];
  } else {
    //Для получения дефектов нужно получить EQUNR дочерних узлов и дочерних дочерних узлов рекурсивно
    return [
      //Так же нужно добавить EQUNR самого оборудования
      [equipmentId as string],
      ...getEquipmentChildAndChildrenChildEQUNRs(
        equipmentsNodesByParentNodes.value,
        equipmentId as string,
      ),
    ];
  }
});

const { defectsList, filesList, defectsFetchError, reloadFetchRequest } = useGetDefectsList({
  IM_WERKS: '',
  IM_TPLNR: '',
  IM_EQUNR: idsToSend.value,
  IM_EQUNR_HISTORY: [],
  IM_QMNUM: '',
});
</script>

<template>
  <HandleRequest
    :no-data="!defectsList?.EX_DEFECT_LIST.length"
    :error-request="defectsFetchError"
    :on-repeat="reloadFetchRequest"
  />
  <DefectsList
    v-if="defectsList"
    :items="defectsList?.EX_DEFECT_LIST"
    :files-list="filesList"
    :auto-load-files="true"
    :is-link="true"
  />
</template>
