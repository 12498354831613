import type { AxiosResponse } from 'axios';
import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';

import { useCreatedDefectsStore } from '@/entities/Defects';
import { CreatedDefectsTypes } from '@/entities/Defects/interfaces';
import { OrderStatus, useOrdersStore } from '@/entities/Orders';
import { getCurrentUTC0Date, getCurrentUTC0Time } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';

import { createDefect } from '../api/createDefect';
import { createDefectFiles } from '../api/createDefectFiles';
import type { CreateDefectInput, FormStateDefectManagement } from '../interfaces/DefectManagement';

export const useDefectManagementStore = defineStore('defectManagement', () => {
  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();
  const { updateLocalOperationStatusAndDefects } = useOrdersStore();
  const { profile } = storeToRefs(useUserProfileStore());
  const { addCreatedDefect } = useCreatedDefectsStore();
  const createDefectValues = ref({} as FormStateDefectManagement);
  const defectId = ref<string | undefined>();
  const successCreate = ref<boolean | undefined>();

  const createDefectFilesAsync = async (QMNUM: string, files: CreateDefectInput['files']) => {
    const inputData = files?.map((file) => [QMNUM, file.FILE_NAME, file.FILE_CONTENT]);
    const tryBlock = async () => {
      if (inputData) {
        const createConfirmResponse = await createDefectFiles(inputData);
        handleResponse(createConfirmResponse);
      }
    };
    await handleLoading({ tryBlock, funcName: 'createDefectFilesAsync' });
  };

  const createDefectAsync = async ({
    formData,
    TPLNR,
    orderId,
    operationId,
    files,
    symptom,
    successMessage,
  }: CreateDefectInput) => {
    const currentUTC0Date = getCurrentUTC0Date();
    const currentUTC0Time = getCurrentUTC0Time();
    const inputData = [
      TPLNR ?? '',
      formData.EQUNR,
      formData.QMTXT,
      symptom.OTGRP ?? '',
      symptom.OTEIL ?? '',
      profile.value?.personnel_number ?? '', // QMNAM Табельный номер
      formData.PRIOK,
      currentUTC0Date, //QMDAT Текущую дату клиента переводим в UTC0, формат «2022-12-09»
      currentUTC0Time, // MZEIT //Текущее время переводим в UTC0, формат “ 13:30:07”
      null, //STRMN
      null, //STRUR
      null, //LTRMN
      null, //LTRUR
      formData.CLOSE_NOTIF ? 'X' : '', // устранен на месте переводим из bool в X или ''
      ...(formData.CLOSE_NOTIF
        ? [currentUTC0Date, currentUTC0Time, currentUTC0Date, currentUTC0Time]
        : [null, null, null, null]),
    ];

    const tryBlock = async () => {
      const createConfirmResponse = await createDefect(inputData).catch(() => ({
        data: {
          EX_NOTIF_LIST: [{ QMNUM: new Date().getTime().toString() }], // Если не удалось создать запись, то используем временный ID в виде timestamp
        },
        status: window.navigator.onLine ? 0 : 200,
      }));
      // Если это не серверная ошибка, а отсутствие сети,
      // то продолжаем выполнение для добавления запросов в оффлайн очередь, включая запрос на отправку вложений
      if (handleResponse(createConfirmResponse as AxiosResponse, successMessage)) {
        const parsedData = createConfirmResponse.data;
        if (files?.length) await createDefectFilesAsync(parsedData.EX_NOTIF_LIST[0].QMNUM, files);
        if (orderId && operationId && parsedData.EX_NOTIF_LIST.length)
          updateLocalOperationStatusAndDefects({
            orderId,
            operationId,
            localDefects: parsedData.EX_NOTIF_LIST,
            localStatus: OrderStatus.COMPLETED,
          });
        addCreatedDefect(
          formData.CLOSE_NOTIF ? CreatedDefectsTypes.ELIMINATED : CreatedDefectsTypes.NEW,
        );
        successCreate.value = true;
      } else {
        successCreate.value = false;
      }

      defectId.value = createConfirmResponse.data?.EX_NOTIF_LIST?.[0].QMNUM;
    };
    await handleLoading({ tryBlock, funcName: 'fetchCreateDefectAsync' });
  };

  const clearCreateDefectValues = () =>
    (createDefectValues.value = {} as FormStateDefectManagement);

  return {
    createDefectAsync,
    createDefectValues,
    clearCreateDefectValues,
    defectId,
    successCreate,
  };
});
