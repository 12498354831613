import { ref, watchEffect } from 'vue';

import { OrderTypesName, useGetOrders } from '@/entities/Orders';

import type { OrdersHeaders } from '../interfaces';
import { getOrdersByType } from '../lib';

export const useGetOrderByType = () => {
  const { orders, ordersFetchError, reloadOrders } = useGetOrders();
  const ordersByType = ref({
    [OrderTypesName.INSPECTION]: [] as OrdersHeaders[],
    [OrderTypesName.MAINTENANCE]: [] as OrdersHeaders[],
    [OrderTypesName.CURRENT_REPAIR]: [] as OrdersHeaders[],
    [OrderTypesName.ALL]: [] as OrdersHeaders[],
  });

  watchEffect(() => {
    if (orders?.value) {
      const categorizedOrders = getOrdersByType(orders.value.EX_HEADERS);
      ordersByType.value = categorizedOrders;
    }
  });

  return { ordersByType, orders, ordersFetchError, reloadOrders };
};
