<script setup lang="ts">
import { computed, ref } from 'vue';

import { NoAvatar, PhoneIcon } from '@/shared/assets/svg';
import { APP_URL } from '@/shared/lib';
import { HorizontalSwipeAble } from '@/shared/ui/HorizontalSwipeAble';

import type { UsersList } from '../../interfaces';
import styles from './UserItem.module.scss';

interface Props {
  user: UsersList;
  title?: string;
  index?: number;
  swipeAble?: boolean;
  showPosition?: boolean;
  showUserPosition?: boolean;
  textDismiss?: string;
  avatarSize?: number;
  checked?: boolean;
  hidePhone?: boolean;
  isSupervisor?: boolean;
  onDismiss?: (id: string) => void;
  onSelect?: (checked: boolean) => void;
}

const {
  user,
  title,
  index,
  swipeAble,
  showPosition,
  showUserPosition,
  textDismiss,
  avatarSize,
  checked,
  hidePhone,
  isSupervisor,
  onDismiss,
  onSelect,
} = defineProps<Props>();

const isImageError = ref(false);
const imageError = computed({
  get: () => isImageError.value,
  set: (newValue) => {
    isImageError.value = newValue;
  },
});
const handleImageError = () => (imageError.value = true);
const userPhoto = computed(() => (user.photo_url ? `${APP_URL}${user.photo_url}` : undefined));
const userName = computed(
  () => `${user.last_name} ${user.first_name.charAt(0)}. ${user.middle_name.charAt(0)}.`,
);

const componentItem = swipeAble ? HorizontalSwipeAble : 'div';
</script>

<template>
  <componentItem
    :item-id="user.personnel_number"
    :on-right-slot-click="onDismiss"
    :text-dismiss="textDismiss"
  >
    <div :class="styles.card">
      <div v-if="title" :class="styles.title">{{ title }}</div>
      <div :class="styles.main">
        <div v-if="onSelect" :class="styles.checkboxContainer">
          <a-checkbox
            :checked="checked"
            @change="(e: Event) => onSelect?.((e.target as HTMLInputElement).checked)"
          />
        </div>
        <div>
          <a-avatar
            v-if="userPhoto && !imageError"
            :size="avatarSize ?? 44"
            shape="square"
            :src="userPhoto"
            :class="styles.avatar"
            :load-error="handleImageError"
          />
          <NoAvatar v-else :class="styles.avatar" />
        </div>
        <div :class="styles.body">
          <a-typography-text v-if="showUserPosition && user.position" :class="styles.position"
            >{{ isSupervisor ? 'Старший бригады' : user.position }}
          </a-typography-text>
          <a-typography-text :class="styles.name">
            {{ userName }}
          </a-typography-text>
          <a-typography-text v-if="index != null && showPosition" :class="styles.serial"
            >{{ `Исполнитель ${index + 1}` }}
          </a-typography-text>
          <div :class="styles.overlay" v-if="user.absence_reason"></div>
          <a-typography-text :class="styles.absence" v-if="user.absence_reason"
            >Отсутствует
          </a-typography-text>
        </div>
        <a
          v-if="user?.phone_number?.[0] && !hidePhone"
          :href="`tel:${user.phone_number[0]}`"
          :class="styles.phone"
        >
          <PhoneIcon />
        </a>
      </div>
    </div>
  </componentItem>
</template>
