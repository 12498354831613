import { httpFmpClient } from '@/shared/api';
import { API_OBTAIN_URL } from '@/shared/lib';

export const fetchFmpToken = async () => {
  return await httpFmpClient.post(API_OBTAIN_URL, {
    environment: import.meta.env.VITE_FMP_ENV,
    project: import.meta.env.VITE_FMP_PROJECT,
    username: import.meta.env.VITE_FMP_USERNAME,
    password: '?q+tqMpW$K{i4P13O^f4L',
    device_id: import.meta.env.VITE_FMP_DEVICE_ID,
  });
};
