<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { InspectionsProgressPanel, TasksPanel, TmTrProgressPanel } from '@/entities/Orders';
import { ShiftTimer } from '@/entities/UserProfile';
import { useUserProfileStore } from '@/shared/model';
import { DefaultSlider } from '@/shared/ui/DefaultSlider';

const { userIsRepairer } = storeToRefs(useUserProfileStore());
</script>

<template>
  <DefaultSlider>
    <div><TasksPanel /></div>
    <div><InspectionsProgressPanel /></div>
    <div v-if="userIsRepairer"><TmTrProgressPanel /></div>
    <div><ShiftTimer /></div>
  </DefaultSlider>
</template>
