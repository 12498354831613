import { storeToRefs } from 'pinia';
import { ref, watchEffect } from 'vue';

import { useUserProfileStore } from '@/shared/model';

import { OrderStatus } from '../interfaces/Orders';
import {
  checkOrderIsInspection,
  checkOrderMaintenanceOrRepair,
  getOrderOperationsByLocalStatus,
} from '../lib';
import { useOrdersStore } from '../model/useOrdersStore';

export const useGetInfoWidgetOrderData = () => {
  const defaultTasksInfo = {
    total: 0,
    inProgress: 0,
    completed: 0,
    canceled: 0,
  };

  const defaultInspectionsProgressInfo = {
    total: 0,
    totalOperations: 0,
    completedOperations: 0,
  };

  const defaultTmTrProgressInfo = {
    total: 0,
    totalOperations: 0,
    completedOperations: 0,
  };
  const ordersStore = useOrdersStore();
  const { userIsEmployee, userIsRepairer, userIsSupervisor } = storeToRefs(useUserProfileStore());
  const { orders } = storeToRefs(ordersStore);
  const tasksInfo = ref(defaultTasksInfo);
  const inspectionsProgressInfo = ref(defaultInspectionsProgressInfo);
  const tmTrProgressInfo = ref(defaultTmTrProgressInfo);

  const clearInfoWidgetOrderData = () => {
    tasksInfo.value = { ...tasksInfo.value, ...defaultTasksInfo };
    inspectionsProgressInfo.value = {
      ...inspectionsProgressInfo.value,
      ...defaultInspectionsProgressInfo,
    };
    tmTrProgressInfo.value = { ...tmTrProgressInfo.value, ...defaultTmTrProgressInfo };
  };

  watchEffect(() => {
    if (!orders.value) return;

    clearInfoWidgetOrderData();

    orders.value?.EX_HEADERS.forEach((order) => {
      const orderIsInspection = checkOrderIsInspection(order);
      const orderMaintenanceOrRepair = checkOrderMaintenanceOrRepair(order);
      if (!orderIsInspection && !orderMaintenanceOrRepair) return; //если в заказах пришли не подходящие по типу то не учитываем в виджетах

      tasksInfo.value.total += 1;

      const orderOperations = getOrderOperationsByLocalStatus(
        order.AUFNR,
        orders.value?.EX_OPER_LIST || [],
      );
      if (orderIsInspection) {
        inspectionsProgressInfo.value.total += 1;
        inspectionsProgressInfo.value.totalOperations += orderOperations?.totalOperations || 0;
      }
      if (orderMaintenanceOrRepair) {
        tmTrProgressInfo.value.total += 1;
        tmTrProgressInfo.value.totalOperations += orderOperations?.totalOperations || 0;
      }

      const orderIsCompleted = [
        OrderStatus.COMPLETED,
        OrderStatus.CLOSED,
        OrderStatus.CLOSED_WITH_ERRORS,
        userIsRepairer.value && OrderStatus.PENDING,
      ].includes(order.STTXT);

      const orderIsInProgress = [
        OrderStatus.IN_PROGRESS,
        userIsSupervisor.value && OrderStatus.PENDING,
      ].includes(order.STTXT);

      if (orderIsCompleted) {
        tasksInfo.value.completed += 1;
        // Если заказ выполнен, то все его операции считаются выполненными
        if (orderIsInspection) {
          inspectionsProgressInfo.value.completedOperations +=
            orderOperations?.totalOperations || 0;
        }
        if (orderMaintenanceOrRepair) {
          tmTrProgressInfo.value.completedOperations += orderOperations?.totalOperations || 0;
        }
      } else if (orderIsInProgress) {
        tasksInfo.value.inProgress += 1;
        //Если заказ не выполнен, то смотрим на локальные статусы операций в нем
        if (orderIsInspection) {
          inspectionsProgressInfo.value.completedOperations +=
            orderOperations?.completedOperations || 0;
        }
        if (orderMaintenanceOrRepair) {
          tmTrProgressInfo.value.completedOperations += orderOperations?.completedOperations || 0;
        }
      } else if (order.STTXT === OrderStatus.CANCELED) {
        tasksInfo.value.canceled += 1;
      }
    });
  });

  return {
    tasksInfo: userIsEmployee.value ? ref(defaultTasksInfo) : tasksInfo,
    inspectionsProgressInfo,
    tmTrProgressInfo,
  };
};
