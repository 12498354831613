<script setup lang="ts">
import PDF from 'pdf-vue3';
import { onMounted, ref } from 'vue';

import type { FileItem } from '@/shared/interfaces';
import { getAttachmentIsImage, getAttachmentIsVideo, getSrcFromBinaryString } from '@/shared/lib';
import { useFileStore } from '@/shared/model/useFiles';
import { InformationDialog } from '@/shared/ui';

import styles from './AttachmentViewer.module.scss';

interface Props {
  file: FileItem;
}
const { file } = defineProps<Props>();

const fileListStore = useFileStore();
const imageUrl = ref('');
const videoUrl = ref('');
const pdfUrl = ref('');
const errorModalOpen = ref(false);

onMounted(async () => {
  await fileListStore.fetchFileAsync([file.FILE_ID]);
  const fileContent = fileListStore.getFilesByIds([file.FILE_ID])[0]?.FILE_CONTENT;
  const attachmentIsImage = getAttachmentIsImage(file?.FILE_EXT);
  const attachmentIsVideo = getAttachmentIsVideo(file?.FILE_EXT);
  if (attachmentIsImage) {
    imageUrl.value = getSrcFromBinaryString(fileContent, file?.FILE_EXT ?? '');
  } else if (attachmentIsVideo) {
    videoUrl.value = getSrcFromBinaryString(fileContent, file?.FILE_EXT ?? '');
  } else if (file?.FILE_EXT === 'pdf') {
    pdfUrl.value = fileContent;
  } else {
    errorModalOpen.value = true;
  }
});
</script>

<template>
  <div :class="styles.image" v-if="imageUrl">
    <a-image :src="imageUrl" :alt="file?.FILE_NAME ?? ''" />
  </div>
  <video
    :class="styles.video"
    controls
    :src="videoUrl"
    v-if="videoUrl"
    controlsList="nodownload"
  ></video>
  <PDF v-if="pdfUrl" :src="pdfUrl" />
  <InformationDialog
    v-if="errorModalOpen"
    text="Файл данного формата не поддерживается приложением"
    :on-close="() => $router.go(-1)"
  />
</template>
