<script setup lang="ts">
import { ref } from 'vue';

import type { PageTab } from '@/shared/interfaces';

import styles from './SubTabs.module.scss';

interface Props {
  tabs: PageTab[];
}
const { tabs } = defineProps<Props>();

const value = ref(tabs[0].id);
</script>

<template>
  <div :class="styles.wrap">
    <a-radio-group v-model:value="value">
      <a-radio-button v-for="tab in tabs" :key="tab.id" :value="tab.id">{{
        tab.name
      }}</a-radio-button>
    </a-radio-group>

    <template v-for="item in tabs">
      <div v-show="value === item.id">
        <component :is="item.component" v-bind="item.props"></component>
      </div>
    </template>
  </div>
</template>
