import { useRouter } from 'vue-router';

import type { OrdersHeadersWithOperListAndComponent } from '@/entities/Orders';
import { OrderStatus, useOrdersStore } from '@/entities/Orders';
import { useCreateConfirmStore } from '@/features/RejectionReasons';
import { NAV_DASHBOARD } from '@/shared/lib';

export const useConfirmOrder = () => {
  const { fetchCreateConfirmAsync } = useCreateConfirmStore();
  const { updateLocalOrderStatus } = useOrdersStore();
  const router = useRouter();

  const confirmOrder = async (order: OrdersHeadersWithOperListAndComponent | undefined) => {
    if (order) {
      await fetchCreateConfirmAsync(order.AUFNR, order.EX_OPER_LIST, '', '', order?.timeBegin);
      updateLocalOrderStatus(order.AUFNR, OrderStatus.COMPLETED);
      router.push(NAV_DASHBOARD);
    }
  };

  return {
    confirmOrder,
  };
};
