import type { AxiosResponse } from 'axios';
import { defineStore, storeToRefs } from 'pinia';
import { ref, watchEffect } from 'vue';

import type { ErrorResponse } from '@/shared/interfaces';
import { DEFAULT_IM_WERKS } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';

import type { FormStateMaterial } from '..';
import { getMaterialList } from '../api/getMaterialList';

export const useMaterialStore = defineStore('materials', () => {
  const materialList = ref<{ EX_MATERIAL_LIST: FormStateMaterial[] } | undefined>(undefined);
  const materialListFetchError = ref(false);

  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();
  const { profile } = storeToRefs(useUserProfileStore());

  const fetchMaterialListAsync = async (factoryIds?: string[]) => {
    const tryBlock = async () => {
      materialListFetchError.value = false;
      const payload = (factoryIds || [profile.value?.enterprise_sap_code ?? DEFAULT_IM_WERKS]).map(
        (id) => [id],
      );
      const response = await getMaterialList(payload);
      if (
        handleResponse(
          response as AxiosResponse<ErrorResponse & { EX_MATERIAL_LIST: FormStateMaterial[] }>,
        )
      ) {
        materialList.value = {
          EX_MATERIAL_LIST: response.data.EX_MATERIAL_LIST.map((material) => ({
            ...material,
            MENGE: +material.LABST,
            max: +material.LABST,
            isUsing: false,
          })),
        };
      } else {
        materialListFetchError.value = true;
      }
    };
    await handleLoading({ tryBlock, funcName: 'fetchMaterialListAsync' });
  };

  watchEffect(() => {
    if (materialList.value?.EX_MATERIAL_LIST) {
      materialList.value.EX_MATERIAL_LIST = materialList.value.EX_MATERIAL_LIST.map((material) => {
        return {
          ...material,
          MENGE: +material.LABST,
          max: +material.LABST,
          isUsing: false,
        };
      });
    }
  });

  return {
    materialList,
    materialListFetchError,
    fetchMaterialListAsync,
  };
});
