import type { PageTab } from '@/shared/interfaces';
import { MyShiftWidget } from '@/widgets/MyShiftWidget';
import { OrdersByTeamsWidget } from '@/widgets/OrdersByTeamsWidget';
import { PastEventsList } from '@/widgets/PastEventsListWidget';
import { TeamsWidget } from '@/widgets/TeamsWidget';

export const navShiftItems: PageTab[] = [
  {
    name: 'Моя смена',
    component: MyShiftWidget,
    id: 1,
  },
  {
    name: 'Прошедшие события',
    component: PastEventsList,
    id: 2,
  },
];

export const supervisorShiftItems: PageTab[] = [
  {
    name: 'Команда',
    component: TeamsWidget,
    id: 1,
  },
  {
    name: 'Задания',
    component: OrdersByTeamsWidget,
    id: 2,
    props: {
      shiftView: true,
    },
  },
  {
    name: 'Прошедшие события',
    component: PastEventsList,
    id: 3,
  },
];
