import type { Equipment } from '../interfaces';

export const getSortedHierarchy = (children: Equipment[]) => {
  const eqMap = new Map();
  const parents: (Equipment & { children: Equipment[] })[] = [];

  children.forEach((item) => {
    eqMap.set(item.EQUNR, { ...item, children: [] });
  });

  children.forEach((item) => {
    if (eqMap.has(item.HEQUI)) {
      eqMap.get(item.HEQUI).children.push(eqMap.get(item.EQUNR));
    } else {
      parents.push(eqMap.get(item.EQUNR));
    }
  });

  function flattenHierarchy(list: (Equipment & { children: Equipment[] })[]) {
    const result: Equipment[] = [];
    list.sort((a, b) => (a.EQUNR < b.EQUNR ? -1 : 1));
    list.forEach((parent) => {
      result.push(parent);
      if (parent.children.length) {
        parent.children.sort((a, b) => (a.EQUNR < b.EQUNR ? -1 : 1));
        result.push(...parent.children);
      }
    });
    return result;
  }

  return flattenHierarchy(parents);
};
