import { type PageTab } from '@/shared/interfaces';
import { CompletedDefectsWidget } from '@/widgets/CompletedDefectsWidget';
import { InProgressDefectsWidget } from '@/widgets/InProgressDefectsWidget';
import { MyDefects } from '@/widgets/MyDefects';

export const tabs: PageTab[] = [
  {
    name: 'В работе',
    component: InProgressDefectsWidget,
    id: 1,
  },
  {
    name: 'Выполнены',
    component: CompletedDefectsWidget,
    id: 2,
  },
  {
    name: 'Мои дефекты',
    component: MyDefects,
    id: 3,
  },
];
