import { StatusLabels } from '@/shared/interfaces';

import { type Order, OrderTypesName } from '../interfaces';

export const postHistory: Order[] = [
  {
    id: 1,
    name: '09.04.24',
    subtitle: 'Давлеталин Л.Н',
    status: StatusLabels.IN_PROGRESS,
    type: OrderTypesName.INSPECTION,
  },
  {
    id: 2,
    name: '09.04.24',
    subtitle: 'Давлеталин Л.Н',
    status: StatusLabels.IN_PROGRESS,
    type: OrderTypesName.INSPECTION,
  },
  {
    id: 3,
    name: '09.04.24',
    subtitle: 'Давлеталин Л.Н',
    status: StatusLabels.IN_PROGRESS,
    type: OrderTypesName.MAINTENANCE,
    operatingTime: '10 100 м.ч',
  },
  {
    id: 4,
    name: '09.04.24',
    subtitle: 'Давлеталин Л.Н',
    status: StatusLabels.IN_PROGRESS,
    type: OrderTypesName.MAINTENANCE,
  },
  {
    id: 5,
    name: '09.04.24 - 11.04.24',
    subtitle: 'Давлеталин Л.Н',
    status: StatusLabels.IN_PROGRESS,
    type: OrderTypesName.CURRENT_REPAIR,
  },
  {
    id: 6,
    name: '06.04.24 - 09.04.24',
    subtitle: 'Давлеталин Л.Н',
    status: StatusLabels.IN_PROGRESS,
    type: OrderTypesName.CURRENT_REPAIR,
  },
  {
    id: 7,
    name: '06.04.24 - 09.04.24',
    subtitle: 'Давлеталин Л.Н',
    status: StatusLabels.IN_PROGRESS,
    type: OrderTypesName.CURRENT_REPAIR,
  },
];
