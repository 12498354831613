import { defineStore } from 'pinia';
import { ref } from 'vue';

import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';

import { getPriorityList } from '../api/getPriorityList';
import type { PriorityListResponse } from '../interfaces';

export const usePriorityListStore = defineStore('priorityList', () => {
  const priorityList = ref<PriorityListResponse | undefined>(undefined);
  const priorityListFetchError = ref(false);

  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();

  const fetchPriorityListAsync = async (silent = false) => {
    const tryBlock = async () => {
      priorityListFetchError.value = false;
      const response = await getPriorityList();

      if (handleResponse(response)) {
        priorityList.value = response.data as PriorityListResponse;
      } else {
        priorityListFetchError.value = true;
      }
    };
    await handleLoading({ tryBlock, funcName: 'fetchPriorityListAsync', noGlobalLoader: silent });
  };

  return {
    priorityList,
    priorityListFetchError,
    fetchPriorityListAsync,
  };
});
