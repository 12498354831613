<script setup lang="ts">
import { useTechnologistWidgetData } from '@/entities/Orders/hooks/useTechnologistWidgetData';
import { DefaultSlider } from '@/shared/ui';

import styles from './TechnologistOrdersWidget.module.scss';

interface Props {
  shiftView?: boolean;
}

const { shiftView } = defineProps<Props>();

const { technologistWidgetInfo } = useTechnologistWidgetData();
</script>

<template>
  <DefaultSlider>
    <div
      :class="styles.panel"
      v-for="technologistWidgetInfoKey in Object.keys(technologistWidgetInfo)"
      :key="technologistWidgetInfoKey"
    >
      <div :class="styles.wrap">
        <a-typography-text :class="styles.text">Панель задач </a-typography-text>
        <a-typography-title :level="3" style="margin-top: 0" :class="styles.title">{{
          technologistWidgetInfoKey
        }}</a-typography-title>
        <ul :class="styles.list">
          <li
            :class="{
              [styles.item]: true,
              [styles.highlight]:
                technologistWidgetInfo[technologistWidgetInfoKey].totalOrders > 0 && shiftView,
            }"
          >
            <b :class="styles.count">{{
              technologistWidgetInfo[technologistWidgetInfoKey].totalOrders
            }}</b
            ><span :class="styles.status">Заданий</span>
          </li>
          <li :class="styles.item">
            <b :class="styles.count">{{
              technologistWidgetInfo[technologistWidgetInfoKey].completedOrders
            }}</b
            ><span :class="styles.status">Выполнено</span>
          </li>
          <li :class="styles.item">
            <b :class="styles.count">{{
              technologistWidgetInfo[technologistWidgetInfoKey].canceledOrders
            }}</b
            ><span :class="styles.status">Отклонено</span>
          </li>
        </ul>
      </div>
    </div>
  </DefaultSlider>
</template>
