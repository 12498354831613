import { ref, watchEffect } from 'vue';

import type { OrdersOperList } from '../interfaces/Orders';
import { useGetOrderInfo } from './useGetOrderInfo';

export const useOperationInfo = (orderId: string, operationId: string) => {
  const { order } = useGetOrderInfo(orderId);
  const operation = ref<OrdersOperList | undefined>(undefined);

  watchEffect(() => {
    const currentOperation = order.value?.EX_OPER_LIST.find((operation) => {
      return operation.VORNR === operationId;
    });

    if (currentOperation) operation.value = currentOperation;
  });

  return { operation };
};
