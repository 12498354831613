<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { useUserProfileStore } from '@/shared/model';

import styles from './ShiftTimer.module.scss';

const userProfileStore = useUserProfileStore();
const { profile } = storeToRefs(userProfileStore);

const timeDiff = computed(() => {
  if (!profile.value.shift_end_date) {
    return 0;
  }

  return (
    new Date(`${profile.value.shift_end_date.split('T')[0]} ${profile.value.end_time}`).getTime() -
    new Date().getTime()
  );
});
</script>

<template>
  <div :class="styles.timer">
    <a-typography-text :class="styles.text">До завершения смены</a-typography-text>
    <a-typography-title :level="3" :class="styles.title">
      <vue-countdown :time="timeDiff" :interval="100" v-slot="{ hours, minutes, seconds }">
        {{ hours }}:{{ minutes }}:{{ seconds }}
      </vue-countdown>
    </a-typography-title>
  </div>
</template>
