import type { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';

import { fetchTeams } from '../api/fetchTeams';
import { type TeamsRequest, type TeamsResponse } from '../interfaces';
import type { WorksOrderData } from '../interfaces/Teams';

export const useTeamsStore = defineStore('teams', () => {
  const teams = ref<TeamsResponse | undefined>(undefined);
  const teamsFetchError = ref(false);
  const teamsWorkOrdersError = ref(false);
  const teamSelected = ref<string | undefined>();
  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();

  const fetchTeamsAsync = async (request: TeamsRequest) => {
    const tryBlock = async () => {
      teamsFetchError.value = false;
      const response: AxiosResponse<TeamsResponse> = await fetchTeams(request);
      if (handleResponse(response)) {
        teams.value = response.data || {};
      } else {
        teamsFetchError.value = true;
      }
    };
    await handleLoading({ tryBlock, funcName: 'fetchTeamsAsync' });
  };

  const setTeamWorkerAbsence = async (userId: string, teamId: string) => {
    if (!teams.value) return;
    const newTeams = [] as TeamsResponse['data'];
    teams.value?.data.forEach((team) => {
      if (team.id === teamId) {
        const worker = team.workers_data.find((user) => user.personnel_number === userId);
        team.workers_data = team.workers_data.filter((user) => user.personnel_number !== userId);
        team.workers_count = team.workers_data.length;
        team.workgroup_capacity = team.workgroup_capacity - (worker?.work_duration ?? 0);
      }
      newTeams.push(team);
    });

    teams.value.data = newTeams;
  };

  const setTeamWorkOrders = async (orderId: string, teamId: string) => {
    if (!teams.value) return;
    const newTeams = [] as TeamsResponse['data'];
    const findOrder = [] as WorksOrderData[];
    teams.value?.data.forEach((team) => {
      const find = team.work_orders.find((work) => work.order_id === orderId);
      if (find) {
        findOrder.push({ ...find, workgroup_id: teamId });
      }
    });
    if (!findOrder.length) return;
    teams.value?.data.forEach((team) => {
      if (team.id !== teamId) {
        team.work_orders = team.work_orders.filter((order) => order.order_id !== orderId);
      } else {
        team.work_orders.push(findOrder[0]);
      }

      newTeams.push(team);
    });

    teams.value.data = newTeams;
  };

  const getTeamByOrderId = (orderId: string) => {
    return teams.value?.data.find((team) => {
      return team.work_orders.find((order) => order.order_id === orderId);
    });
  };

  return {
    teams,
    teamsOrders: computed(() => {
      if (!teams.value) return [];
      return teams.value.data.flatMap((team) =>
        team.work_orders.flatMap((order) => {
          const splitOperations = order.operations.split(',');
          return splitOperations.map((operation) => [order.order_id, operation]);
        }),
      );
    }),
    teamsFetchError,
    teamsWorkOrdersError,
    fetchTeamsAsync,
    setTeamWorkerAbsence,
    setTeamWorkOrders,
    getTeamByOrderId,
    teamSelected,
  };
});
