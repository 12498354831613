import type { EquipmentNodesByParent } from '../interfaces/Equipment';

export const getEquipmentChildAndChildrenChildEQUNRs = (
  equipmentsNodesByParentNodes: EquipmentNodesByParent | undefined,
  startEQUNR: string,
) => {
  //Получение EQUNR дочерних узлов и дочерних дочерних узлов рекурсивно
  if (!equipmentsNodesByParentNodes) return [];
  const finalArr: string[][] = [];
  const getChildNodesEQUNRs = (parentEQUNR: string) => {
    if (equipmentsNodesByParentNodes?.[parentEQUNR]) {
      equipmentsNodesByParentNodes?.[parentEQUNR].forEach((item) => {
        finalArr.push([item.EQUNR]);
        getChildNodesEQUNRs(item.EQUNR);
      });
    }
  };
  getChildNodesEQUNRs(startEQUNR);
  return finalArr;
};
