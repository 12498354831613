<script setup lang="ts">
import { computed, ref, watch, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { NfcTags } from '@/entities/Nfc';
import { OrderStatus, useGetOrderInfo, useOrdersStore } from '@/entities/Orders';
import { useCreateConfirmStore } from '@/features/RejectionReasons';
import { NAV_DASHBOARD } from '@/shared/lib';

const route = useRoute();
const router = useRouter();
const { orderId } = route.params;
const { order, getOrderTags, getOperationsByTag } = useGetOrderInfo(orderId as string);
const createConfirmStore = useCreateConfirmStore();
const { updateLocalOrderStatus } = useOrdersStore();

const isRequestSend = ref(true);
const allOperationsCompleted = computed(
  () =>
    !!getOperationsByTag.value.length &&
    getOperationsByTag.value.every((operation) => {
      return operation.STATUS.includes(OrderStatus.COMPLETED) || operation.localDefects?.length;
    }),
);

const createConfirm = async () => {
  await createConfirmStore.fetchCreateConfirmAsync(
    orderId as string,
    order.value?.EX_OPER_LIST || [],
    '',
    '',
    order.value?.timeBegin,
  );
  updateLocalOrderStatus(orderId as string, OrderStatus.COMPLETED);

  router.push(NAV_DASHBOARD);
};

watchEffect(async () => {
  if (allOperationsCompleted.value && isRequestSend.value) {
    isRequestSend.value = false;
    await createConfirm();
  }
});
</script>

<template>
  <NfcTags
    v-if="getOrderTags.length"
    :tags="getOrderTags"
    :tag-operations="getOperationsByTag"
    :order-id="orderId as string"
  />
</template>
