export const API_DEFECTS_LIST_URL = 'YFM_MPAI097_GET_DEFECT_LIST';
export const API_IDLES_LIST_URL = 'YFM_MPAI097_GET_PROSTOI_LIST';
export const API_GET_ORDER_URL = 'YFM_MPAI097_GET_ORDER';
export const API_ORDER_CONFIRMATIONS_URL = 'orderConfirmations?Orders=:orders';
export const API_GET_EQUIP_HIST = 'YFM_MPAI097_GET_EQUIP_HIST';
export const AP_GET_REASON_LIST = 'YFM_MPAI097_GET_REASON_LIST';
export const API_GET_FILE_URL = 'YFM_MPAI097_GET_FILE';
export const API_CREATE_CONFIRM = 'YFM_MPAI097_CREATE_CONFIRM';
export const API_CREATE_NOTIF = 'YFM_MPAI097_CREATE_NOTIF';
export const API_CREATE_NOTIF_FILES = 'YFM_MPAI097_ADD_NOTIF_FILES';
export const API_GET_PRIORITY_LIST = 'YFM_MPAI097_GET_PRIORITY_LIST';
export const API_GET_FUNC_LOC_LIST = 'YFM_MPAI097_GET_FUNC_LOC_LIST';
export const API_GET_MATERIALS = 'YFM_MPAI097_GET_MATERIAL_LIST';
export const API_GET_EQUIP_LIST = 'YFM_MPAI097_GET_EQUIP_LIST';
export const API_CREATE_MEASURE = 'YFM_MPAI097_CREATE_MEASURE';
export const API_GET_SECTORS = 'YFM_MPAI097_GET_SECTORS';
export const API_GET_ALTERNATIVE_SECTORS = 'YFM_MPAI099_GET_SECTORS'; // Данный get sectors нужен для формирования цехов и участков у предприятии ЕЭК(1001) и ПАЗ (1183)
export const API_GET_SYMPTOMS = 'YFM_MPAI097_GET_SYMPTOMS';
export const API_CREATE_WORKORDER = 'YFM_MPAI099_CREATE_WORKORDER';

export const API_OBTAIN_URL = 'auth/jwt/obtain/'; //Todo временно. Удалить после подключения авторизации
export const API_AUTH = 'auth';
export const API_USER = 'user?UserId=:userId';
export const API_ORDERS_OPER_PLAN = 'orders?UserId=:userId&StartDate=:startDate&EndDate=:endDate';
export const API_TEAMS =
  'teams?supervisor_id=:supervisorId&start_date=:startDate&end_date=:endDate';
export const API_TECHNOLOGIST =
  'technologists/?start_date=:startDate&end_date=:endDate&supervisor_technologist_id=:supervisorTechnologistId';
export const API_GET_USERS = 'users?org_unit_id=:unitId';
export const API_ORDER = 'order';
export const API_USER_UPDATE = 'user/update';
// TODO - убрать API_WORK_ORDERS так как не используется в МТОРО, используется в опер плане
export const API_WORK_ORDERS = 'work_orders/appointment';
export const API_WORK_ORDERS_DELETE = 'work_orders/appointment/:appointmentId';
export const API_ORDERS_ASSIGNEE = 'order/assignee';
export const API_USER_INFORMATION = 'userInformation';
