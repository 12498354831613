<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';

import { NAV_DASHBOARD } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { PageHeading } from '@/shared/ui';
import { TeamsWidget } from '@/widgets/TeamsWidget';

const router = useRouter();
const userProfileStore = useUserProfileStore();
const { userIsTechnologSupervisor } = storeToRefs(userProfileStore);

const handleBack = () => router.push(NAV_DASHBOARD);
</script>

<template>
  <PageHeading
    :text="userIsTechnologSupervisor ? 'Исполнители' : 'Бригады'"
    :go-back="true"
    :on-handle-back="handleBack"
  />
  <div :class="$style.page">
    <TeamsWidget />
  </div>
</template>

<style lang="scss" module>
.page {
  padding: 16px 12px 56px;
}
</style>
