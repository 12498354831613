import { backArrow, headerMenuItems, isWebViewBridgeAvailable, setTitle } from '@orta/bridge';
import { onMounted } from 'vue';

export const isInsideMobileApp = isWebViewBridgeAvailable();

export const useTitle = (title: string) => {
  onMounted(async () => {
    if (!isInsideMobileApp) return;
    await setTitle(title);
  });

  return { setTitle };
};

export const useReloadButton = (onClick?: () => void | Promise<void>) => {
  onMounted(() => {
    if (!isInsideMobileApp) return;
    headerMenuItems.setItems([{ id: 'reload', icon: 'Reload' }]);
    headerMenuItems.setClickHandler((id) => {
      if (id === 'reload') return onClick?.();
    });
  });
};

export const back = {
  visible: true,
  onClick(callback?: () => unknown) {
    return backArrow.setOnClickHandler(callback || (() => {}));
  },
  hide() {
    this.visible = false;
    return backArrow.setVisible(false);
  },
  show() {
    this.visible = true;
    return backArrow.setVisible(true);
  },
};

export const useHideBack = async () => {
  if (back.visible && isInsideMobileApp) {
    await backArrow.setMode(true);
    await back.hide();
  }
};

export const useShowBack = async () => {
  if (!back.visible && isInsideMobileApp) {
    await backArrow.setMode(true);
    await back.show();
  }
};

export const useBackClick = (onClick?: () => void | Promise<void>) => {
  onMounted(async () => {
    if (!isInsideMobileApp) return;
    await backArrow.setMode(true);
    back.onClick(onClick);
  });
};

// Код ниже прислал Тайнар Толеубаев @Kainarr

export const backNavigateQueue = {
  handlerFns: [] as (() => void)[],
  menuActive: false,
  androidHandleBack: null as null | (() => void),
  /**
   * Set new back
   * @param fn function to exec on 'back'
   **/
  set(fn: () => void) {
    console.log('backNavigateQueue.set');
    if (typeof fn !== 'function') throw new Error('handler should be a function, got ' + typeof fn);
    if (!this.handlerFns.length) {
      this.androidHandleBack = this.exec;
      backArrow.setVisible(true);
    }
    this.handlerFns = [fn];
  },
  /**
   * Add back navigation which will be poped after execution
   * @param fn function to exec on 'back'
   **/
  push(fn: () => void) {
    console.log('backNavigateQueue.push');
    if (typeof fn !== 'function') throw new Error('handler should be a function, got ' + typeof fn);
    if (!this.handlerFns.length) {
      backArrow.setVisible(true);
    }
    this.handlerFns.push(fn);
  },
  /**
   * Executes last handler fn and removes it
   */
  exec() {
    console.log('backNavigateQueue.exec');
    const menuActive = true; // store.getters['layout/show_burger_content'];
    if (menuActive) {
      // store.dispatch('layout/hideBurgerContent');
      if (!this.handlerFns.length) {
        backArrow.setVisible(false);
      }
    } else if (this.handlerFns.length) {
      this.handlerFns[this.handlerFns.length - 1]();
    } else {
      console.log('No func to exec');
    }
  },
  pop() {
    console.log('backNavigateQueue.pop');
    this.handlerFns.pop();
    if (!this.handlerFns.length) {
      backArrow.setVisible(false);
    }
  },
  clear() {
    console.log('backNavigateQueue.clear');
    this.handlerFns = [];
    this.androidHandleBack = null;
    backArrow.setVisible(false);
  },
  async init() {
    console.log('backNavigateQueue.init');
    await backArrow.setVisible(true);
    await backArrow.setOnClickHandler(this.exec.bind(this));
  },
};
