<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { watchEffect } from 'vue';
import { useRouter } from 'vue-router';

import { useIdlesListStore } from '@/entities/Defects/model/useIdlesListStore';
import { useStorage } from '@/shared/hooks/useStorage';
import {
  DEFAULT_IM_WERKS,
  NAV_DASHBOARD,
  NAV_ROOT,
  NAV_SHIFT_FINISHED,
  STORAGE_END_ACCEPTED_SHIFT,
  STORAGE_IDLES_LIST,
  STORAGE_LAST_ROUTE,
} from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { BottomButtons, PageHeading, PageTabs } from '@/shared/ui';

import { navShiftItems, supervisorShiftItems } from '../lib/navShiftItems';

const router = useRouter();
const { setItem, getItem } = useStorage();

const { profile, userIsSupervisor, userIsEmployee } = storeToRefs(useUserProfileStore());
const { idlesList } = storeToRefs(useIdlesListStore());

const redirectFunction = async () => {
  if (!profile.value.user_id) return; // Перенаправляем только после загрузки профиля

  const lastRoute = await getItem(STORAGE_LAST_ROUTE);
  const endAcceptedShiftTime = await getItem(STORAGE_END_ACCEPTED_SHIFT);

  const shiftAcceptedToday =
    new Date(profile.value.shift_end_date || '').getDate() ===
    new Date(endAcceptedShiftTime || '').getDate();

  const correctTimeToSeeShift =
    new Date() <= new Date(profile.value.endDatetimeWithOffset || '') &&
    new Date() >= new Date(profile.value.startDatetimeWithOffset || '');

  // Если установлен альтернативный завод,
  // то не используем редирект с целью тестирования
  if (DEFAULT_IM_WERKS) return;

  if (userIsEmployee.value) return router.push(NAV_DASHBOARD); // Принятие смены не требуется для сотрудников актива
  if (shiftAcceptedToday || !correctTimeToSeeShift) {
    if (lastRoute === NAV_ROOT || lastRoute === NAV_SHIFT_FINISHED) {
      router.push(NAV_DASHBOARD);
    } else {
      router.push(lastRoute || NAV_DASHBOARD);
    }
  }
};

watchEffect(() => {
  redirectFunction();
});

const onAcceptShift = async () => {
  await setItem(STORAGE_END_ACCEPTED_SHIFT, profile.value.shift_end_date || '');
  const idesIds = idlesList.value?.EX_PROSTOI_LIST?.map((item) => item.QMNUM) || [];
  await setItem(STORAGE_IDLES_LIST, JSON.stringify(idesIds));
  await router.push(NAV_DASHBOARD);
};
</script>

<template>
  <div :class="$style.wrap">
    <PageHeading text="Принять смену" />
    <PageTabs
      :tabs="userIsSupervisor ? supervisorShiftItems : navShiftItems"
      :no-wrap="userIsSupervisor ? undefined : [1]"
    />
    <BottomButtons :no-panel="true">
      <a-button type="primary" html-type="submit" size="large" @click="onAcceptShift"
        >Начать смену</a-button
      >
    </BottomButtons>
  </div>
</template>

<style lang="scss" module>
.wrap {
  padding-bottom: 94px;
}
</style>
