import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';

import { DEFAULT_IM_WERKS } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';

import { getRejectReasons } from '../api/getRejectReasons';
import { type RejectReasonsResponse } from '../interfaces';

export const useRejectReasonsStore = defineStore('rejectReasons', () => {
  const rejectReasons = ref<RejectReasonsResponse | undefined>(undefined);
  const rejectReasonsFetchError = ref(false);
  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();
  const { profile } = storeToRefs(useUserProfileStore());

  const fetchRejectReasonsAsync = async (silent = false) => {
    const tryBlock = async () => {
      rejectReasonsFetchError.value = false;
      const rejectReasonsResponse = await getRejectReasons(
        profile.value?.enterprise_sap_code ?? DEFAULT_IM_WERKS,
      );
      if (handleResponse(rejectReasonsResponse)) {
        rejectReasons.value = rejectReasonsResponse.data as RejectReasonsResponse;
      } else {
        rejectReasonsFetchError.value = true;
      }
    };
    await handleLoading({ tryBlock, funcName: 'fetchRejectReasonsAsync', noGlobalLoader: silent });
  };

  return {
    rejectReasons,
    rejectReasonsFetchError,
    fetchRejectReasonsAsync,
  };
});
