import type { Equipment, EquipmentNodesByParent } from '../interfaces/Equipment';

export const getEquipmentChildAndChildrenChild = (
  equipmentsNodesByParentNodes: EquipmentNodesByParent | undefined,
  startEQUNR: string,
) => {
  //Получение EQUNR дочерних узлов и дочерних дочерних узлов рекурсивно
  if (!equipmentsNodesByParentNodes) return [];
  const finalArr: Equipment[] = [];
  const getChildNodes = (parentEQUNR: string) => {
    if (equipmentsNodesByParentNodes?.[parentEQUNR]) {
      equipmentsNodesByParentNodes?.[parentEQUNR].forEach((item) => {
        finalArr.push(item);
        getChildNodes(item.EQUNR);
      });
    }
  };
  getChildNodes(startEQUNR);
  return finalArr;
};
