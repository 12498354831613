<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { type FormStateMaterial, MaterialsFormList } from '@/entities/Materials';
import {
  OperationsList,
  OrderStatus,
  useGetOrderInfo,
  useOrderConfirmationsStore,
  useOrdersStore,
} from '@/entities/Orders';
import type { OrdersOperList } from '@/entities/Orders/interfaces/Orders';
import { RejectionReasons } from '@/features/RejectionReasons';
import { useStorage } from '@/shared/hooks';
import { getTimeDurationValue, STORAGE_MATERIALS } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { HandleRequest, InformationDialog } from '@/shared/ui';

import styles from './WorksWidget.module.scss';

const currentOperation = ref<OrdersOperList>();
const openReasons = ref<boolean>(false);
const errorModalOpen = ref(false);
const componentOperationsKey = ref(0);
const updatedOrderMaterials = ref<FormStateMaterial[]>([]);
const handleModalReasons = (value: boolean) => {
  openReasons.value = value;
};

const errorQuantityModalOpen = ref(false);
const handleToggleQuantityErrorModal = (value: boolean) => (errorQuantityModalOpen.value = value);
const materialCurrentValue = ref({} as { type: string; quantity: number });

const route = useRoute();
const { orderId } = route.params;
const { order, ordersFetchError, reloadOrders } = useGetOrderInfo(orderId as string);
const { updateLocalOperationStatusAndDefects, updateLocalOrderStatus } = useOrdersStore();
const { getItem, setItem } = useStorage();

const storageKey = `${STORAGE_MATERIALS}_${orderId}`;

const userProfileStore = useUserProfileStore();
const { userIsSupervisor } = storeToRefs(userProfileStore);

const orderConfirmationsStore = useOrderConfirmationsStore();
const { orderConfirmations } = storeToRefs(orderConfirmationsStore);

const currentOrderConfirmation = computed(() =>
  orderConfirmations.value?.find((orderConfirmation) => orderConfirmation.OrderNumber === orderId),
);

const onReject = (itemId: number) => {
  currentOperation.value = order?.value?.EX_OPER_LIST.find(
    (oper) => oper.VORNR === itemId.toString(),
  );
  handleModalReasons(true);
};

const handleStatusIcon = (e: Event, operationId: string) => {
  e.preventDefault();

  if (order?.value?.STTXT === OrderStatus.IN_PROGRESS) {
    updateLocalOperationStatusAndDefects({
      orderId: orderId as string,
      operationId,
      localStatus: OrderStatus.COMPLETED,
      localChecked: true,
    });
  } else {
    errorModalOpen.value = true;
  }
};

const getParseMaterials = async (key: string) => {
  const materialsSelected = await getItem(key);
  return materialsSelected ? JSON.parse(materialsSelected) : [];
};

const handleSelectMaterial = async (id: string, checked: boolean, quantity?: string) => {
  if (order.value?.EX_COMPONENT) {
    const parseMaterials = (await getParseMaterials(storageKey)) as FormStateMaterial[];
    updatedOrderMaterials.value = [...parseMaterials];

    const findMaterial = order.value.EX_COMPONENT.find(
      (material) => parseInt(material.MATNR, 10) === +id,
    ) as FormStateMaterial;
    const findMaterialChecked = updatedOrderMaterials.value.find(
      (material) => parseInt(material.MATNR, 10) === +id,
    );

    if (checked) {
      materialCurrentValue.value = {
        type: findMaterial.MSEHT,
        quantity: findMaterial.MENGE,
      };
    }

    if (!findMaterialChecked && findMaterial) {
      updatedOrderMaterials.value.push({
        ...findMaterial,
        isUsing: checked,
        ...(quantity ? { MENGE: +quantity } : {}),
      });
    }

    updatedOrderMaterials.value = updatedOrderMaterials.value.map((material) => {
      if (parseInt(material.MATNR, 10) === +id && quantity !== undefined) {
        return {
          ...material,
          isUsing: checked,
          MENGE: +quantity,
        };
      }

      return material;
    });

    await setItem(storageKey, JSON.stringify(updatedOrderMaterials.value));
  }
};

const handleToggleErrorModal = (value: boolean) => (errorModalOpen.value = value);

const forceRenderOperationsComponent = () => {
  componentOperationsKey.value += 1;
};

watch(
  () => order.value?.EX_OPER_LIST,
  () => {
    forceRenderOperationsComponent();
  },
);

watch(
  () => currentOrderConfirmation.value,
  async (newValue) => {
    if (newValue && userIsSupervisor.value) {
      const parseMaterials = (await getParseMaterials(storageKey)) as FormStateMaterial[];
      const firstOperation = newValue.Operations[0];
      const materials = firstOperation.Materials;

      if (materials.length) {
        updatedOrderMaterials.value = materials.map((material) => {
          const findMaterial = order.value?.EX_COMPONENT?.find(
            (item) => parseInt(item.MATNR, 10) === +material.MaterialNumber,
          ) as FormStateMaterial;

          const findCheckedMaterial = parseMaterials.find((item) => {
            return parseInt(item.MATNR, 10) === +material.MaterialNumber;
          });

          return {
            ...findMaterial,
            MATNR: material.MaterialNumber,
            POSNR: material.MaterialPosnr.toString(),
            MENGE: findCheckedMaterial ? findCheckedMaterial.MENGE : material.MaterialQuantity,
            MSEHT: material.MaterialUnit,
            max: findMaterial?.MENGE,
            isUsing: findCheckedMaterial ? findCheckedMaterial.isUsing : true,
          };
        });

        await setItem(storageKey, JSON.stringify(updatedOrderMaterials.value));
      }
    }
  },
  { immediate: true },
);

const clearStorageMaterials = async () => {
  await setItem(storageKey, JSON.stringify([]));
};

onMounted(async () => {
  const parseSelectedMaterials = (await getParseMaterials(storageKey)) as FormStateMaterial[];
  updatedOrderMaterials.value = [...parseSelectedMaterials];
});

onMounted(() => window.addEventListener('beforeunload', clearStorageMaterials));
onUnmounted(() => window.removeEventListener('beforeunload', clearStorageMaterials));

const timeDuration = computed(() =>
  userIsSupervisor.value && currentOrderConfirmation.value && !order.value?.timeDuration
    ? new Date(currentOrderConfirmation.value.FinishDateWork).getTime() -
      new Date(currentOrderConfirmation.value.StartDateWork).getTime()
    : order.value?.timeDuration,
);

const onTimerBlur = (e: Event) => {
  const { value } = e.target as HTMLInputElement;

  if (order.value) {
    const orderStatus = Object.keys(OrderStatus).find((status) => {
      return order.value?.STTXT === OrderStatus[status as keyof typeof OrderStatus];
    }) as keyof typeof OrderStatus;

    updateLocalOrderStatus(
      order.value.AUFNR,
      OrderStatus[orderStatus],
      getTimeDurationValue(value),
    );
  }
};
</script>

<template>
  <div :class="styles.widget">
    <HandleRequest :no-data="!order" :error-request="ordersFetchError" :on-repeat="reloadOrders" />
    <template v-if="order">
      <OperationsList
        v-if="order?.EX_OPER_LIST.length"
        :operations="order.EX_OPER_LIST"
        :swipe-able="true"
        :on-reject="onReject"
        :with-timer="true"
        :time-begin="order.timeBegin"
        :time-duration="timeDuration"
        :show-status="!!order.STTXT"
        show-number
        is-link
        :order-confirmation="!!currentOrderConfirmation"
        :on-timer-blur="onTimerBlur"
        :key="componentOperationsKey"
        :handle-status-icon="handleStatusIcon"
      />
      <MaterialsFormList
        v-if="order?.EX_COMPONENT?.length"
        :materials="order.EX_COMPONENT"
        :selected-materials="updatedOrderMaterials"
        :key="updatedOrderMaterials.toString()"
        :on-select="handleSelectMaterial"
        :disabled="order.STTXT === OrderStatus.NOT_STARTED"
        :is-supervisor="userIsSupervisor"
      />
      <RejectionReasons
        v-if="openReasons"
        :on-close="() => handleModalReasons(false)"
        maintenance-or-repair
        :operation-id="currentOperation?.VORNR"
        :open="openReasons"
        :order-id="orderId as string"
      />
    </template>
  </div>

  <InformationDialog
    v-if="errorModalOpen"
    title="Ошибка"
    text="Для выполнения операции необходимо нажать на кнопку «Начать работу»"
    :on-close="() => handleToggleErrorModal(false)"
  />

  <InformationDialog
    v-if="errorQuantityModalOpen"
    title="Ошибка"
    :text="`Введенное значение превышает плановое количество. Плановое количество этого материала составляет ${materialCurrentValue.quantity} ${materialCurrentValue.type}`"
    :on-close="() => handleToggleQuantityErrorModal(false)"
  />
</template>
