<script setup lang="ts">
import { useRoute } from 'vue-router';

import { DefectsList, useGetDefectsListByStatus } from '@/entities/Defects';
import { HandleRequest } from '@/shared/ui';

const { sectorId } = useRoute().params;

const { myDefects, filesList, defectsFetchError, reloadFetchRequest } = useGetDefectsListByStatus({
  IM_WERKS: '',
  IM_TPLNR: sectorId as string,
  IM_EQUNR: [],
  IM_EQUNR_HISTORY: [],
  IM_QMNUM: '',
});
</script>

<template>
  <HandleRequest
    :no-data="!myDefects.length"
    :error-request="defectsFetchError"
    :on-repeat="reloadFetchRequest"
  />
  <DefectsList v-if="myDefects.length" :items="myDefects" :files-list="filesList" :is-link="true" />
</template>
