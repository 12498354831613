import type { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';

import { fetchTechnologists } from '../api/fetchTechnologists';
import { type TechnologistsRequest, type TechnologistsResponse } from '../interfaces';
import type { WorksOrderData } from '../interfaces/Teams';

export const useTechnologistStore = defineStore('technologist', () => {
  const technologist = ref<TechnologistsResponse[] | undefined>(undefined);
  const technologistFetchError = ref(false);
  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();
  const fetchTechnologistAsync = async (request: TechnologistsRequest) => {
    const tryBlock = async () => {
      technologistFetchError.value = false;
      const response: AxiosResponse<TechnologistsResponse[]> = await fetchTechnologists(request);
      if (handleResponse(response)) {
        technologist.value = response.data || [];
      } else {
        technologistFetchError.value = true;
      }
    };
    await handleLoading({ tryBlock, funcName: 'fetchTechnologistAsync' });
  };

  const setTechnologistAbsence = async (userId: string) => {
    if (!technologist.value) return;
    technologist.value = technologist.value.filter(
      (technologist) => technologist.user_id !== userId,
    );
  };

  const setTechnologistWorkOrders = async (orderId: string, userId: string) => {
    if (!technologist.value) return;

    const newUsers = [] as TechnologistsResponse[];
    const findOrder = [] as WorksOrderData[];
    technologist.value.forEach((user) => {
      const find = user.work_orders.find((work) => work.order_id === orderId);
      if (find) {
        findOrder.push({ ...find, technologist_id: userId });
      }
    });

    if (!findOrder.length) return;

    technologist.value.forEach((user) => {
      if (user.user_id !== userId) {
        user.work_orders = user.work_orders.filter((order) => order.order_id !== orderId);
      } else {
        user.work_orders.push(findOrder[0]);
      }

      newUsers.push(user);
    });
  };

  const getTechnologistByOrderId = (orderId: string) => {
    return technologist.value?.find((technologist) => {
      return technologist.work_orders.find((order) => order.order_id === orderId);
    });
  };

  return {
    technologist,
    technologistFetchError,
    technologistsOrdersIs: computed(() => {
      if (!technologist.value) return [];
      return technologist.value.flatMap((technologist) =>
        technologist.work_orders.flatMap((order) => {
          const splitOperations = order.operations.split(',');
          return splitOperations.map((operation) => [order.order_id, operation]);
        }),
      );
    }),
    fetchTechnologistAsync,
    setTechnologistAbsence,
    setTechnologistWorkOrders,
    getTechnologistByOrderId,
  };
});
