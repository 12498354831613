<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';

import { ExclamationCircleFillIcon } from '@/shared/assets/svg';
import { nDivide } from '@/shared/lib';

import styles from './InformationDialog.module.scss';

interface Props {
  title?: string;
  text: string;
  onClose: () => void;
  isButtonLink?: boolean;
}

const { title, text, onClose, isButtonLink } = defineProps<Props>();

const target = ref<HTMLElement | null>(null);

onClickOutside(target, onClose);
</script>

<template>
  <div :class="styles.overlay">
    <div ref="target" :class="styles.dialog">
      <ExclamationCircleFillIcon :class="styles.icon" />
      <a-typography-title :level="5" :class="styles.title">{{ title }}</a-typography-title>
      <div :class="styles.wrap">
        <a-typography-text :class="styles.text" v-for="item in nDivide(text)">
          {{ item }}
        </a-typography-text>
      </div>
      <div :class="styles.buttons">
        <a-button
          :type="isButtonLink ? 'link' : 'primary'"
          size="large"
          :class="isButtonLink ? styles.link : styles.close"
          @click="onClose"
          >Закрыть</a-button
        >
        <slot></slot>
      </div>
    </div>
  </div>
</template>
