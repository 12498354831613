import { ref, watchEffect } from 'vue';

import { EquipmentStatuses } from '@/shared/interfaces';

import { type EquipmentNodesByParent } from '../interfaces/Equipment';
import { useGetEquipList } from './useGetEquipList';

export const useGetEquipmentNodesByParentLines = () => {
  //Получение единиц оборудования в разбивке по родительским линиям. Линии определяем по TPLNR
  const { equipmentList, equipmentListFetchError, reloadFetchRequest, equipmentSearchValue } =
    useGetEquipList();
  const equipmentNodesByParentLines = ref<EquipmentNodesByParent | undefined>(undefined);
  watchEffect(() => {
    if (equipmentList.value) {
      equipmentList.value.EX_EQUIP_LIST.forEach((item) => {
        const equipmentIsNode =
          item.STTXU.includes(EquipmentStatuses.COST_LEVEL) ||
          item.STTXU.includes(EquipmentStatuses.MTORO_EP);
        if (equipmentIsNode) {
          if (!equipmentNodesByParentLines.value) {
            equipmentNodesByParentLines.value = {};
          }
          if (!equipmentNodesByParentLines.value[item.TPLNR]) {
            equipmentNodesByParentLines.value[item.TPLNR] = [];
          }
          equipmentNodesByParentLines.value[item.TPLNR].push(item);
        }
      });
    }
  });

  return {
    equipmentList,
    equipmentNodesByParentLines,
    equipmentListFetchError,
    reloadFetchRequest,
    equipmentSearchValue,
  };
};
