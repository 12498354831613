import { StatusLabels } from '@/shared/interfaces';

import { type Order, OrderTypesName } from '../interfaces';

export const currentOrders: Order[] = [
  {
    id: 1,
    name: 'Замена мотор-колеса',
    subtitle: 'KAMATSU 213',
    time: '30 мин.',
    startTime: '',
    type: OrderTypesName.CURRENT_REPAIR,
    status: StatusLabels.NOT_STARTED,
  },
];
