<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useGetSymptoms } from '@/entities/Defects';
import type { Equipment } from '@/entities/Equipments/interfaces/Equipment';
import { useOperationInfo } from '@/entities/Orders';
import { useGetPriorityList } from '@/entities/Priorities';
import { DefectCreateForm } from '@/features/DefectManagement';
import { NAV_OPERATIONS_SLIDER } from '@/shared/lib';
import { HandleRequest, PageHeading } from '@/shared/ui';

import styles from './DefectCreate.module.scss';

const route = useRoute();
const router = useRouter();
const { orderId, operationId } = route.params;
const {
  priorityList,
  priorityListFetchError,
  reloadFetchRequest: priorityListReload,
} = useGetPriorityList();
const { operation } = useOperationInfo(orderId as string, operationId as string);

const { symptoms, symptomsFetchError, reloadFetchRequest: symptomsReload } = useGetSymptoms();

const node = ref<Equipment | undefined>(undefined);

watchEffect(() => {
  if (operation.value) {
    node.value = {
      EQUNR: operation.value?.EQUNR || '',
      EQKTX: operation.value?.EQKTX || '',
      RBNR: operation.value?.RBNR || '',
      TPLNR: operation.value?.TPLNR || '',
    } as Equipment;
  }
});

const onReload = () => {
  if (priorityListFetchError) {
    priorityListReload();
  }

  if (symptomsFetchError) {
    symptomsReload();
  }
};

const handleBack = () => {
  router.replace({ name: NAV_OPERATIONS_SLIDER, params: { orderId, operationId } });
};
</script>

<template>
  <PageHeading text="Создать дефект" :go-back="true" :on-handle-back="handleBack" />
  <div :class="styles.page">
    <HandleRequest
      :no-data="!node || !priorityList?.EX_PRIORITY.length || !symptoms"
      :error-request="priorityListFetchError || symptomsFetchError"
      :on-repeat="onReload"
    />
    <template v-if="node && priorityList?.EX_PRIORITY.length && symptoms">
      <DefectCreateForm
        :TPLNR="operation?.TPLNR"
        :nodes="[node]"
        :priority-list="priorityList.EX_PRIORITY"
        :symptoms="symptoms.EX_TORO_SYMPTOMS"
    /></template>
  </div>
</template>
