<script setup lang="ts">
import { computed } from 'vue';
import { ChevronRightFilledIcon } from '@/shared/assets/svg';
import { EquipmentStatuses } from '@/shared/interfaces';
import {
  NAV_DEFECT_CREATE_FROM_EQUIPMENT_TO_DETAIL,
  NAV_EQUIPMENT_DETAIL,
  NAV_EQUIPMENT_LINES_CREATE_DEFECT,
} from '@/shared/lib/constants';
import type { FucLockByParentNode } from '../../interfaces';
import type { Equipment, EquipmentNodesByParent } from '../../interfaces/Equipment';
import type { FuncLock } from '../../interfaces/FuncLocks';
import { getSortedHierarchy } from '../../lib/getSortedHierarchy';
import styles from './EquipmentLinesList.module.scss';

interface Props {
  areaId: string; //Линиями являются дочерние оборудования участка и дочерние оборудования всех линей ниже по цепочке
  funcLocByParentNode: FucLockByParentNode;
  equipmentNodesByParentLines: EquipmentNodesByParent;
}

const { areaId, funcLocByParentNode, equipmentNodesByParentLines } = defineProps<Props>();

const flatLines = computed(() => {
  const getResult = (startId: string): FuncLock[] => {
    const finalResult: FuncLock[] = [];

    const processNodes = (itemId: string) => {
      const locations = funcLocByParentNode[itemId];
      // Данная проверка нужна чтобы выяснить, есть ли у участка технические места, если их нет, то техническим местом для ЕО будет участок
      if (!locations) return;

      locations.forEach(location => {
        let children: Equipment[] = [];

        // Если заполнено свойство ifGroupingEquipment то данное ТМ было добавлено из списка ЕО и является группирующей ЕО как ТМ
        if (location.ifGroupingEquipment?.EQUNR) {
          const parentChildren =
            equipmentNodesByParentLines[location.TPLMA]?.filter(
              equipment => equipment.HEQUI === location.ifGroupingEquipment?.EQUNR,
            ) || [];

          children = [...parentChildren];

          parentChildren.forEach(child => {
            const costLevelChildren =
              equipmentNodesByParentLines[location.TPLMA]?.filter(
                equipment =>
                  equipment.HEQUI === child.EQUNR &&
                  equipment.STTXU === EquipmentStatuses.COST_LEVEL,
              ) || [];

            children.push(...costLevelChildren);
          });
        } else {
          children = equipmentNodesByParentLines[location.TPLNR] || [];
        }

        finalResult.push({
          ...location,
          equipmentNodes: getSortedHierarchy(children),
        });

        if (location.TPLNR !== areaId && location.TPLNR !== location.TPLMA) {
          processNodes(location.TPLNR);
        }
      });
    };

    processNodes(startId);
    return finalResult;
  };

  return funcLocByParentNode[areaId] ? getResult(areaId) : [];
});
</script>

<template>
  <ul :class="styles.list">
    <li v-for="(item, index) in flatLines" :key="index" :class="styles.item">
      <div :class="styles.line">{{ item.PLTXT }}</div>

      <div v-for="(equipmentNode, key) in item.equipmentNodes" :key="key">
        <!-- Если статус группирующей ЕО над УПЛН то выделяем как ТМ -->
        <div v-if="equipmentNode.STTXU === EquipmentStatuses.MTORO_EP" :class="styles.line">
          {{ equipmentNode.EQKTX }}
        </div>
        <router-link
          v-else
          :class="styles.link"
          :to="{
            name:
              $route.name === NAV_EQUIPMENT_LINES_CREATE_DEFECT
                ? NAV_DEFECT_CREATE_FROM_EQUIPMENT_TO_DETAIL
                : NAV_EQUIPMENT_DETAIL,
            params: { equipmentId: equipmentNode.EQUNR },
          }"
        >
          <a-typography-text :class="styles.text">{{ equipmentNode.EQKTX }}</a-typography-text>
          <ChevronRightFilledIcon :class="styles.arrow" />
        </router-link>
      </div>
    </li>
  </ul>
</template>
