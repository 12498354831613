<script setup lang="ts">
import { computed } from 'vue';

import { EXTRA_DELIMITER, toRuDate } from '@/shared/lib';
import { getDateWithTimeInUserTimeZone } from '@/shared/lib/dateUtils';

import { type OrderComment } from '../../interfaces/Orders';

interface Props {
  comments: OrderComment[];
}

const { comments } = defineProps<Props>();

const commentsGroupedByDate = computed(() => {
  const groupedComments: Record<string, OrderComment[]> = {}; // { '2022-01-01': [comment1, comment2], '2022-01-02': [comment3] }

  comments.forEach((comment) => {
    const date = comment.IEDD.slice(0, 10);
    if (!groupedComments[date]) {
      groupedComments[date] = [];
    }
    groupedComments[date].push(comment);
  });

  return groupedComments;
});

const getCommentText = (comment: OrderComment) => {
  if (!comment.LTXA1.includes(EXTRA_DELIMITER)) {
    return {
      name: '',
      text: comment.LTXA1,
    };
  } else {
    const [name, text] = comment.LTXA1.split(EXTRA_DELIMITER);
    return {
      name,
      text,
    };
  }
};
</script>

<template>
  <ul :class="$style.dates">
    <li v-for="date in Object.keys(commentsGroupedByDate)" :key="date">
      <a-typography-text :class="$style.date">{{ toRuDate(date) }}</a-typography-text>
      <ul :class="$style.list">
        <li
          v-for="comment in commentsGroupedByDate[date]"
          :key="comment.AUFNR + comment.VORNR"
          :class="$style.item"
        >
          <a-typography-text :class="$style.name">
            {{ getCommentText(comment).name }}
          </a-typography-text>
          <div :class="$style.wrap">
            <a-typography-text :class="$style.text">
              {{ getCommentText(comment).text }}
            </a-typography-text>
            <a-typography-text :class="$style.time">
              {{ getDateWithTimeInUserTimeZone(`${comment.IEDD} ${comment.IEDZ}`, 'HH:mm') }}
            </a-typography-text>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</template>

<style lang="scss" module>
.dates {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.date {
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  color: var(--color-white, #fff);
  font-size: 12px;
  font-weight: 600;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  width: max-content;
  margin: 0 auto 16px;
}
.list {
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 8px 12px;
  background-color: var(--color-white, #fff);
  position: relative;
  text-decoration: none;

  &:after {
    content: '';
    position: absolute;
    width: calc(100% - 16px);
    height: 1px;
    right: 0;
    top: 0;
    background-color: var(--colorBorder, rgba(0, 0, 0, 0.15));
  }
}

.item:first-child {
  border-radius: 6px 6px 0 0;
  &:after {
    display: none;
  }
}

.item:last-child {
  border-radius: 0 0 6px 6px;
}

.name {
  color: var(--colorPrimary, #ef7f1a);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
}
.wrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 8px;
}
.wrap:empty {
  display: none;
}
.text {
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
}
.time {
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
  font-size: 11px;
  line-height: 140%;
  white-space: nowrap;
}
</style>
