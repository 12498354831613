import type { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import type { Alert } from '@/shared/interfaces';

import type { ExErrorResponse } from '../interfaces';
import type { AlertErrorResponse } from '../interfaces/AlertErrorResponse';

export const useAlertStore = defineStore('alert', () => {
  const alert = ref<Alert>();

  const clearAlert = () => {
    alert.value = undefined;
  };

  const setAlert = ({ type, message }: Alert) => {
    alert.value = {
      type,
      message,
    };
    setTimeout(clearAlert, 5000);
  };

  const handleResponse = (
    response: AxiosResponse<unknown, unknown> & AlertErrorResponse,
    successMessage?: string,
  ) => {
    const responseOk = response.status === 200 || response.status === 201;
    if (!responseOk) {
      setAlert({
        type: 'error',
        message:
          (response.data as AlertErrorResponse)?.description ||
          `Произошла ошибка в запросе ${response.config.url} `,
      });
      return false;
    } else if (responseOk && (response.data as AlertErrorResponse)?.EX_ERROR?.length) {
      (response.data as AlertErrorResponse).EX_ERROR?.forEach((error: ExErrorResponse) => {
        setAlert({ type: error.RETCODE === 'I' ? 'success' : 'error', message: error.ERROR_TEXT });
      });
    } else if (responseOk && successMessage) {
      setAlert({ type: 'success', message: successMessage });
    }
    return true;
  };

  return {
    alert,
    clearAlert,
    setAlert,
    handleResponse,
  };
});
