<script setup lang="ts">
import { onClickOutside, useSwipe } from '@vueuse/core';
import { computed, h, onMounted, ref } from 'vue';

import { DismissFilledIcon } from '@/shared/assets/svg';

import styles from './SwipeModal.module.scss';

interface Props {
  onShowModal: (show: boolean) => void;
  isShowModal: boolean;
  title?: string;
}

const { onShowModal, isShowModal, title } = defineProps<Props>();

const hideModalActions = () => {
  hideModal();
  onShowModal(false);
  document.documentElement.style.overflow = '';
};

const target = ref<HTMLElement | null>(null);
const bodyHeight = computed(() => document.body.offsetHeight);

const bottom = ref('-100%');
const opacity = ref(0);

const showModal = () => {
  bottom.value = '0';
  opacity.value = 1;
};

const hideModal = () => {
  bottom.value = '-100%';
  opacity.value = 0;
};

onClickOutside(target, hideModalActions);

const { isSwiping, lengthY } = useSwipe(target, {
  passive: true,
  threshold: 0,
  onSwipe() {
    if (bodyHeight.value) {
      if (lengthY.value < 0) {
        const length = Math.abs(lengthY.value);
        bottom.value = `-${length}px`;
        opacity.value = 1.1 - length / bodyHeight.value;
      } else {
        showModal();
      }
    }
  },
  onSwipeEnd() {
    if (
      lengthY.value < 0 &&
      bodyHeight.value &&
      Math.abs(lengthY.value) / bodyHeight.value >= 0.3
    ) {
      hideModalActions();
    } else {
      showModal();
    }
  },
});

onMounted(() => {
  isShowModal && showModal();
  document.documentElement.style.overflow = 'hidden';
});
</script>

<template>
  <div :class="styles.wrapper" ref="ref">
    <div
      :class="{ [styles.modal]: true, [styles.animated]: !isSwiping }"
      ref="target"
      :style="{ bottom, opacity }"
    >
      <div v-if="title" :class="styles.header">
        <a-typography-title :level="4" :class="styles.title">
          {{ title }}
        </a-typography-title>
        <a-button
          :class="styles.close"
          type="text"
          :icon="h(DismissFilledIcon)"
          @click="hideModalActions"
        />
      </div>
      <div v-else :class="styles.swipe" />
      <slot></slot>
    </div>
  </div>
</template>
