import type { AxiosResponse } from 'axios';
import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';

import { useTeamsStore, useTechnologistStore } from '@/entities/Users';
import type { WorksOrderUserData } from '@/entities/Users/interfaces';
import { useUserProfileStore } from '@/shared/model';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';

import { fetchOrderAssignee } from '../api/fetchOrderAssignee';
import { fetchWorkOrdersDelete } from '../api/fetchWorkOrdersDelete';

export const useOrderAssigneeStore = defineStore('orderAssignee', () => {
  const { handleResponse, setAlert } = useAlertStore();
  const { handleLoading } = useLoadingStore();
  const { setTeamWorkOrders } = useTeamsStore();
  const { setTechnologistWorkOrders } = useTechnologistStore();
  const userProfileStore = useUserProfileStore();
  const { userIsRepairSupervisor, userIsTechnologSupervisor } = storeToRefs(userProfileStore);
  const workOrdersDeleteError = ref(false);

  const workOrdersDeleteAsync = async (appointmentId: string) => {
    const tryBlock = async () => {
      const response: AxiosResponse = await fetchWorkOrdersDelete(appointmentId);

      if (response.status !== 200) {
        setAlert({
          type: 'error',
          message: 'Не удалось переназначить бригаду на заказ',
        });
        workOrdersDeleteError.value = true;
      } else {
        workOrdersDeleteError.value = false;
      }
    };
    await handleLoading({ tryBlock, funcName: 'workOrdersDeleteAsync' });
  };

  const orderAssigneeAsync = async (
    input: WorksOrderUserData,
    successTextResponse?: string,
    itemId?: string,
  ) => {
    const tryBlock = async () => {
      const response = await fetchOrderAssignee(input).catch(() => ({
        //если приложение не подключено к интернету то показываем уведомление об успешной отправке
        status: window.navigator.onLine ? 0 : 200,
        data: {},
      }));

      successTextResponse && handleResponse(response as AxiosResponse, successTextResponse);

      if (itemId) {
        userIsRepairSupervisor.value && setTeamWorkOrders(input.order_id, itemId);
        userIsTechnologSupervisor.value && setTechnologistWorkOrders(input.order_id, itemId);
      }
    };
    await handleLoading({ tryBlock, funcName: 'orderAssigneeAsync' });
  };

  return { workOrdersDeleteAsync, workOrdersDeleteError, orderAssigneeAsync };
});
