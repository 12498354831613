import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';

import { usePriorityListStore } from '../model/usePriorityListStore';

export const useGetPriorityList = () => {
  const priorityListStore = usePriorityListStore();

  const { priorityList, priorityListFetchError } = storeToRefs(priorityListStore);

  const reloadFetchRequest = () => {
    priorityListStore.fetchPriorityListAsync();
  };

  onMounted(() => {
    if (priorityList.value) return;
    reloadFetchRequest();
  });
  return { priorityList, priorityListFetchError, reloadFetchRequest };
};
