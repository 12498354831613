import { formatDate } from '@vueuse/core';
import { differenceInHours, format, intervalToDuration } from 'date-fns';

import { declOfNum } from '@/shared/lib';

const parseDate = (date: string | Date) => (date instanceof Date ? date : new Date(date));

export const toRuDate = (date: string | Date) => formatDate(parseDate(date), 'DD.MM.YYYY');

export const diffDateWithCurrent = (date: string) => {
  if (!date) return;

  const currentDate = new Date();
  const planCompletionDate = new Date(date);
  const currentTime = currentDate.getTime();
  const planCompletionDateTime = planCompletionDate.getTime();
  const diffDays = Math.trunc((currentTime - planCompletionDateTime) / 86400000);

  return diffDays <= 0 ? false : diffDays;
};

export const getDelayText = (date: string) => {
  const countDate = diffDateWithCurrent(date);
  return countDate
    ? `Просрочена ${countDate} ${declOfNum(countDate, ['день', 'дня', 'дней'])}`
    : '';
};

export const getCorrectDate = (date: string) => {
  if (!date) return;
  const dateArray = date.split('-');

  if (dateArray.length === 1) return date;

  return `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`;
};

export const getHoursAndMinutes = (time: string) => {
  const timeArray = time.split(':');

  if (timeArray.length === 2) return time;

  return `${timeArray[0]}:${timeArray[1]}`;
};

export const getDateWithTimeInUserTimeZone = (
  dateString: string = '',
  formatType = 'DD.MM.YYYY HH:mm',
  returnDate = false,
) => {
  const dateStringWithMilliSeconds = dateString.split('.')[1] ? dateString : `${dateString}.000`;
  const dateInTimeZone = new Date(`${dateStringWithMilliSeconds}0Z`); //Добавляем 0Z в конец чтобы получить дату в таймзоне юзера, так как с бэка приходит дата в формате UTC0
  return returnDate ? dateInTimeZone : formatDate(dateInTimeZone, formatType);
};

export const getHoursBetween = (date1: string | Date, date2: string | Date) => {
  return differenceInHours(parseDate(date1), parseDate(date2));
};
export const differenceInHoursAndMinutes = (date1: string | Date, date2: string | Date) => {
  const between = intervalToDuration({
    start: parseDate(date2),
    end: parseDate(date1),
  });

  let result = '';

  between.months && (result += `${between.months} мес \n`);
  between.days && (result += `${between.days} дн \n`);
  between.hours && (result += `${between.hours} ч \n`);
  between.minutes && (result += `${between.minutes} мин `);
  between.seconds && !between.minutes && (result += `${between.seconds} сек`);

  if (!Object.keys(between).length) {
    result = '0 мин';
  }

  return result;
};

export const adjustForUTCOffset = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};

export const formatDateToUTC = (dateString: string, formatType: string = 'HH:mm:ss') => {
  const dateWithOffset = adjustForUTCOffset(new Date(dateString));

  return format(dateWithOffset, formatType);
};

export const getCurrentUTC0Date = (formatType: string = 'yyyy-MM-dd') => {
  const currentDateString = new Date().toString();
  return formatDateToUTC(currentDateString, formatType);
};

export const getCurrentUTC0Time = () => {
  const currentDateString = new Date().toString();
  return formatDateToUTC(currentDateString);
};
