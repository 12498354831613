import { storeToRefs } from 'pinia';
import { ref, watchEffect } from 'vue';

import type { DefectsListInput, FileItem } from '@/shared/interfaces';
import { getAttachmentIsImage, getAttachmentIsVideo } from '@/shared/lib';

import type { DefectsListData } from '../interfaces';
import { useDefectsListStore } from '../model/useDefectsListStore';

export const useGetOrderDefectInfo = (input: DefectsListInput, defectId: string) => {
  const defectsListStore = useDefectsListStore();
  const { ordersDefectsList, ordersDefectsFetchError } = storeToRefs(defectsListStore);
  const filesList = ref<FileItem[]>([]);
  const defectInfo = ref<DefectsListData | undefined>(undefined);

  watchEffect(() => {
    if (ordersDefectsList.value) {
      defectInfo.value = ordersDefectsList.value?.EX_DEFECT_LIST.find(
        (defect) => defect.QMNUM === defectId,
      );
      ordersDefectsList.value?.EX_FILES_LIST.forEach((file) => {
        if (getAttachmentIsImage(file.FILE_EXT) || getAttachmentIsVideo(file.FILE_EXT)) {
          const findCurrentFile = filesList.value.find(
            (fileList) => fileList.FILE_ID === file.FILE_ID,
          );

          if (!findCurrentFile) {
            if ((defectId && file.QMNUM && defectId.includes(file.QMNUM)) || !defectId) {
              filesList.value = [...filesList.value, file];
            }
          }
        }
      });
    }
  });

  const reloadFetchRequest = () => {
    defectsListStore.fetchDefectsListAsync(input);
  };

  return { defectInfo, filesList, defectsFetchError: ordersDefectsFetchError, reloadFetchRequest };
};
