<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { NAV_DASHBOARD } from '@/shared/lib';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { DrawerCustomize } from '@/shared/ui';

import styles from './ChangeShift.module.scss';

interface Props {
  onModal: (status: boolean) => void;
  open: boolean;
  onSubmitChangeShift: (comment: string) => void;
}

const router = useRouter();
const { setAlert } = useAlertStore();

const { onModal, open, onSubmitChangeShift } = defineProps<Props>();

const value = ref<string>('');
const onSubmit = async () => {
  onModal(false);
  await onSubmitChangeShift(value.value);
  value.value = '';

  router.push(NAV_DASHBOARD);
  setAlert({ type: 'success', message: 'Заказ передан по смене' });
};
</script>

<template>
  <DrawerCustomize :title="'Опишите выполненные работы'" :open="open" @close="onModal(false)">
    <a-textarea v-model:value="value" show-count :bordered="false" maxlength="150" />
    <div :class="styles.buttons">
      <a-button
        type="primary"
        size="large"
        :class="styles.button"
        @click="onSubmit"
        :disabled="value.trim().length < 5"
        >Отправить</a-button
      >
    </div>
  </DrawerCustomize>
</template>
