import { defineStore } from 'pinia';
import { ref } from 'vue';

import type { CreatedDefects, CreatedDefectsTypes } from '../interfaces';

export const useCreatedDefectsStore = defineStore('createdDefects', () => {
  const createdDefects = ref<CreatedDefects>({
    eliminated: 0,
    new: 0,
  });
  const addCreatedDefect = (type: CreatedDefectsTypes) => {
    createdDefects.value = {
      ...createdDefects.value,
      [type]: createdDefects.value?.[type] ? createdDefects.value[type] + 1 : 1,
    };
  };
  return {
    createdDefects,
    addCreatedDefect,
  };
});
