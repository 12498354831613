import { storeToRefs } from 'pinia';
import { ref, watchEffect } from 'vue';

import type { DefectsListInput } from '@/shared/interfaces';
import { useUserProfileStore } from '@/shared/model';

import { type DefectsListData, DefectStatuses } from '../interfaces';
import { useGetDefectsList } from './useGetDefectsList';

export const useGetDefectsListByStatus = (input: DefectsListInput) => {
  const { defectsList, filesList, defectsFetchError, reloadFetchRequest } =
    useGetDefectsList(input);
  const { profile } = storeToRefs(useUserProfileStore());

  const completedDefects = ref<DefectsListData[]>([]);
  const inProgressDefects = ref<DefectsListData[]>([]);
  const myDefects = ref<DefectsListData[]>([]);

  watchEffect(() => {
    if (defectsList.value) {
      completedDefects.value = [];
      inProgressDefects.value = [];
      myDefects.value = [];
      defectsList.value.EX_DEFECT_LIST?.forEach((defect) => {
        if (defect.STAT.includes(DefectStatuses.COMPLETED)) {
          completedDefects.value.push(defect);
        } else if (defect.STAT.includes(DefectStatuses.IN_PROGRESS)) {
          inProgressDefects.value.push(defect);
        }

        const isMyDefect = profile.value.personnel_number === defect.QMNAM;
        const statusesForMyDefects = [
          DefectStatuses.COMPLETED,
          DefectStatuses.IN_PROGRESS,
          DefectStatuses.NEW,
        ];
        if (isMyDefect && statusesForMyDefects.includes(defect.STAT)) {
          myDefects.value.push(defect);
        }
      });
    }
  });

  return {
    defectsList,
    completedDefects,
    inProgressDefects,
    myDefects,
    filesList,
    defectsFetchError,
    reloadFetchRequest,
  };
};
