import { httpBackendClient } from '@/shared/api';
import { API_ORDER_CONFIRMATIONS_URL } from '@/shared/lib';

import type { OrderConfirmationsRequest } from '../interfaces';

export const getOrderConfirmations = async (request: OrderConfirmationsRequest[]) => {
  return await httpBackendClient.get(
    API_ORDER_CONFIRMATIONS_URL.replace(':orders', JSON.stringify(request)),
  );
};
