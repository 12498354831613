<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { MaterialsList } from '@/entities/Materials';
import { OperationsList, TmTrInfo, useGetOrders, useGetOrdersHistoryInfo } from '@/entities/Orders';
import { HandleRequest, PageHeading } from '@/shared/ui';

const { orderId } = useRoute().params;

const { orders, ordersFetchError, reloadOrders } = useGetOrders();
const { ordersHistoryInfo, ordersHistory, ordersHistoryFetchError, reloadFetchRequest } =
  useGetOrdersHistoryInfo(orderId as string);

const headingTitle = computed(() => {
  return ordersHistoryInfo.value?.EX_HISTORY_ORDER?.KTEXT || '';
});

const onReload = () => {
  if (ordersFetchError) {
    reloadOrders();
  }

  if (ordersHistoryFetchError) {
    reloadFetchRequest();
  }
};
</script>

<template>
  <PageHeading :text="headingTitle" :go-back="true" />
  <HandleRequest
    :no-data="!ordersHistory || !orders"
    :error-request="ordersHistoryFetchError || ordersFetchError"
    :on-repeat="onReload"
  />
  <template v-if="ordersHistoryInfo && orders">
    <TmTrInfo :order="ordersHistoryInfo" />

    <div :class="$style.wrap">
      <div>
        <a-typography-title :level="4" :class="$style.title">Операции</a-typography-title>
        <OperationsList
          v-if="ordersHistoryInfo?.EX_OPER_LIST"
          :operations="ordersHistoryInfo.EX_OPER_LIST"
          show-number
          :with-title="false"
          :swipe-able="false"
        />
      </div>
    </div>
    <MaterialsList
      :materials="ordersHistoryInfo.EX_COMPONENT"
      v-if="ordersHistoryInfo?.EX_COMPONENT?.length"
    />
  </template>
</template>

<style lang="scss" module>
.wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
  .title {
    margin: 16px 12px 0;
  }
}
</style>
