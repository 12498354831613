import { ref, watchEffect } from 'vue';

import { useGetOrders } from '@/entities/Orders';

export const useGetDefectsInspection = (orderId?: string) => {
  // Todo рефакторинг (разбить на 2 и переименовать)
  const { orders } = useGetOrders();
  const equipmentIds = ref<string[][]>([]);
  const searchEquipmentId = (EQUNR: string) => {
    return equipmentIds.value?.find((equipmentId) => equipmentId[0] === EQUNR);
  };

  watchEffect(() => {
    if (orders.value) {
      orders.value?.EX_HEADERS.forEach((order) => {
        if (
          order.EQUNR &&
          !searchEquipmentId(order.EQUNR) &&
          (orderId ? orderId === order.AUFNR : true)
        ) {
          equipmentIds.value.push([order.EQUNR]);
        }
      });

      orders.value?.EX_OPER_LIST.forEach((order) => {
        if (
          order.EQUNR &&
          !searchEquipmentId(order.EQUNR) &&
          (orderId ? orderId === order.AUFNR : true)
        ) {
          order.EQUNR && equipmentIds.value.push([order.EQUNR]);
        }
      });
    }
  });

  return { equipmentIds };
};
