import { defineStore, storeToRefs } from 'pinia';
import { ref, watchEffect } from 'vue';

export const useLoadingStore = defineStore('loading', () => {
  const initialDataLoading = ref<string[]>([]); //Выводим прелоадер с фоном при загрузке первоначальных данных (авторизация, схема...)
  const loading = ref<string[]>([]); //Выводим прозрачный прелоадер при подргузке данных в компоненты
  const silentLoading = ref<string[]>([]);

  const handleLoading = async ({
    tryBlock,
    funcName,
    initialData,
    noGlobalLoader = false,
  }: {
    tryBlock: () => Promise<void>;
    funcName: string;
    initialData?: boolean;
    noGlobalLoader?: boolean;
  }) => {
    const loadingArray = initialData ? initialDataLoading : loading;
    if (!noGlobalLoader) loadingArray.value.push(funcName);
    else silentLoading.value.push(funcName);
    try {
      await tryBlock();
    } catch (e) {
    } finally {
      if (!noGlobalLoader) {
        loadingArray.value = loadingArray.value.filter((item) => item !== funcName);
      } else {
        silentLoading.value = silentLoading.value.filter((item) => item !== funcName);
      }
    }
  };

  return {
    loading,
    silentLoading,
    initialDataLoading,
    handleLoading,
  };
});

export const useShowSilentLoading = (funcName: string) => {
  const { silentLoading, loading } = storeToRefs(useLoadingStore());

  watchEffect(() => {
    if (silentLoading.value.includes(funcName)) {
      loading.value = [...new Set([...loading.value, funcName])];
    } else if (loading.value.includes(funcName)) {
      loading.value = loading.value.filter((item) => item !== funcName);
    }
  });
};
