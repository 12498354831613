<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { NoOrders, OrdersList, OrderTypesName, useGetOrderByType } from '@/entities/Orders';
import { RejectionReasons } from '@/features/RejectionReasons';
import { NAV_REASSIGNMENT } from '@/shared/lib/constants';
import { useUserProfileStore } from '@/shared/model';
import { HandleRequest } from '@/shared/ui';
import { BrigadesOrdersWidget } from '@/widgets/BrigadesOrdersWidget';
import { InfoWidget } from '@/widgets/InfoWidget';
import { OrdersByTeamsWidget } from '@/widgets/OrdersByTeamsWidget';
import { TechnologistOrdersWidget } from '@/widgets/TechnologistOrdersWidget';

import styles from './Dashboard.module.scss';

const currentOrderId = ref<string>('');
const maintenanceOrRepair = ref<boolean>(false);

const { ordersByType, ordersFetchError, reloadOrders } = useGetOrderByType();

const {
  userIsRepairer,
  userIsSupervisor,
  userIsEmployee,
  userIsRepairSupervisor,
  userIsTechnologSupervisor,
} = storeToRefs(useUserProfileStore());
const router = useRouter();
const openReasons = ref<boolean>(false);
const handleModalReasons = (value: boolean) => {
  openReasons.value = value;
};
const onReject = (itemId: number, orderRequest?: boolean) => {
  currentOrderId.value = itemId.toString();
  maintenanceOrRepair.value = orderRequest ? true : false;

  handleModalReasons(true);
};

const onReassign = (orderId: number) => {
  router.push({ name: NAV_REASSIGNMENT, params: { orderId } });
};
</script>

<template>
  <div :class="styles.home">
    <HandleRequest
      :no-data="false"
      :error-request="ordersFetchError"
      :on-repeat="reloadOrders"
      :class-name="styles.errors"
      :no-fixed="true"
      v-if="!userIsSupervisor"
    />
    <BrigadesOrdersWidget v-if="userIsRepairSupervisor" />
    <TechnologistOrdersWidget v-if="userIsTechnologSupervisor" />
    <InfoWidget v-if="!userIsSupervisor" />

    <NoOrders
      v-if="(!ordersFetchError && !ordersByType?.[OrderTypesName.ALL].length) || userIsEmployee"
    />
    <OrdersByTeamsWidget v-if="userIsSupervisor" />

    <template
      v-if="
        !ordersFetchError &&
        ordersByType?.[OrderTypesName.ALL].length &&
        !userIsSupervisor &&
        !userIsEmployee
      "
    >
      <template v-if="Boolean(ordersByType?.[OrderTypesName.INSPECTION].length)">
        <a-typography-title v-if="!userIsSupervisor" :level="4" :class="styles.title"
          >Осмотры</a-typography-title
        >
        <OrdersList
          :is-link="true"
          :orders="ordersByType?.[OrderTypesName.INSPECTION]"
          :swipe-able="true"
          :on-reject="onReject"
          :on-reassign="userIsSupervisor ? onReassign : undefined"
        />
      </template>
      <template v-if="userIsRepairer">
        <div v-if="Boolean(ordersByType?.[OrderTypesName.MAINTENANCE].length)">
          <a-typography-title :level="4" :class="styles.title"
            >Техническое обслуживание</a-typography-title
          >
          <OrdersList
            :is-link="true"
            :orders="ordersByType?.[OrderTypesName.MAINTENANCE]"
            :swipe-able="true"
            :on-reject="(itemId: number) => onReject(itemId, true)"
          />
        </div>
        <div v-if="Boolean(ordersByType?.[OrderTypesName.CURRENT_REPAIR].length)">
          <a-typography-title :level="4" :class="styles.title">Текущие ремонты</a-typography-title>
          <OrdersList
            :is-link="true"
            :orders="ordersByType?.[OrderTypesName.CURRENT_REPAIR]"
            :swipe-able="true"
            :on-reject="(itemId: number) => onReject(itemId, true)"
          />
        </div>
      </template>
    </template>
  </div>
  <RejectionReasons
    v-if="openReasons"
    :on-close="() => handleModalReasons(false)"
    :open="openReasons"
    :order-id="currentOrderId"
    :maintenance-or-repair="maintenanceOrRepair"
  />
</template>
