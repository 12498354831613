<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';

import { getTimerValue } from '@/shared/lib';

import styles from './Timer.module.scss';

interface Props {
  timeBegin: string;
}

const { timeBegin } = defineProps<Props>();

let intervalId = 0 as number;
const currentTime = ref('');
onMounted(() => {
  intervalId = window.setInterval(() => {
    currentTime.value = timeBegin ? getTimerValue(timeBegin) : '';
  }, 1000);
});
onUnmounted(() => {
  window.clearInterval(intervalId);
});
</script>

<template>
  <a-typography-text v-if="timeBegin" :class="styles.text">{{ currentTime }}</a-typography-text>
</template>
