<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useGetSymptoms } from '@/entities/Defects';
import { useGetEquipList } from '@/entities/Equipments';
import { useGetEquipmentByParentNodes } from '@/entities/Equipments/hooks/useGetEquipmentByParentNodes';
import { getEquipmentChildAndChildrenChild } from '@/entities/Equipments/lib';
import { useGetPriorityList } from '@/entities/Priorities';
import { DefectCreateForm, useDefectManagementStore } from '@/features/DefectManagement';
import { HistoryIcon } from '@/shared/assets/svg';
import { EquipmentStatuses } from '@/shared/interfaces';
import { NAV_DEFECTS_HISTORY, nfcStaticPages } from '@/shared/lib/constants';
import { HandleRequest, InformationDialog, PageHeading } from '@/shared/ui';

import styles from './DefectCreate.module.scss';

const route = useRoute();
const router = useRouter();
const { equipmentId } = route.params;

const unitId = ref<string>();

const {
  priorityList,
  priorityListFetchError,
  reloadFetchRequest: priorityListReload,
} = useGetPriorityList();
const { symptoms, symptomsFetchError, reloadFetchRequest: symptomsReload } = useGetSymptoms();
const createDefectStore = useDefectManagementStore();

const {
  equipmentList,
  equipmentListFetchError,
  reloadFetchRequest: equipmentReload,
} = useGetEquipList();

const { equipmentsNodesByParentNodes, currentNode } = useGetEquipmentByParentNodes(
  equipmentId as string,
);

const nodes = computed(() => {
  const finalArr = [
    ...getEquipmentChildAndChildrenChild(equipmentsNodesByParentNodes.value, equipmentId as string),
  ];
  if (currentNode.value && currentNode.value.STTXU.includes(EquipmentStatuses.DEFECTS_LEVEL)) {
    //Если оборудования считается узлом то добавляем его в селект первым
    finalArr.unshift(currentNode.value);
  }
  return finalArr;
});

const onReload = () => {
  if (priorityListFetchError) {
    priorityListReload();
  }

  if (symptomsFetchError) {
    symptomsReload();
  }

  if (equipmentListFetchError) {
    equipmentReload();
  }
};

const handleUnits = (unit: string) => {
  unitId.value = unit;
};

onBeforeMount(() => {
  const prevRoute = (router.options.history.state.back || '').toString();
  const nextRoute = (router.options.history.state.forward || '').toString();

  if (nfcStaticPages.includes(prevRoute) && !nextRoute.startsWith(NAV_DEFECTS_HISTORY)) {
    createDefectStore.clearCreateDefectValues();
  }
});
</script>

<template>
  <PageHeading text="Создать дефект" :go-back="true">
    <router-link
      v-if="equipmentId && unitId"
      :class="styles.link"
      :to="{
        name: NAV_DEFECTS_HISTORY,
        params: { unitId },
      }"
      ><HistoryIcon />
    </router-link>
  </PageHeading>
  <div :class="styles.page">
    <HandleRequest
      :no-data="
        !equipmentList?.EX_EQUIP_LIST ||
        !priorityList?.EX_PRIORITY.length ||
        !symptoms?.EX_TORO_SYMPTOMS?.length
      "
      :error-request="priorityListFetchError || symptomsFetchError || equipmentListFetchError"
      :on-repeat="onReload"
    />
    <template
      v-if="
        equipmentList?.EX_EQUIP_LIST &&
        priorityList?.EX_PRIORITY.length &&
        symptoms?.EX_TORO_SYMPTOMS
      "
    >
      <InformationDialog
        v-if="!nodes.length"
        text="Создание дефекта для этого оборудования невозможно"
        :on-close="() => $router.go(-1)"
      />
      <DefectCreateForm
        :nodes="nodes"
        :priority-list="priorityList.EX_PRIORITY"
        :symptoms="symptoms.EX_TORO_SYMPTOMS"
        :on-units="handleUnits"
      />
    </template>
  </div>
</template>
