import { type PageTab } from '@/shared/interfaces';
import { EquipmentCharacteristicsWidget } from '@/widgets/EquipmentCharacteristicsWidget';
import { EquipmentMeasuringPointsWidget } from '@/widgets/EquipmentMeasuringPointsWidget';
import { EquipmentsDefectsWidget } from '@/widgets/EquipmentsDefectsWidget';

export const nodeTabs: PageTab[] = [
  {
    name: 'Характеристики',
    component: EquipmentCharacteristicsWidget,
    id: 1,
  },
  {
    name: 'Дефекты',
    component: EquipmentsDefectsWidget,
    id: 2,
  },
];

export const tabs: PageTab[] = [
  ...nodeTabs,
  {
    name: 'Точки измерений',
    component: EquipmentMeasuringPointsWidget,
    id: 3,
  },
];
