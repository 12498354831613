import { defineStore } from 'pinia';
import { ref } from 'vue';

import { getUserInformation } from '@/shared/api';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';

import { getOrdersHistoryByEquipmentIds } from '../api/getOrdersHistoryByEquipmentIds';
import type { OrdersHistoryResponse } from '../interfaces';
import { mapUserInfoWithHistory } from '../lib/mapUserInfoWithHistory';

export const useOrdersHistoryStore = defineStore('ordersHistory', () => {
  const ordersHistory = ref<OrdersHistoryResponse | undefined>(undefined);
  const ordersHistoryFetchError = ref(false);
  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();

  const fetchOrdersHistoryByEquipmentIdsAsync = async (equipmentIds: string[], silent = false) => {
    const tryBlock = async () => {
      ordersHistoryFetchError.value = false;
      const response = await getOrdersHistoryByEquipmentIds(equipmentIds);
      if (handleResponse(response)) {
        const data = response.data as OrdersHistoryResponse;
        const tabNumbers = data?.EX_HISTORY_ORDER.map((item) => item.TABN) || [];
        if (tabNumbers.length > 0) {
          const informationResponse = await getUserInformation(tabNumbers);
          if (handleResponse(informationResponse)) {
            const dataWithInfo = mapUserInfoWithHistory(
              data?.EX_HISTORY_ORDER,
              informationResponse.data,
            );
            ordersHistory.value = { ...data, EX_HISTORY_ORDER: dataWithInfo };
          } else {
            ordersHistory.value = data;
          }
        } else {
          ordersHistoryFetchError.value = true;
        }
      } else {
        ordersHistoryFetchError.value = true;
      }
    };
    await handleLoading({ tryBlock, funcName: 'fetchOrdersAsync', noGlobalLoader: silent });
  };

  return {
    ordersHistory,
    ordersHistoryFetchError,
    fetchOrdersHistoryByEquipmentIdsAsync,
  };
});
