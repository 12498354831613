import { httpFmpClient } from '@/shared/api';
import { API_CREATE_WORKORDER } from '@/shared/lib';

import type { OrderCreateFormData } from '../interfaces';

export const createWorkOrder = ({ IT_HEADER, IT_OPERATION, IT_COMPONENTS }: OrderCreateFormData) =>
  httpFmpClient.post(`/rpc/${API_CREATE_WORKORDER}/`, {
    IT_HEADER,
    IT_OPERATION,
    IT_COMPONENTS,
  });
