<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

import { getTimerValue } from '@/shared/lib';
import { NAV_OPERATION_DETAIL } from '@/shared/lib/constants';
import { useUserProfileStore } from '@/shared/model';
import { Timer } from '@/shared/ui';

import { type OrdersOperList } from '../../interfaces/Orders';
import OperationItem from '../OperationItem/OperationItem.vue';
import styles from './OperationsList.module.scss';

interface Props {
  operations: OrdersOperList[];
  withTimer?: boolean;
  swipeAble?: boolean;
  onReject?: (id: number) => void;
  timeBegin?: string | number;
  timeDuration?: number;
  withTitle?: boolean;
  url?: string;
  urlTag?: boolean;
  showStatus?: boolean;
  showNumber?: boolean;
  isLink?: boolean;
  orderConfirmation?: boolean;
  onTimerBlur?: (e: Event) => void;
  handleStatusIcon?: (e: Event, operationId: string) => void;
  isInspectionOrder?: boolean;
}

const {
  operations,
  withTimer,
  swipeAble,
  onReject,
  timeBegin,
  timeDuration,
  withTitle,
  url,
  urlTag,
  showStatus,
  showNumber,
  isLink,
  orderConfirmation,
  onTimerBlur,
  handleStatusIcon,
  isInspectionOrder,
} = withDefaults(defineProps<Props>(), {
  withTimer: false,
  swipeAble: false,
  withTitle: true,
  url: NAV_OPERATION_DETAIL,
  withoutLine: false,
});

const userProfileStore = useUserProfileStore();
const { userIsSupervisor } = storeToRefs(userProfileStore);

const timeValue = ref(timeDuration);
const timeDurationValue = ref(getTimerValue(timeValue.value || 0, false));
</script>

<template>
  <div :class="styles.operations">
    <div :class="styles.heading">
      <a-typography-title v-if="withTitle" :level="4" :class="styles.title"
        >Операции</a-typography-title
      >
      <Timer v-if="withTimer && !!timeBegin && !timeDuration" :time-begin="timeBegin.toString()" />
      <div
        :class="styles.time"
        v-if="userIsSupervisor && orderConfirmation && timeDuration !== undefined"
      >
        <a-input v-model:value="timeDurationValue" @blur="onTimerBlur" />
      </div>
    </div>
    <div :class="styles.list">
      <OperationItem
        v-for="operation in operations"
        :key="operation.AUFNR + operation.VORNR"
        :class="styles.item"
        :swipe-able="swipeAble"
        :on-reject="onReject"
        :handle-status-icon="handleStatusIcon"
        :url="url"
        :url-tag="urlTag"
        :show-status="showStatus"
        :show-number="showNumber"
        :is-link="isLink"
        :operation="operation"
        :operations="operations"
        :is-supervisor="userIsSupervisor"
        :is-inspection-order="isInspectionOrder"
      />
    </div>
  </div>
</template>
