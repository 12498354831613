import { defineStore } from 'pinia';
import { type Ref, ref } from 'vue';

import { getUserInformation } from '@/shared/api';
import { useStorage } from '@/shared/hooks';
import type { DefectsListInput } from '@/shared/interfaces';
import { STORAGE_DEFECT_INFO } from '@/shared/lib';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';

import { getDefectsList } from '../api/getDefectsList';
import type { DefectsListResponse, DefectStatuses } from '../interfaces';
import { mapUserInfoWithDefects } from '../lib';

export const useDefectsListStore = defineStore('defectsList', () => {
  const defectsList = ref<DefectsListResponse | undefined>(undefined);
  const defectsFetchError = ref(false);
  const lastInput = ref<DefectsListInput | undefined>(undefined);
  //дефекты заказов загружаются отдельным запросом при каждом обновлении
  const ordersDefectsList = ref<DefectsListResponse | undefined>(undefined);
  const ordersDefectsFetchError = ref(false);
  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();
  const { setItem } = useStorage();

  const fetchDefectsListAsync = async (input: DefectsListInput, silent = false) => {
    await handleLoading({
      tryBlock: async () => {
        await fetchDefectsList(input, defectsList, defectsFetchError, lastInput);
      },
      funcName: 'fetchDefectsListAsync',
      noGlobalLoader: silent,
    });
  };

  const fetchOrdersDefectsListAsync = async (input: DefectsListInput, silent = false) => {
    await handleLoading({
      tryBlock: async () => {
        await fetchDefectsList(input, ordersDefectsList, ordersDefectsFetchError, lastInput);
      },
      funcName: 'fetchOrdersDefectsListAsync',
      noGlobalLoader: silent,
    });
  };

  const fetchDefectsList = async (
    input: DefectsListInput,
    listRef: Ref<DefectsListResponse | undefined>,
    errorRef: Ref<boolean>,
    lastInputRef?: Ref<DefectsListInput | undefined>,
  ) => {
    errorRef.value = false;
    if (lastInputRef && lastInputRef.value?.IM_TPLNR && input.IM_TPLNR) {
      if (lastInputRef.value?.IM_TPLNR !== input.IM_TPLNR) {
        lastInputRef.value = input;
      } else {
        lastInputRef.value = input;
        return;
      }
    }

    const response = await getDefectsList(input);
    if (handleResponse(response)) {
      const data = response.data as DefectsListResponse;
      listRef.value = data;
      const tabNumbers = data?.EX_DEFECT_LIST?.map((item) => item.QMNAM) || [];
      if (tabNumbers.length > 0) {
        const informationResponse = await getUserInformation(tabNumbers);
        if (handleResponse(informationResponse)) {
          const dataWithInfo = mapUserInfoWithDefects(
            data?.EX_DEFECT_LIST,
            informationResponse.data,
          );
          listRef.value = { ...data, EX_DEFECT_LIST: dataWithInfo };
        }
      }
    } else {
      errorRef.value = true;
    }
  };

  const updateLocalDefectStatus = (
    defectId: string,
    status: DefectStatuses,
    statusDefault?: DefectStatuses,
  ) => {
    if (!defectsList.value) return;

    const index = defectsList.value.EX_DEFECT_LIST.findIndex((defect) => defect.QMNUM === defectId);

    if (index !== -1) {
      const defect = defectsList.value.EX_DEFECT_LIST[index];

      const updateDefect = {
        ...defect,
        ...(statusDefault && { STAT: statusDefault }),
        localStatus: status,
      };

      defectsList.value.EX_DEFECT_LIST[index] = updateDefect;

      // Запоминаем дефект с изменением статуса для скрытия кнопки "Создать ремонтное задание" после обновления
      setItem(`${STORAGE_DEFECT_INFO}_${defectId}`, JSON.stringify(updateDefect));
    }
  };

  return {
    defectsList,
    defectsFetchError,
    ordersDefectsList,
    ordersDefectsFetchError,
    fetchDefectsListAsync,
    fetchOrdersDefectsListAsync,
    updateLocalDefectStatus,
  };
});
